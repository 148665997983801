import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

//context
import { Context as AuthContext } from "../context/AuthContext";

const PublicRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props => (state.isAuthenticated ? <Redirect to={"/"} /> : <Component {...props} />)}
    />
  );
};

export default PublicRoute;
