import { useEffect, useRef } from "react";
import $ from "jquery";
import "selectize";
import "./Selectize.css";
import places from "./places.js";

import Search from "@arcgis/core/widgets/Search.js";

import Graphic from "@arcgis/core/Graphic.js";
import * as Locator from "@arcgis/core/rest/locator.js";
import * as route from "@arcgis/core/rest/route.js";
import RouteParameters from "@arcgis/core/rest/support/RouteParameters.js";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet.js";
import SearchResultRenderer from "@arcgis/core/widgets/Search/SearchResultRenderer.js";

import BasemapToggle from "@arcgis/core/widgets/BasemapToggle.js";

const Widgets = props => {
  let startRoute = useRef();
  let finishRoute = useRef();
  let lineRoute = useRef();
  let lng = useRef(props.lat);
  let lat = useRef(props.lng);

  useEffect(() => {
    lat.current = props.lat;
    lng.current = props.lng;
  }, [props.lat, props.lng]);

  useEffect(() => {
    const routeUrl =
      "https://utility.arcgis.com/usrsvcs/appservices/QaF21TVOjzBT8mZI/rest/services/World/Route/NAServer/Route_World/solve";

    props.view.on("click", function (event) {
      console.log(event.button, "event.button");
      if (event.button !== 2) return;
      if (!startRoute.current) {
        console.log(startRoute.current, "start called");

        addGraphic("start", event.mapPoint);
      } else if (!finishRoute.current) {
        console.log(startRoute.current, "finish called");

        addGraphic("finish", event.mapPoint);
        //*** ADD ***//
        getRoute();
      } else {
        console.log("else called");
        // props.view.graphics.removeAll()
        props.view.graphics.remove(startRoute.current);
        props.view.graphics.remove(finishRoute.current);
        props.view.graphics.remove(lineRoute.current);
        lineRoute.current = null;
        startRoute.current = null;
        finishRoute.current = null;

        addGraphic("start", event.mapPoint);
      }
    });

    props.view.on("drag", () => {
      props.setAnchorX(null);
    });

    function addGraphic(type, point) {
      const graphic = new Graphic({
        symbol: {
          type: "simple-marker",
          color: type === "start" ? "white" : "black",
          size: "8px"
        },
        geometry: point
      });
      props.view.graphics.add(graphic);
      type === "start" ? (startRoute.current = graphic) : (finishRoute.current = graphic);
    }

    const travelModeObject = {
      distanceAttributeName: "Kilometers",
      description:
        "Follows paths and roads that allow pedestrian traffic and finds solutions that optimize travel distance.",
      impedanceAttributeName: "Kilometers",
      simplificationToleranceUnits: "esriMeters",
      uturnAtJunctions: "esriNFSBAllowBacktrack",
      useHierarchy: false,
      name: "Walking Distance",
      simplificationTolerance: 2,
      timeAttributeName: "WalkTime",
      restrictionAttributeNames: [
        "Avoid Private Roads",
        "Avoid Roads Unsuitable for Pedestrians",
        "Preferred for Pedestrians",
        "Walking"
      ],
      type: "WALK",
      id: "yFuMFwIYblqKEefX",
      attributeParameterValues: [
        {
          parameterName: "Restriction Usage",
          attributeName: "Avoid Private Roads",
          value: "AVOID_MEDIUM"
        },
        {
          parameterName: "Restriction Usage",
          attributeName: "Walking",
          value: "PROHIBITED"
        },
        {
          parameterName: "Restriction Usage",
          attributeName: "Preferred for Pedestrians",
          value: "PREFER_LOW"
        },
        {
          parameterName: "Walking Speed (km/h)",
          attributeName: "WalkTime",
          value: 5
        },
        {
          parameterName: "Restriction Usage",
          attributeName: "Avoid Roads Unsuitable for Pedestrians",
          value: "AVOID_HIGH"
        }
      ]
    };

    function getRoute() {
      // Setup the route parameters
      const routeParams = new RouteParameters({
        stops: new FeatureSet({
          features: [startRoute.current, finishRoute.current] // Pass the array of graphics
        }),
        travelMode: travelModeObject
      });
      // Get the route
      route.solve(routeParams).then(function (data) {
        // Display the route
        data.routeResults.forEach(function (result) {
          result.route.symbol = {
            type: "simple-line",
            color: [5, 150, 255],
            width: 3
          };
          props.view.graphics.add(result.route);
          lineRoute.current = result.route;
        });
      });
    }

    const searchWidget = new Search({
      view: props.view,
      locationEnabled: false,
      popupOpenOnSelect: false
    });

    searchWidget.on("select-result", function (evt) {
      console.info(evt);
      evt.source.getSuggestions();
      evt.source.getResults();

      props.view.popup.open({
        location: evt.result.feature.geometry, // location of the click on the view
        title: "Search result", // title displayed in the popup
        content: evt.result.name, // content displayed in the popup
        dockEnabled: true,
        dockOptions: {
          buttonEnabled: true,
          // Ignore the default sizes that trigger responsive docking
          breakpoint: true,
          position: "bottom-center"
        }
      });
    });

    props.view.popup.set("dockOptions", {
      breakpoint: true,
      buttonEnabled: true,
      position: "top-center"
    });

    const searchResult = new SearchResultRenderer({
      view: props.view,
      container: document.createElement("div"),
      showMoreResultsOpen: false,
      viewModel: searchWidget.viewModel
    });

    props.view.ui.add([searchWidget], {
      position: "top-right",
      index: 1
    });
    props.view.ui.add(searchResult, {
      position: "top-left",
      index: 2
    });

    //list of places
    // https://developers.arcgis.com/rest/geocode/api-reference/geocoding-category-filtering.htm#ESRI_SECTION1_502B3FE2028145D7B189C25B1A00E17B

    const select = document.createElement("select", "");
    // select.setAttribute("class", "esri-widget esri-select");
    select.setAttribute("style", "width: 175px;z-index:101; box-shadow: none !important;");

    const searchOption = document.createElement("option");
    searchOption.value = "";
    searchOption.innerHTML = "";
    select.appendChild(searchOption);
    places.forEach(function (p) {
      const option = document.createElement("option");
      option.value = p;
      option.innerHTML = p;
      select.appendChild(option);
    });

    $(function () {
      const $select = $(select).selectize({
        sortField: "text",
        onDropdownOpen: function ($dropdown) {
          this.$input.get(0).selectize.clear(false);
        }
      });

      // Listen for category changes and find places
      $select.on("change", function (event) {
        // findPlaces(event.target.value, props.view.center);
      });
    });

    // const locatorUrl = "http://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer";

    // function findPlaces(category, pt) {
    //   locator
    //     .addressToLocations({
    //       location: pt,
    //       categories: [category],
    //       maxLocations: 50,
    //       outFields: ["Place_addr", "PlaceName"]
    //     })
    //     .then(function (results) {
    //       // Clear the map
    //       let pointResults = [];

    //       props.view.popup.close();
    //       props.view.graphics.removeMany(pointResultsToDelete.current);
    //       // Add graphics
    //       results.forEach(function (result) {
    //         pointResults.push(
    //           new Graphic({
    //             attributes: result.attributes,
    //             geometry: result.location,
    //             symbol: {
    //               type: "simple-marker",
    //               color: "#000000",
    //               size: "12px",
    //               outline: {
    //                 color: "#ffffff",
    //                 width: "2px"
    //               }
    //             },
    //             popupTemplate: {
    //               title: "{PlaceName}",
    //               content: "{Place_addr}"
    //             }
    //           })
    //         );
    //       });
    //       pointResultsToDelete.current = pointResults;
    //       props.view.graphics.addMany(pointResults);
    //     });
    // }

    const toggle = new BasemapToggle({
      // 2 - Set properties
      view: props.view, // view that provides access to the map's 'topo-vector' basemap
      nextBasemap: "hybrid", // allows for toggling to the 'hybrid' basemap,
      index: 1
    });
    props.view.ui.add(toggle, "bottom-left");

    // Listen for mouse clicks and find places
    props.view.on("click", function (event) {
      if (event.button === 2) return;
      props.view.hitTest(event.screenPoint).then(function (response) {
        console.log(response, "clickGraph");
        if (response.results.length < 1) {
          // If graphic is not clicked, find places
          // findPlaces(select.options[select.selectedIndex].text, event.mapPoint);
        }
      });
    });

    props.view.ui.add(select, {
      position: "top-right",
      index: 0
    });

    const element = document.createElement("div");
    element.className = "esri-icon-locate esri-widget--button esri-widget esri-interactive";
    element.addEventListener("click", function (evt) {
      props.view
        .goTo({
          center: [lng.current, lat.current]
        })
        .catch(function (error) {
          if (error.name != "AbortError") {
            console.error(error);
          }
        });
    });
    props.view.ui.add(element, "bottom-right");
  }, []);

  return null;
};

export default Widgets;
