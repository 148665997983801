import React, { useContext } from "react";

//context
import { Context as AuthContext } from "../../context/AuthContext";

//material
import { Snackbar, makeStyles } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function CustomizedSnackbars() {
  const {
    state: { snackBar, snackBarDescription, snackBarSeverity },
    toggleSnackBar
  } = useContext(AuthContext);
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    toggleSnackBar(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={snackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarSeverity}>
          {snackBarDescription}
        </Alert>
      </Snackbar>
    </div>
  );
}
