import React from "react";

//material
import { CircularProgress, makeStyles } from "@material-ui/core";

//style
const useStyles = makeStyles(theme => ({
  fullPage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100vh",
    alignItems: "center"
  },
  element: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const PageLoading = ({ page = true }) => {
  const classes = useStyles();

  return (
    <div className={page ? classes.fullPage : classes.element}>
      <CircularProgress size={50} />
    </div>
  );
};

export default PageLoading;
