export default [
  "Populated Place",
  "Block",
  "Sector",
  "Neighborhood",
  "District",
  "City",
  "Metro Area",
  "Subregion",
  "Region",
  "Territory",
  "Country",
  "Zone",
  "POI",
  "Arts and Entertainment",
  "Amusement",
  "Park",
  "Aquarium",
  "Art Gallery",
  "Art Museum",
  "Billiards",
  "Bowling Alley",
  "Casino",
  "Cinema",
  "Historical Monument",
  "History Museum",
  "Indoor Sports",
  "Jazz Club",
  "Landmark",
  "Library",
  "Live Music",
  "Museum",
  "Other Arts and Entertainment",
  "Performing Arts",
  "Ruin",
  "Science Museum",
  "Tourist Attraction",
  "Wild Animal Park",
  "Zoo",
  "Education",
  "College",
  "Fine Arts School",
  "Other Education",
  "School",
  "Vocational School",
  "Food",
  "African Food",
  "American Food",
  "Argentinean Food",
  "Australian Food",
  "Austrian Food",
  "Bakery",
  "BBQ and Southern Food",
  "Belgian Food",
  "Bistro",
  "Brazilian Food",
  "Breakfast",
  "Brewpub",
  "British Isles Food",
  "Burgers",
  "Cajun and Creole Food",
  "Caribbean Food",
  "Chicken Restaurant",
  "Chilean Food",
  "Chinese Food",
  "Coffee Shop",
  "Continental Food",
  "Creperie",
  "East European Food",
  "Fast Food",
  "Filipino Food",
  "French Food",
  "Fusion Food",
  "German Food",
  "Greek Food",
  "Grill",
  "Ice Cream Shop",
  "Indian Food",
  "Indonesian Food",
  "International Food",
  "Irish Food",
  "Italian Food",
  "Japanese Food",
  "Korean Food",
  "Latin American Food",
  "Malaysian Food",
  "Mexican Food",
  "Middle Eastern Food",
  "Moroccan Food",
  "Other Restaurant",
  "Pastries",
  "Pizza",
  "Polish Food",
  "Portuguese Food",
  "Russian Food",
  "Sandwich Shop",
  "Scandinavian Food",
  "Seafood",
  "Snacks",
  "South American Food",
  "Southeast Asian Food",
  "Spanish Food",
  "Steak House",
  "Sushi",
  "Swiss Food",
  "Tapas",
  "Thai Food",
  "Turkish Food",
  "Vegetarian Food",
  "Vietnamese Food",
  "Winery",
  "Land Features",
  "Atoll",
  "Basin",
  "Butte",
  "Canyon",
  "Cape",
  "Cave",
  "Cliff",
  "Desert",
  "Dune",
  "Flat",
  "Forest",
  "Glacier",
  "Grassland",
  "Hill",
  "Island",
  "Isthmus",
  "Lava",
  "Marsh",
  "Meadow",
  "Mesa",
  "Mountain",
  "Mountain Range",
  "Oasis",
  "Other Land Feature",
  "Peninsula",
  "Plain",
  "Plateau",
  "Point",
  "Ravine",
  "Ridge",
  "Rock",
  "Scrubland",
  "Swamp",
  "Valley",
  "Volcano",
  "Wetland",
  "Nightlife Spot",
  "Bar or Pub",
  "Dancing",
  "Karaoke",
  "Night Club",
  "Nightlife",
  "Parks and Outdoors",
  "Basketball",
  "Beach",
  "Campground",
  "Diving Center",
  "Fishing",
  "Garden",
  "Golf Course",
  "Golf Driving Range",
  "Harbor",
  "Hockey",
  "Ice Skating Rink",
  "Nature Reserve",
  "Other Parks and Outdoors",
  "Park",
  "Racetrack",
  "Scenic Overlook",
  "Shooting Range",
  "Ski Lift",
  "Ski Resort",
  "Soccer",
  "Sports Center",
  "Sports Field",
  "Swimming Pool",
  "Tennis Court",
  "Trail",
  "Wildlife Reserve",
  "Professional and Other Places",
  "Ashram",
  "Banquet Hall",
  "Border Crossing",
  "Building",
  "Business Facility",
  "Cemetery",
  "Church",
  "City Hall",
  "Civic Center",
  "Convention Center",
  "Court House",
  "Dentist",
  "Doctor",
  "Embassy",
  "Factory",
  "Farm",
  "Fire Station",
  "Government Office",
  "Gurdwara",
  "Hospital",
  "Industrial Zone",
  "Livestock",
  "Medical Clinic",
  "Military Base",
  "Mine",
  "Mosque",
  "Observatory",
  "Oil Facility",
  "Orchard",
  "Other Professional Place",
  "Other Religious Place",
  "Place of Worship",
  "Plantation",
  "Police Station",
  "Post Office",
  "Power Station",
  "Prison",
  "Public Restroom",
  "Radio Station",
  "Ranch",
  "Recreation Facility",
  "Religious Center",
  "Scientific Research",
  "Shrine",
  "Storage",
  "Synagogue",
  "Telecom",
  "Temple",
  "Tower",
  "Veterinarian",
  "Vineyard",
  "Warehouse",
  "Water Tank",
  "Water Treatment",
  ,
  "Residence",
  "Estate",
  "House",
  "Nursing Home",
  "Residential Area",
  "Shops and Service",
  "ATM",
  "Auto Dealership",
  "Auto Maintenance",
  "Auto Parts",
  "Bank",
  "Bookstore",
  "Butcher",
  "Candy Store",
  "Car Wash",
  "Childrens Apparel",
  "Clothing Store",
  "Consumer Electronics Store",
  "Convenience Store",
  "Department Store",
  "Electrical",
  "Fitness Center",
  "Flea Market",
  "Food and Beverage Shop",
  "Footwear",
  "Furniture Store",
  "Gas Station",
  "Grocery",
  "Home Improvement Store",
  "Market",
  "Mens Apparel",
  "Mobile Phone Shop",
  "Motorcycle Shop",
  "Office Supplies Store",
  "Other Shops and Service",
  "Pet Store",
  "Pharmacy",
  "Repair Services",
  "Shopping Center",
  "Spa",
  "Specialty Store",
  "Sporting Goods Store",
  "Tire Store",
  "Toy Store",
  "Used Car Dealership",
  "Wholesale Warehouse",
  "Womens Apparel",
  "Travel and Transport",
  "Airport",
  "Bed and Breakfast",
  "Bridge",
  "Bus Station",
  "Cargo Center",
  "Dock",
  "Ferry",
  "Heliport",
  "Highway Exit",
  "Hostel",
  "Hotel",
  "Marina",
  "Metro Station",
  "Motel",
  "Other Travel",
  "Parking",
  "Pier",
  "Port",
  "Railyard",
  "Rental Cars",
  "Resort",
  "Rest Area",
  "Taxi",
  "Tollbooth",
  "Tourist Information",
  "Train Station",
  "Transportation Service",
  "Truck Stop",
  "Tunnel",
  "Weigh Station",
  "Water Features",
  "Bay",
  "Canal",
  "Channel",
  "Cove",
  "Dam",
  "Delta",
  "Estuary",
  "Fjord",
  "Gulf",
  "Hot Spring",
  "Irrigation",
  "Jetty",
  "Lagoon",
  "Lake",
  "Ocean",
  "Other Water Feature",
  "Reef",
  "Reservoir",
  "Sea",
  "Sound",
  "Spring",
  "Strait",
  "Stream",
  "Waterfall",
  "Well",
  "Wharf"
];
