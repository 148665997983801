import React, { useReducer } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
//material
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  Select,
  Button,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import stateList from "../utils/stateList";
//style
const useStyles = makeStyles(theme => ({
  dialogContainer: {
    borderRadius: "15px"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  }
}));

const ClientFormModal = ({
  openClientFormDialog,
  closeUserDialog,
  onUpdateClient,
  user,
  error
}) => {
  const { firstName, lastName, otherInfo, _id, email } = user;

  const classes = useStyles();

  const userInfo = {
    firstName: firstName,
    lastName: lastName,
    phone: otherInfo ? otherInfo.phone : "",
    birthDate: otherInfo ? new Date(otherInfo.birthDate) : new Date(),
    address: {
      street: otherInfo ? otherInfo.address.street : "",
      city: otherInfo ? otherInfo.address.city : "",
      state: otherInfo ? otherInfo.address.state : "State",
      zipCode: otherInfo ? otherInfo.address.zipCode : ""
    },
    _id,
    email
  };

  function reducer(state, action) {
    switch (action.type) {
      case "firstName":
        return { ...state, firstName: action.payload };
      case "email":
        return { ...state, email: action.payload };
      case "lastName":
        return { ...state, lastName: action.payload };
      case "phone":
        return { ...state, phone: action.payload };
      case "birthDate":
        return { ...state, birthDate: action.payload };
      case "street":
        return { ...state, address: { ...state.address, street: action.payload } };
      case "city":
        return { ...state, address: { ...state.address, city: action.payload } };
      case "state":
        return { ...state, address: { ...state.address, state: action.payload } };
      case "zipCode":
        return { ...state, address: { ...state.address, zipCode: action.payload } };
      default:
        throw new Error();
    }
  }
  const [state, dispatch] = useReducer(reducer, userInfo);

  const handlePhoneChange = e => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length < 10) {
      dispatch({ type: "phone", payload: onlyNums });
    }
  };

  return (
    <div>
      <Dialog
        open={openClientFormDialog}
        onClose={closeUserDialog}
        maxWidth={"xs"}
        aria-labelledby='form-dialog-title'
        classes={{
          paper: classes.dialogContainer
        }}
      >
        <Grid className={classes.headerStyle}>
          <Typography style={{ margin: "20px", color: "white" }}>Update client</Typography>
        </Grid>
        <DialogContent>
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Email Address'
            type='email'
            fullWidth
            value={state.email}
            onChange={e => dispatch({ type: "email", payload: e.target.value })}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='First Name'
            type='text'
            fullWidth
            value={state.firstName}
            onChange={e => dispatch({ type: "firstName", payload: e.target.value })}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Last Name'
            type='text'
            fullWidth
            value={state.lastName}
            onChange={e => dispatch({ type: "lastName", payload: e.target.value })}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Phone'
            type='tel'
            fullWidth
            value={state.phone}
            onChange={e => handlePhoneChange(e)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              className={classes.input}
              color='primary'
              fullWidth
              label='Birthdate'
              value={state.birthDate}
              onChange={date => dispatch({ type: "birthDate", payload: date })}
              format='MM/dd/yyyy'
              inputVariant='outlined'
            />
          </MuiPickersUtilsProvider>
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Street'
            type='text'
            fullWidth
            value={state.address.street}
            onChange={e => dispatch({ type: "street", payload: e.target.value })}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='City'
            type='text'
            fullWidth
            value={state.address.city}
            onChange={e => dispatch({ type: "city", payload: e.target.value })}
          />
          <FormControl variant='outlined' fullWidth className={classes.input} color='primary'>
            <InputLabel id='state label'>State</InputLabel>
            <Select
              labelId='state label'
              value={state.address.state}
              onChange={e => dispatch({ type: "state", payload: e.target.value })}
              label='State'
            >
              {stateList.map((state, i) => (
                <MenuItem key={i} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Zip code'
            type='text'
            fullWidth
            value={state.address.zipCode}
            onChange={e => dispatch({ type: "zipCode", payload: e.target.value })}
          />
        </DialogContent>
        {error ? (
          <Typography align='center' style={{ color: "red" }}>
            {error}
          </Typography>
        ) : null}
        <DialogActions>
          <Button onClick={closeUserDialog} variant='contained'>
            Cancel
          </Button>
          <Button
            disabled={!state.email || !state.firstName || !state.lastName || !state._id}
            onClick={() => onUpdateClient(state)}
            variant='contained'
            color='primary'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClientFormModal;
