import React, { useEffect, useContext, useState } from "react";

//material
import {
  Grid,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Paper,
  Container,
  makeStyles
} from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  paperContainer: {
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  }
}));

const ClientResetPassword = props => {
  const classes = useStyles();
  const {
    state: { errorMessage },
    verifyToken,
    changePassword,
    clearErrorMessage
  } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [email, setEmail] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    console.log(props);
    if (!props.match.params.token) {
      props.history.push("/");
    } else {
      onVerifyToken();
    }
  }, []);

  const onVerifyToken = async () => {
    const isOk = await verifyToken(props.match.params.token);
    if (isOk && isOk.message !== "ok") {
      setError(true);
      setLoading(false);
    } else {
      setError(false);
      setLoading(false);
      setEmail(isOk.email);
    }
  };

  const onChangePassword = async () => {
    setButtonLoading(true);
    const isOk = await changePassword(password, verifyPassword, email);
    if (isOk && isOk.message === "ok") {
      clearErrorMessage();
      setPasswordChanged(true);
      setButtonLoading(false);
    } else {
      setButtonLoading(false);
    }
  };

  if (error) {
    return (
      <Grid
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh" }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography>An error has occured. Or the link has expired. Please retry.</Typography>
        </Grid>
      </Grid>
    );
  } else if (loading) {
    return (
      <Grid
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh" }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  } else if (passwordChanged) {
    return (
      <Grid
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh" }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography>Password has been changed</Typography>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div>
        <Typography variant='h3' style={{ paddingTop: "20px", textAlign: "center" }}>
          Tappy Guide
        </Typography>
        <div style={{ height: "80vh", display: "flex", alignItems: "center" }}>
          <Container maxWidth='sm'>
            <Paper elevation={1} className={classes.paperContainer}>
              <Grid className={classes.headerStyle}>
                <Typography style={{ padding: "20px", color: "white" }}>Reset Password</Typography>
              </Grid>
              <Container className={classes.container}>
                <TextField
                  className={classes.input}
                  variant='outlined'
                  required
                  color='primary'
                  label='Password'
                  type='password'
                  fullWidth
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <TextField
                  className={classes.input}
                  variant='outlined'
                  required
                  color='primary'
                  label='Verify Password'
                  type='password'
                  fullWidth
                  value={verifyPassword}
                  onChange={e => setVerifyPassword(e.target.value)}
                />
                {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
                <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => onChangePassword()}
                    style={{ marginTop: 20, marginBottom: 30 }}
                  >
                    {buttonLoading ? (
                      <CircularProgress style={{ width: "25px", height: "25px" }} />
                    ) : (
                      "Reset"
                    )}
                  </Button>
                </Grid>
              </Container>
            </Paper>
          </Container>
        </div>
      </div>
    );
  }
};

export default ClientResetPassword;
