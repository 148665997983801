import React, { useState, useContext, useEffect } from "react";

//material
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//context
import { Context as AppContext } from "../../context/AppContext";
import { Context as AuthContext } from "../../context/AuthContext";

//style
const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  input: {
    marginTop: "20px"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  gridContainer: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column"
  }
}));

const ChangeIdleTimer = () => {
  const classes = useStyles();
  const [minutes, setMinutes] = useState(0);
  const { getIdleTimer, changeIdleTimer } = useContext(AppContext);
  const { toggleSnackBar } = useContext(AuthContext);

  const onChangeMinutes = async () => {
    const newMinutes = await changeIdleTimer(minutes);
    if (newMinutes) {
      toggleSnackBar(true, "Idle timer updated successfully", "success");
      setMinutes(newMinutes);
      return true;
    } else {
      toggleSnackBar(true, "Failed! Select something different than 0", "error");
    }
  };

  const onGetIdleTimer = async () => {
    const newMinutes = await getIdleTimer();
    if (newMinutes) {
      setMinutes(newMinutes);
    }
  };

  useEffect(() => {
    onGetIdleTimer();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6} md={6} className={classes.gridContainer}>
        <Typography>Change minutes idle agent</Typography>
        <TextField
          required
          size='small'
          className={classes.input}
          variant='outlined'
          color='primary'
          type='number'
          label='Minutes'
          value={minutes}
          onChange={e => setMinutes(e.target.value)}
        />
        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography
            style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
            align='center'
          >{`Agents will be set offline after ${minutes} minutes of inactivity. Notified in ${
            minutes * 0.85
          } minutes.`}</Typography>
          <Button
            onClick={() => onChangeMinutes()}
            disabled={!minutes}
            className={classes.button}
            variant='contained'
            color='primary'
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeIdleTimer;
