import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";

//material
import { Grid, Container, Typography, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  gridContainer: {
    borderRadius: "15px"
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "20px",
    display: "flex",
    flexDirection: "column"
  }
}));

const DropFile = ({ onSavePdf, acceptedFiles }) => {
  const [files, setFiles] = useState([]);
  const [dzKey, setDzKey] = useState(0);
  const classes = useStyles();

  const handleChange = files => {
    console.log(files, "dropzone");
    setFiles(files);
  };

  const onSave = async () => {
    const response = await onSavePdf(files);
    setFiles([]);
    setDzKey(Math.random());
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12} md={6} sm={6} className={classes.gridContainer}>
        <Typography>Change Tappy's license file</Typography>
        <Container style={{ marginTop: "20px" }}>
          <DropzoneArea
            style={{ padding: "10px" }}
            key={dzKey}
            onChange={handleChange}
            filesLimit={1}
            acceptedFiles={acceptedFiles}
            maxFileSize={10000000}
          />
          <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
            <Button
              onClick={onSave}
              disabled={files.length === 0}
              className={classes.button}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
};

export default DropFile;
