import Widgets from "../components/common/Widgets";
import Marker from "../components/common/Marker";
import { PopUp } from "../components/common/PopUp";
import { MyModal } from "../components/common/MyModal";
import { useGetQuotes } from "../components/utils/hooks/useParking";

import { useArcgisMap } from "../components/utils/hooks/useArcgisMap";
import { LoadingModal } from "../components/common/LoadingModal";
import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Grid, Button } from "@material-ui/core";
import { PinDrop } from "@material-ui/icons";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

import SearchBoxGoogle from "../components/common/SearchBoxGoogle";

const GoogleMapCallerPin = () => {
  const K_WIDTH = 40;
  const K_HEIGHT = 40;

  const greatPlaceStyle = {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2
  };
  return (
    <div style={greatPlaceStyle}>
      <PersonPinCircleIcon style={{ color: "red", width: "30px", height: "30px" }} />
    </div>
  );
};

const Map = ({ callerLat, callerLng }) => {
  const {
    state: { quotes, isLoading, error, setQuotes },
    getQuotes
  } = useGetQuotes();

  const [showParking, setShowParking] = useState(false);
  const [fullLocationData, setFullLocationData] = useState(null);
  const [anchorX, setAnchorX] = useState(null);
  const [anchorY, setAnchorY] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [timesOfFormats, setTimesOfFormats] = useState({
    startTime: undefined,
    endTime: undefined
  });

  const mapRef = useRef();

  const [mapsapi, setMapsapi] = useState({
    apiReady: false,
    map: null,
    maps: null
  });

  const [currentMap, setCurrentMap] = useState("arcgis");

  const {
    arcgisMap: { view, apiReady }
  } = useArcgisMap(mapRef, {
    lat: 38,
    lng: -95.7129
  });

  useEffect(() => {
    if (currentMap === "google") return;
    setMapsapi({ apiReady: false, map: null, maps: null });
  }, [currentMap]);

  const GoogleMapsMarker = ({ quote, hasFreePrice }) => (
    <PinDrop
      onClick={e => {
        setAnchorX(e.clientX);
        setAnchorY(e.clientY);
        setFullLocationData(quote);
      }}
      style={{ zIndex: 3234, color: hasFreePrice ? "green" : "blue", fontSize: 22 }}
    />
  );

  const createMapOptions = maps => {
    return {
      styles: [{ stylers: [{ visibility: "on" }] }],
      fullscreenControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT
      },
      draggable: !fullLocationData,
      scrollwheel: !fullLocationData
    };
  };

  useEffect(() => {
    if (timesOfFormats.startTime && timesOfFormats.endTime) {
      getQuotes(timesOfFormats.startTime, timesOfFormats.endTime);
    } else {
      setQuotes([]);
    }
  }, [timesOfFormats]);

  return (
    <div style={{ height: "94vh" }}>
      <Grid style={{ height: "95%", width: "100%", position: "relative", gap: 20 }}>
        <div
          className='webmap'
          ref={mapRef}
          style={{
            height: "100%",
            width: "100%",
            display: currentMap === "arcgis" ? "flex" : "none"
          }}
        >
          {apiReady ? (
            <>
              <Widgets view={view} setAnchorX={setAnchorX} />
              {callerLat && callerLng && (
                <Marker view={view} remove={false} lat={callerLat} lng={callerLng} type='caller' />
              )}
              {quotes?.map(item => {
                const lat = item._embedded["pw:location"].entrances[0].coordinates[0];
                const lng = item._embedded["pw:location"].entrances[0].coordinates[1];

                return (
                  <Marker
                    view={view}
                    key={item.location_id}
                    remove={!showParking}
                    {...{ lat, lng, item, setAnchorX, setFullLocationData, setAnchorY }}
                  />
                );
              })}
            </>
          ) : null}
          <PopUp
            {...{
              anchorX,
              anchorY,
              fullLocationData,
              setFullLocationData,
              map: "arcgis"
            }}
          />
        </div>

        <div
          style={{
            display: currentMap === "google" ? "flex" : "none",
            height: "100%",
            width: "100%"
          }}
        >
          <GoogleMapReact
            options={createMapOptions}
            id='gMap'
            defaultCenter={{ lat: 38, lng: -90 }}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={4}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              libraries: ["places", "geometry"]
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              setTimeout(() => {
                setMapsapi({ map, maps, apiReady: true });
              }, 800);
            }}
          >
            {mapsapi.apiReady && <SearchBoxGoogle mapsapi={mapsapi} />}
            <PopUp
              map={"google"}
              {...{
                anchorX,
                anchorY,
                fullLocationData,
                setFullLocationData
              }}
              lat={fullLocationData?._embedded?.["pw:location"]?.entrances[0].coordinates[0] || 0}
              lng={fullLocationData?._embedded?.["pw:location"]?.entrances[0].coordinates[1] || 0}
            />

            {quotes.length > 0 &&
              quotes.map(quote => {
                const lat = quote._embedded["pw:location"].entrances[0].coordinates[0];
                const lng = quote._embedded["pw:location"].entrances[0].coordinates[1];

                if (!showParking) return null;

                const hasFreePrice = quote.purchase_options.filter(
                  item => item.base_price.USD === "0.00"
                );

                return (
                  <GoogleMapsMarker
                    key={quote.location_id}
                    hasFreePrice={!!hasFreePrice?.length}
                    quote={quote}
                    {...{ lat, lng }}
                  />
                );
              })}
            {callerLat && callerLng && <GoogleMapCallerPin lat={callerLat} lng={callerLng} />}
          </GoogleMapReact>
        </div>
        <div
          style={{
            display: "flex",
            gap: 30,
            backgroundColor: "white",
            height: 70,
            justifyContent: "center"
          }}
        >
          <Button
            style={{
              zIndex: 100,

              backgroundColor: "white"
            }}
            onClick={() => setCurrentMap(currentMap === "google" ? "arcgis" : "google")}
          >
            {currentMap === "google" ? "ARCGIS" : "GOOGLE"}
          </Button>
          <Button onClick={() => setModalOpen(true)} color='primary'>
            {isLoading ? "Loading..." : "Reserve Parking"}
          </Button>
          <Button
            disabled={!quotes?.length}
            color='primary'
            onClick={() => setShowParking(!showParking)}
          >
            {showParking ? "Hide Parking Spaces" : "Show Parking Spaces"}
          </Button>
        </div>
      </Grid>
      <MyModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        setTimesOfFormats={setTimesOfFormats}
      />
      <LoadingModal visible={isLoading} />
    </div>
  );
};

export default Map;
