import React, { useContext, useEffect, useState, useRef } from "react";
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";
import "./history.css";

//material
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableFooter,
  TableContainer,
  TablePagination,
  makeStyles,
  CircularProgress,
  Button
} from "@material-ui/core";

//context
import { Context as UserContext } from "../../context/UserContext";

//components
import PageLoading from "../../components/PageLoading";
import SearchHeader from "../../components/common/SearchHeader";
import TableRowCollapseHistory from "../../components/common/TableRowCollapseHistory";

//icons
import HistoryIcon from "@material-ui/icons/History";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "20px"
  },
  paperContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  },
  button: {
    margin: theme.spacing(1),
    width: 260,
    height: 40
  }
}));

const History = () => {
  const {
    state: { histories, historyLoading },
    fetchHistory,
    fetchAllTimeHistory
  } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const token = localStorage.getItem("jwtToken");
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [allTimeHistory, setAllTimeHistory] = useState([]);
  const csvButtonRef = useRef();
  const [loadingAllDataButton, setLoadingAllDataButton] = useState(false);

  useEffect(() => {
    fetchAllHistory(1, rowsPerPage, search, startDate, endDate);
  }, []);

  const fetchAllHistory = async (page, rowsPerPage, search, startDate, endDate) => {
    const data = await fetchHistory(page, rowsPerPage, search, true, startDate, endDate);
    setPaginationData(data);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
  };

  //used to search on hitting enter
  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchAllHistory(1, rowsPerPage, search, startDate, endDate);
    }
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    await fetchAllHistory(newPage + 1, rowsPerPage, search, startDate, endDate);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    await fetchAllHistory(1, +event.target.value, search, startDate, endDate);
  };

  const handleDateChange = async (date, type) => {
    if (type === "start") {
      setStartDate(date);
      fetchAllHistory(1, rowsPerPage, search, date, endDate);
    } else {
      setEndDate(date);
      fetchAllHistory(1, rowsPerPage, search, startDate, date);
    }
  };

  const onFetchAllTimeHistory = async () => {
    setLoadingAllDataButton(true);
    const data = await fetchAllTimeHistory();
    setAllTimeHistory(data);
    csvButtonRef.current.link.click();
    setLoadingAllDataButton(false);
  };

  const csvHeader = [
    { label: "Agent", key: "userName" },
    { label: "User", key: "clientName" },
    { label: "Time", key: "timeCSV" },
    { label: "Rating", key: "userRate" },
    { label: "Caller", key: "callerCSV" },
    { label: "Duration", key: "durationCSV" },
    { label: "From", key: "calledFrom" },
    { label: "Start Lat", key: "startLat" },
    { label: "Start Long", key: "startLong" },
    { label: "End Lat", key: "endLat" },
    { label: "End Long", key: "endLong" },
    { label: "Rating text", key: "clientDescription" }
  ];

  const columns = [
    { id: "userName", label: "Agent", align: "left" },
    { id: "clientName", label: "User", align: "left" },
    {
      id: "createdAt",
      label: "Time",
      align: "left",
      format: value => (
        <div>
          <Typography>
            {" "}
            {moment(value.createdAt).format("MM/DD/YYYY")}
            {`(${moment(value.createdAt).format("h:mm A")})`}
          </Typography>
        </div>
      )
    },
    { id: "userRate", label: "Rating", align: "left" },
    { id: "calledFrom", label: "From", align: "left" },
    {
      id: "caller",
      label: "Caller",
      align: "left",
      format: value => (
        <div>
          <Typography> {value.caller === "User" ? value.userName : value.clientName}</Typography>
        </div>
      )
    },
    {
      id: "duration",
      label: "Duration",
      align: "left",
      format: value => (
        <div>
          <Typography>
            {moment.duration(value.duration, "seconds").format("h:mm:ss", { trim: false })}{" "}
          </Typography>
        </div>
      )
    }
  ];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SearchHeader
        onClick={() => fetchAllHistory(1, rowsPerPage, search, startDate, endDate)}
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        onKeyPress={e => handleKeyDown(e)}
        title={"History"}
        icon={<HistoryIcon style={{ color: "white", width: 32, height: 32 }} />}
        searchPlaceholder={"Search in history"}
      />
      <Grid style={{ margin: "30px" }}>
        <Grid className={classes.gridContainer}>
          <Paper className={classes.paperContainer} elevation={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
              <Grid style={{ alignSelf: "center" }}>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  startIcon={
                    loadingAllDataButton ? (
                      <Grid
                        style={{
                          alignSelf: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center"
                        }}
                      >
                        <CircularProgress
                          color='secondary'
                          size={20}
                          style={{ alignSelf: "center" }}
                        />
                      </Grid>
                    ) : (
                      <DescriptionIcon
                        style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      />
                    )
                  }
                  onClick={() => onFetchAllTimeHistory()}
                >
                  Download all data
                </Button>
                <CSVLink
                  ref={csvButtonRef}
                  target='_blank'
                  filename={`TappyGuide History.csv`}
                  data={allTimeHistory}
                  headers={csvHeader}
                  separator={","}
                  style={{ alignSelf: "center" }}
                ></CSVLink>
                <CSVLink
                  onClick={() => (histories.length === 0 ? false : true)}
                  filename={`TappyGuide(${moment(startDate).format("MM/DD/YYYY")} - ${moment(
                    endDate
                  ).format("MM/DD/YYYY")})History.csv`}
                  data={histories}
                  headers={csvHeader}
                  separator={","}
                  style={{ alignSelf: "center" }}
                >
                  <Button
                    disabled={histories.length === 0}
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    startIcon={<DescriptionIcon />}
                  >
                    Download current data
                  </Button>
                </CSVLink>
              </Grid>
              <Grid>
                <KeyboardDatePicker
                  PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='normal'
                  label='Select start date'
                  value={startDate}
                  onChange={date => handleDateChange(date, "start")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginRight: "20px" }}
                  autoOk={true}
                />
                <KeyboardDatePicker
                  PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='normal'
                  label='Select end date'
                  value={endDate}
                  onChange={date => handleDateChange(date, "end")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginRight: "20px" }}
                  autoOk={true}
                />
              </Grid>
            </Grid>
            {historyLoading ? (
              <PageLoading page={false} />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                          align={column.align}
                        >
                          <div>{column.label}</div>
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {histories.map(row => (
                        <TableRowCollapseHistory
                          row={row}
                          columns={columns}
                          key={row._id}
                          userToken={token}
                        />
                      ))}
                    </TableBody>
                  }
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100, 10000]}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default History;
