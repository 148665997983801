import React, { createContext, useState } from "react";

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [map, setMap] = useState("google");
  const [followCaller, setFollowCaller] = useState(false);

  const [timesOfFormats, setTimesOfFormats] = useState({
    startTime: undefined,
    endTime: undefined
  });

  return (
    <MapContext.Provider
      value={{ map, setMap, followCaller, setFollowCaller, timesOfFormats, setTimesOfFormats }}
    >
      {children}
    </MapContext.Provider>
  );
};
