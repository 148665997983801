import React, { useContext } from "react";

//material
import { Container, Grid, Typography, makeStyles } from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  }
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const {
    state: { isAuthenticated }
  } = useContext(AuthContext);

  return (
    <div className={isAuthenticated ? classes.appBar : {}}>
      <Container
        style={{
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <Grid style={{ alignSelf: "center" }}>
          <Typography style={{ fontSize: "50px", alignSelf: "center" }}>404 Not found</Typography>
        </Grid>
      </Container>
    </div>
  );
};

export default NotFoundPage;
