import createDataContext from './createDataContext';
import axios from 'axios';
import setAuthToken from '../setAuthToken'
import jwt_decode from 'jwt-decode';
import detect from 'detect.js'

const authReducer = (state, action) => {
    switch (action.type) {
        case 'signin':
            localStorage.setItem('is_authenticated', true)
            return { ...state, token: action.payload.decoded, isAuthenticated: true, errorMessage: '', loading: false, user: action.payload.user }
        case 'signout':
            return { token: null, isAuthenticated: false, errorMessage: '', isAdmin: false, loading: false, user: null }
        case 'update_user_data':
            return { ...state, user: action.payload }
        case 'add_error':
            return { ...state, errorMessage: action.payload };
        case 'clear_error':
            return { ...state, errorMessage: '' }
        case 'is_admin':
            return { ...state, isAdmin: true }
        case 'loading':
            return { ...state, loading: action.payload }
        case 'mic_req':
            return { ...state, isMic: action.payload }
        case 'update_user_profile':
            return { ...state, user: action.payload, errorMessage: '' }
        case 'snackbar':
            return { ...state, snackBar: action.payload.value, snackBarDescription: action.payload.description, snackBarSeverity: action.payload.severity }
        default:
            return state;
    }
}

const signin = (dispatch) => {
    return async (email, password, rememberPass) => {
        try {
            if (rememberPass) {
                localStorage.setItem('email', email)
                localStorage.setItem('password', password)
            } else {
                localStorage.removeItem('email')
                localStorage.removeItem('password')
            }
            const response = await axios.post('/api/user-login', { email, password })
            const { token, user } = response.data
            if (!token) {
                return
            }
            localStorage.setItem('jwtToken', token)

            setAuthToken(token)
            const decoded = await jwt_decode(token)
            dispatch({ type: 'signin', payload: { decoded, user } })



            if (decoded.role === 'Admin') {
                dispatch({ type: 'is_admin' })
            }
            dispatch({ type: 'clear_error' })
            return decoded.role
        } catch (e) {
            console.log(e)
            if (e.response) {
                dispatch({ type: 'add_error', payload: e.response.data.error })
            }
        }
    }
}

// const signup = (dispatch) => {
//     return async (email, password, verifyPassword, history) => {
//         try {
//             const response = await axios.post('/user/register', { email, password, verifyPassword })
//             if (response.data.error) {
//                 return console.log(response.data.error)
//             }
//             history.push('/login')
//             dispatch({ type: 'clear_error' })
//             return true
//         } catch (e) {
//             if (e.response.status === 400) {
//                 console.log(e.response.data.error)

//             }
//             dispatch({ type: 'add_error', payload: e.response.data.error })
//         }
//     }
// }

const tryLocalSignIn = (dispatch) => {
    return async (decoded) => {
        try {
            const user = await axios.post('/api/user-login-token', { token: decoded })
            if (decoded.role === 'Admin') {
                dispatch({ type: 'is_admin' })
            }
            console.log(user)
            dispatch({ type: 'signin', payload: { decoded, user: user.data.user } })
            return true
        } catch (e) {
            forceLogout(dispatch)
            console.log(e)
        }
    }
}

const userOnOff = (dispatch) => {
    return async (value) => {
        try {
            var det = detect.parse(navigator.userAgent);
            const response = await axios.post('/api/user-availability', { status: value, browser: det.browser.name })
            console.log(response)
            dispatch({ type: 'update_user_data', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const failedSignIn = (dispatch) => {
    return async () => {
        dispatch({ type: 'loading', payload: false })
        return true
    }
}

const clearErrorMessage = (dispatch) => {
    return async () => {
        dispatch({ type: 'clear_error' })
    }
}

const changeMicReq = (dispatch) => {
    return async (isMic) => {
        dispatch({ type: 'mic_req', payload: isMic })
    }
}

const logout = (dispatch) => {
    return async (history) => {
        const session = localStorage.getItem('sessionID');
        const response = await axios.post('/api/user-logout', { session })
        console.log(response, 'logged out')
        forceLogout(dispatch, history)
    }
}

const forceLogout = async (dispatch, history) => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('is_authenticated')
    localStorage.removeItem('sessionID')
    setAuthToken(false);
    await dispatch({ type: 'signout' })
    if (history) {
        history.push('/login')
    }
}

const verifyToken = (dispatch) => {
    return async (token) => {
        try {
            const response = await axios.post('/api/verify-reset-token', { token })
            console.log(response)
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}

const changePassword = (dispatch) => {
    return async (password, verifyPassword, email) => {
        try {
            const response = await axios.post('/api/change-password', { password, verifyPassword, email })
            console.log(response)
            return response.data
        } catch (e) {
            console.log(e)
            dispatch({ type: 'add_error', payload: e.response.data.error })
        }
    }
}

const updateCallCenterUserProfile = (dispatch) => {
    return async (email, firstName, lastName, _id) => {
        try {
            const response = await axios.put(`/api/update-user-cc-profile`, { email, firstName, lastName, _id });
            console.log(response)
            dispatch({ type: 'update_user_profile', payload: response.data })
            return true
        } catch (e) {
            if (e.response.data.error?.code === 11000) {
                dispatch({ type: 'add_error', payload: 'Email already exists' })
                return false
            } else if (e.response.status === 404) {
                console.log(e.response)
                dispatch({ type: 'add_error', payload: e.response.data.error })
            }
        }
    }
}

const refreshUserData = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/refresh-user-data`);
            console.log(response)
            dispatch({ type: 'update_user_data', payload: response.data })
            return true
        } catch (e) {
            console.log(e)
        }
    }
}

//  severity="error">
//  severity="warning">
//  severity="info">
//  severity="success">
const toggleSnackBar = (dispatch) => {
    return async (value, description, severity) => {
        try {
            dispatch({ type: 'snackbar', payload: { value, description, severity } })
            return true
        } catch (e) {
            console.log(e)
        }
    }
}

const resetPassword = (dispatch) => {
    return async (email) => {
        try {

            const response = await axios.post(`/api/user-reset-password`, { email });
            console.log(response)
            if (response) {
                dispatch({ type: 'add_error', payload: '' })
            }
            return true
        } catch (e) {
            console.log(e.response)
            dispatch({ type: 'add_error', payload: e.response.data.error })
        }
    }
}

export const { Provider, Context } = createDataContext(authReducer,
    { tryLocalSignIn, resetPassword, signin, failedSignIn, logout, clearErrorMessage, toggleSnackBar, userOnOff, changeMicReq, verifyToken, changePassword, updateCallCenterUserProfile, refreshUserData },
    { loading: true, token: null, isAuthenticated: false, isAdmin: false, errorMessage: '', paid: false, user: null, isMic: false, snackBar: false, snackBarDescription: '', snackBarSeverity: 'success' })