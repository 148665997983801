import React from "react";
//material
import { Toolbar, Grid, Typography, makeStyles } from "@material-ui/core";

import SearchField from "./SearchField";

const useStyles = makeStyles(theme => ({
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  }
}));
const SearchHeader = ({
  onClick,
  onKeyPress,
  value,
  icon,
  title,
  onChange,
  searchPlaceholder,
  count,
  children
}) => {
  const classes = useStyles();

  return (
    <Grid>
      <Toolbar className={classes.tableBar} elevation={3}>
        <Grid item sm={4} xs={12} md={4} style={{ display: "flex", flexDirection: "row" }}>
          {icon}
          <Typography style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}>
            {title} {count > 0 ? ` (${count})` : null}
          </Typography>
        </Grid>
        <SearchField
          onClick={onClick}
          onKeyPress={onKeyPress}
          value={value}
          onChange={onChange}
          searchPlaceholder={searchPlaceholder}
        />
        <Grid style={{ marginLeft: "10%" }}>{children}</Grid>
      </Toolbar>
    </Grid>
  );
};

export default SearchHeader;
