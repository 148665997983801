import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

//context
import { Context as AppContext } from "../../context/AppContext";

//material
import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
  makeStyles
} from "@material-ui/core";

//style
const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  },
  input: {
    marginTop: "20px"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    padding: "30px"
  }
}));

const PushNotification = () => {
  const classes = useStyles();
  const { sendNotificationsToAllUsers } = useContext(AppContext);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [okMessage, setOkMessage] = useState("");

  const onSendNotifications = async () => {
    setLoadingButton(true);
    const response = await sendNotificationsToAllUsers(title, message);
    if (response?.status === 400) {
      setErrorMessage(response.data.error);
      setOkMessage("");
    } else {
      setOkMessage(response.data.message);
      setTitle("");
      setMessage("");
      setErrorMessage("");
    }
    setLoadingButton(false);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={12} sm={12} style={{ display: "flex", flexDirection: "column" }}>
          <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Typography>Send push notifications</Typography>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              component={Link}
              to={`/admin/push-notifications/history`}
            >
              Notifications
            </Button>
          </Grid>
          <TextField
            size='small'
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Title'
            type='text'
            required
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <TextField
            size='small'
            required
            className={classes.input}
            variant='outlined'
            color='primary'
            label='Message'
            multiline
            minRows={4}
            type='text'
            value={message}
            onChange={e => setMessage(e.target.value)}
          />

          {errorMessage ? (
            <Typography align='center' style={{ color: "red" }}>
              {errorMessage}
            </Typography>
          ) : null}
          {okMessage ? (
            <Typography align='center' style={{ color: "green" }}>
              {okMessage}
            </Typography>
          ) : null}
          <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
            <Button
              onClick={() => onSendNotifications()}
              disabled={!title || !message}
              className={classes.button}
              variant='contained'
              color='primary'
            >
              <Grid style={{ width: "82px", height: "25px" }}>
                {loadingButton ? <CircularProgress size={22} color='inherit' /> : "Send"}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PushNotification;
