import { useContext, useEffect, useState } from 'react'
import setAuthToken from './setAuthToken'
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom'

//context
import { Context as AuthContext } from './context/AuthContext'

const LocalSignin = ({ history }) => {
    const { tryLocalSignIn, failedSignIn, logout } = useContext(AuthContext)

    const [loadPage, setLoadPage] = useState(false)

    const signin = async () => {
        try {
            if (localStorage.jwtToken) {
                setAuthToken(localStorage.jwtToken);
                const decoded = jwt_decode(localStorage.jwtToken);
                tryLocalSignIn(decoded).then(r => {
                    setLoadPage(r)
                })
            } else {
                failedSignIn()
            }
        } catch (e) {
            logout(history)
            console.log(e)
        }
    }

    useEffect(() => {
        signin()
    }, [])

    return true
}

export default withRouter(LocalSignin)