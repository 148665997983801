import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  CircularProgress,
  DialogActions
} from "@material-ui/core";

const UploadDialog = ({ fileIsUploading, error, closeUploadDialog }) => {
  return (
    <Dialog open={fileIsUploading}>
      {!error ? (
        <>
          <DialogTitle>
            Please wait while the call is being saved. Please do not close or navigate away from
            this window!
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <CircularProgress />
          </DialogContent>{" "}
        </>
      ) : (
        <>
          <DialogContent
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            Something went wrong. Please contact the administrator.
          </DialogContent>
          <DialogActions>
            <Button onClick={closeUploadDialog} color='primary'>
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default UploadDialog;
