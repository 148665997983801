import React from "react";

//material
import { ImageList, Typography, Grid, makeStyles } from "@material-ui/core";
import moment from "moment";

//style
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
  },
  title: {
    color: "grey"
  }
}));

const GridListInfo = ({ moreInfoData }) => {
  const classes = useStyles();

  return (
    <Grid container style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <ImageList>
        {moreInfoData.map((data, i) => (
          <Grid className={classes.root} key={i}>
            <Grid style={{ margin: "20px" }}>
              <Typography align='center' className={classes.title}>
                {data.title}
              </Typography>
              <Typography variant='h5' align='center'>
                {data.title === "Total Calls" || data.title === "Total Calls Selected Dates"
                  ? data.value
                  : moment.duration(data.value, "seconds").format("h:mm:ss", { trim: false })}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </ImageList>
    </Grid>
  );
};

export default GridListInfo;
