import React, { useState } from "react";

//material
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//style
const useStyles = makeStyles(theme => ({
  dialogContainer: {
    borderRadius: "15px"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  }
}));

const UserFormModal = ({ openUserFormDialog, closeUserDialog, updateUser, user, error }) => {
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [_id, setId] = useState(user._id);
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openUserFormDialog}
        maxWidth={"xs"}
        onClose={closeUserDialog}
        aria-labelledby='form-dialog-title'
        classes={{
          paper: classes.dialogContainer
        }}
      >
        <Grid className={classes.headerStyle}>
          <Typography style={{ margin: "20px", color: "white" }}>Update agent info</Typography>
        </Grid>
        <DialogContent>
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            id='email'
            label='Email Address'
            type='email'
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            id='firstName'
            label='First Name'
            type='text'
            fullWidth
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <TextField
            className={classes.input}
            variant='outlined'
            color='primary'
            id='lastName'
            label='Last Name'
            type='text'
            fullWidth
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </DialogContent>
        {error ? (
          <Typography align='center' style={{ color: "red" }}>
            {error}
          </Typography>
        ) : null}
        <DialogActions>
          <Button onClick={closeUserDialog} variant='contained'>
            Cancel
          </Button>
          <Button
            disabled={!email || !firstName || !lastName || !_id}
            onClick={() => updateUser(email, firstName, lastName, _id)}
            variant='contained'
            color='primary'
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserFormModal;
