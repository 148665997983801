import { useState } from "react";
import axios from "axios";

const clientId =
  process.env.NODE_ENV === "production" ? "600ee056d3343a5b53509129" : "64f329167d0c046edc81434b";

const useGetQuotes = () => {
  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getQuotes = async (start_time, end_time) => {
    setQuotes([]);
    setIsLoading(true);
    const q = "coordinates:42.331,-83.045 distance:70";
    const returns = "offstreet_bookable";

    try {
      const quotes = await axios.get("/api/quote-parking", {
        params: { returns, q, start_time, end_time, clientId }
      });

      setQuotes(quotes.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    state: {
      quotes,
      setQuotes,
      isLoading,
      error
    },
    getQuotes
  };
};

export { useGetQuotes };

export const useParking = () => {
  const getQuotes = async (start_time, end_time) => {
    const q = "coordinates:42.331,-83.045 distance:70";
    const returns = "offstreet_bookable";

    const quotes = await axios.get("/api/quote-parking", {
      params: { returns, q, start_time, end_time }
    });

    return quotes;
  };

  const bookParkingSpace = async (quoteId, finalPrice, plateNumber) => {
    const reply = await axios.post("/api/quote-bookings", {
      quoteId,
      plateNumber,
      finalPrice,
      clientId
    });

    return reply.data;
  };

  return {
    getQuotes,
    bookParkingSpace
  };
};

export default useParking;
