import createDataContext from './createDataContext'
import axios from 'axios'

const chatReducer = (state, action) => {
    switch (action.type) {
        case 'add_chat':
            return { ...state, chat: action.payload }
        case 'get_chats':
            return { ...state, chats: action.payload }
        case 'add_seen_chat':
            const index = state.chats.findIndex((x) => x._id === action.payload._id)
            state.chats[index] = action.payload
            return { ...state }
        case 'clear_messages':
            return { messages: [], page: 1, hasMore: false, limit: 15 }
        case 'get_messages':
            if (action.payload.page === 1) {
                return { ...state, messages: action.payload.docs, hasMore: action.payload.hasNextPage, page: action.payload.page }
            } else {
                return { ...state, messages: [...state.messages, ...action.payload.docs], page: action.payload.page, hasMore: action.payload.hasNextPage }
            }
        case 'add_message':
            state.messages.unshift(action.payload)
            if (state.messages.length >= 15) {
                state.messages.splice(-1, 1) // find a better solution 
            }
            return { ...state }
        case 'replace_last_message':
            const newArray = state.messages.map((x) => {
                if (x._id === '') {
                    return action.payload
                } else {
                    return x
                }
            })

            return { ...state, messages: newArray }
        case 'receive_message':
            state.messages.unshift(action.payload)
            if (state.messages.length >= 15) {
                state.messages.splice(-1, 1) // find a better solution 
            }
            return { ...state }
        default:
            return state;
    }
}

const getChat = (dispatch) => {
    return async (clientId, userId) => {
        try {
            const response = await axios.get(`/api/get-chat-cc?clientId=${clientId}&userId=${userId}`);
            console.log(response)
            if (response.status === 200) {
                dispatch({ type: 'add_chat', payload: response.data })
                return response.data._id
            } else {
                return null
            }
        } catch (e) {
            console.log(e)
        }
    }
}

const getChatsByIds = (dispatch) => {
    return async (clientId, userId) => {
        try {
            const response = await axios.get(`/api/get-chat-by-ids?clientId=${clientId}&userId=${userId}`);
            console.log(response)
            if (response.status === 200) {
                dispatch({ type: 'add_chat', payload: response.data })
                return response.data._id
            } else {
                return null
            }
        } catch (e) {
            console.log(e)
        }
    }
}



const getChats = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get('/api/get-chats-cc');
            console.log(response)
            if (response.status === 200) {
                dispatch({ type: 'get_chats', payload: response.data.chats })
                return response
            } else {
                return null
            }
        } catch (e) {
            console.log(e)
        }
    }
}

const getMessages = (dispatch) => {
    return async (page, limit, chatId) => {
        try {
            const response = await axios.get(`/api/get-messages-cc?page=${page}&limit=${limit}&chatId=${chatId}`);
            dispatch({ type: 'get_messages', payload: response.data })
            console.log(response)
            return response.data.docs
        } catch (e) {
            console.log(e)
        }
    }
}

const addMessage = (dispatch) => {
    return async (message, chatId, image, roomId) => {
        try {
            const response = await axios.post(`/api/add-message-cc`, { message, chatId, image, roomId });
            dispatch({ type: 'add_message', payload: response.data })
            console.log(response)
            return response
        } catch (e) {
            console.log(e)
        }
    }
}

const clearMessages = (dispatch) => {
    return async () => {
        dispatch({ type: 'clear_messages' })
    }
}
const setChat = (dispatch) => {
    return async (chat) => {
        dispatch({ type: 'add_chat', payload: chat })
    }
}

const receiveMessage = (dispatch) => {
    return async ({ message }) => {
        try {
            console.log(message)
            dispatch({ type: 'receive_message', payload: message })
        } catch (e) {
            console.log(e)
        }
    }
}

const seenChat = (dispatch) => {
    return async (chatId, chat) => {
        try {
            if (chatId) {
                const response = await axios.post(`/api/read-chat-cc`, { chatId });
                console.log(response)
                dispatch({ type: 'add_seen_chat', payload: response.data.chat })
            } else {
                dispatch({ type: 'add_seen_chat', payload: chat })
            }

        } catch (e) {
            console.log(e)
        }
    }
}

export const { Provider, Context } = createDataContext(chatReducer,
    { getChat, getMessages, clearMessages, addMessage, getChats, receiveMessage, setChat, seenChat, getChatsByIds },
    { chat: null, messages: [], hasMore: false, page: 1, limit: 15, chats: [] })