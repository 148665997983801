import React, { useState } from "react";

import ReactPlayer from "react-player";
import "../../components/VideoPlayer.css";

//material
import {
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Collapse,
  Box,
  Modal,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  makeStyles,
  DialogContent
} from "@material-ui/core";

//icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CloseIcon from "@material-ui/icons/Close";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ExploreIcon from "@material-ui/icons/Explore";
import ChatIcon from "@material-ui/icons/Chat";

//components
import PolylineWidget from "./PolylineWidget";
import { url } from "../utils/url";
import ChatPreview from "../admin/ChatPreview";
import CallComment from "../call/CallComment";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const TableRowCollapseHistory = ({ row, columns, userToken }) => {
  const [open, setOpen] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [openVideo, setOpenVideo] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [openChat, setOpenChat] = useState(null);
  const classes = useStyles();

  const onOpenModal = videoId => {
    setOpenVideo(videoId);
  };

  const onOpenMap = () => {
    setOpenMap(true);
  };

  const onOpenChat = (clientId, userId) => {
    setOpenChat({ clientId, userId });
  };

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell align={"left"} key='compress' style={{ borderWidth: 0 }}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(column => {
          const value = row[column.id];
          return (
            <TableCell align={column.align} key={column.id} style={{ borderWidth: 0 }}>
              {column.format ? column.format(row) : value}
            </TableCell>
          );
        })}
        <TableCell style={{ display: "flex", flexDirection: "row", borderWidth: 0 }}>
          <IconButton title='Play video' onClick={() => onOpenModal(row._id)}>
            <PlayArrowIcon />
          </IconButton>
          <IconButton title='Show Map' onClick={() => onOpenMap(true)}>
            <ExploreIcon />
          </IconButton>
          <IconButton title='Chat' onClick={() => onOpenChat(row.clientId._id, row.userId._id)}>
            <ChatIcon />
          </IconButton>
          {row.callId ? <CallComment room={row.callId} /> : null}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box>
              <Grid
                container
                spacing={2}
                style={{ width: "100%", flexDirection: "row", display: "flex" }}
              >
                <Grid item style={{ borderWidth: "1px" }} xs={12} md={6} sm={6}>
                  <Typography>Rating:</Typography>
                  <Typography style={{ padding: "30px" }}>
                    {row.clientDescription ? row.clientDescription : "No rating available"}
                  </Typography>
                </Grid>
                <Grid item style={{ borderWidth: "1px" }} xs={12} md={6} sm={6}>
                  <Typography>Coords:</Typography>
                  <Typography style={{ padding: "30px" }}>
                    <strong>(Start)</strong>Lat: {row.location[0]?.coords.latitude} Long:{" "}
                    {row.location[0]?.coords.longitude}
                  </Typography>
                  <Typography style={{ padding: "30px" }}>
                    <strong>(Finish)</strong>Lat:{" "}
                    {row.location[row.location.length - 1]?.coords.latitude} Long:{" "}
                    {row.location[row.location.length - 1]?.coords.longitude}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openVideo ? true : false}
        onClose={() => setOpenVideo("")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <>
          <IconButton
            style={{ position: "absolute", top: 50, right: 50, color: "white" }}
            onClick={() => setOpenVideo("")}
          >
            <CloseIcon />
          </IconButton>
          <div className='player-wrapper'>
            {!videoError ? (
              <ReactPlayer
                width='auto'
                height='500px'
                url={`${url}/audio?audioId=${openVideo}&userToken=${userToken}`}
                controls={true}
                type={"video/mp4"}
                playing={true}
                //remove picture in picture and make it a modal
                config={{ file: { attributes: { disablePictureInPicture: true } } }}
                onError={() => {
                  setVideoError(true);
                }}
              />
            ) : (
              <p style={{ color: "red" }}>No video found</p>
            )}
          </div>
        </>
      </Modal>
      <Modal
        open={openMap}
        onClose={() => setOpenMap(false)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ width: "85%", height: "85%" }}>
          <IconButton
            style={{ position: "absolute", top: 50, right: 50, color: "white" }}
            onClick={() => setOpenMap(false)}
          >
            <CloseIcon />
          </IconButton>
          {/* <WebMap
            id={"ae202c63879a46f9a5f5ca997dee2554"}
            mapProperties={{ basemap: "osm" }}
            viewProperties={{
              center: [row.location[0]?.coords.longitude, row.location[0]?.coords.latitude],
              zoom: 13
            }}
          >
            <PolylineWidget coords={row.location} />
          </WebMap> */}
        </div>
      </Modal>
      <Dialog open={openChat ? true : false} onClose={() => setOpenChat("")} maxWidth={"lg"}>
        <DialogTitle>
          {row.clientId?.fullName} - {row.userId?.fullName}
          <IconButton className={classes.closeButton} onClick={() => setOpenChat("")}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "700px", height: "80%" }}>
            <ChatPreview data={openChat} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableRowCollapseHistory;
