import React, { useEffect, useContext, useState } from "react";
import moment from "moment";

//material
import { Typography, Container, Grid, CircularProgress, makeStyles } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";

//context
import { Context as EventContext } from "../../context/EventContext";

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const Logs = () => {
  const classes = useStyles();

  const [search, setSearch] = useState("");

  const {
    state: { eventsLog, pageLogs, limitLogs, hasMoreLogs },
    getEventsLog
  } = useContext(EventContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    initLogs();
  }, []);

  const initLogs = async () => {
    await getEventsLog(1, limitLogs, search);
    setLoaded(true);
  };

  const onLoadMore = async () => {
    if (hasMoreLogs) {
      await getEventsLog(pageLogs + 1, limitLogs, search);
    } else {
      return;
    }
  };

  const vh = v => {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
  };

  const renderItem = (item, index) => {
    switch (item.type) {
      case "accepted":
      case "declined":
        return (
          <Grid className={classes.gridItem}>
            <Typography variant='subtitle2'>
              {" "}
              <strong>{item.user.fullName}</strong>{" "}
              {` ${item.type} ${item.event.title} appointment`}
            </Typography>
            <Typography>{`${moment(item.createdAt).format("MM-DD-YYYY : hh:mm A")}`}</Typography>
          </Grid>
        );
      case "updated":
      case "deleted":
      case "created":
        return (
          <Grid className={classes.gridItem}>
            <Typography variant='subtitle2'>
              {" "}
              <strong>{item.client.fullName}</strong>{" "}
              {` ${item.type} ${item.event.title} appointment`}
            </Typography>
            <Typography>{`${moment(item.createdAt).format("MM-DD-YYYY : hh:mm A")}`}</Typography>
          </Grid>
        );
      default:
        return;
    }
  };

  return (
    <Container>
      <Grid container style={{ height: vh(90) }}>
        <Grid item xs={12} sm={12} md={12} style={{ display: "flex", flexDirection: "column" }}>
          {loaded ? (
            eventsLog.length > 0 ? (
              <>
                <InfiniteScroll
                  loadMore={() => onLoadMore()}
                  useWindow={true}
                  hasMore={hasMoreLogs}
                  initialLoad={false}
                  isReverse={false}
                  style={{ display: "flex", flexDirection: "column" }}
                  threshold={10}
                >
                  {eventsLog.map((eventLog, index) => (
                    <div key={eventLog._id}>{renderItem(eventLog, index)}</div>
                  ))}
                  {hasMoreLogs ? (
                    <Grid
                      style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                      className='loader'
                      key={0}
                    >
                      <CircularProgress style={{ alignSelf: "center" }} size={20} />
                    </Grid>
                  ) : null}
                </InfiniteScroll>
              </>
            ) : (
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignSelf: "center"
                }}
              >
                <Typography style={{ margin: "50px" }}>No records found</Typography>
              </Grid>
            )
          ) : (
            <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <CircularProgress style={{ alignSelf: "center" }} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Logs;
