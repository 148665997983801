import React, { useState, useContext, useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";

//components
import EventView from "../components/event/EventView";

//material
import {
  Grid,
  makeStyles,
  Typography,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  CircularProgress
} from "@material-ui/core";

//context
import { Context as EventContext } from "../context/EventContext";

const useStyles = makeStyles(theme => ({
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center"
  }
}));

const MainPage = () => {
  const classes = useStyles();
  const {
    state: { events },
    getEvents
  } = useContext(EventContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    onGetEvents();
  }, []);

  const onGetEvents = async () => {
    await getEvents();
    setLoaded(true);
  };

  return (
    <Grid className={classes.container}>
      {loaded ? (
        events.length > 0 ? (
          <TableContainer style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Table>
              <TableBody>
                {events.map(event => {
                  return (
                    <TableRow hover tabIndex={-1} key={event._id}>
                      <TableCell>
                        <div>
                          <EventView event={event} />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid className={classes.gridItem}>
            <Typography style={{ margin: "50px" }}>No appointments for today</Typography>
          </Grid>
        )
      ) : (
        <Grid className={classes.gridItem}>
          <CircularProgress style={{ margin: "50px" }} />
        </Grid>
      )}
    </Grid>
  );
};

export default MainPage;
