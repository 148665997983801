import React, { useState } from "react";

//material
import { Grid, TextField, Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//style
const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  input: {
    marginTop: "20px"
  }
}));

const ChangePasswordAdmin = ({ error, changePassword, toggleSnackBar }) => {
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const resetState = () => {
    setOldPassword("");
    setVerifyPassword("");
    setNewPassword("");
  };

  const onChangePassword = async () => {
    setLoadingButton(true);
    try {
      const isOk = await changePassword(oldPassword, newPassword, verifyPassword);
      if (isOk) {
        toggleSnackBar(true, "Password updated successfully", "success");
        resetState();
      }
      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
    }
  };

  return (
    <Grid container style={{ display: "flex", flexDirection: "column" }}>
      <Typography>Change password</Typography>
      <Grid item xs={12} md={6} sm={6} style={{ display: "flex", flexDirection: "column" }}>
        <TextField
          size='small'
          required
          className={classes.input}
          variant='outlined'
          color='primary'
          label='Old Password'
          type='password'
          value={oldPassword}
          onChange={e => setOldPassword(e.target.value)}
        />
        <TextField
          size='small'
          required
          className={classes.input}
          variant='outlined'
          color='primary'
          label='New Password'
          type='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <TextField
          size='small'
          required
          className={classes.input}
          variant='outlined'
          color='primary'
          label='Verify Password'
          type='password'
          value={verifyPassword}
          onChange={e => setVerifyPassword(e.target.value)}
        />
        {error ? (
          <Typography align='center' style={{ color: "red" }}>
            {error}
          </Typography>
        ) : null}
        <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => onChangePassword()}
            disabled={!newPassword || !verifyPassword}
            className={classes.button}
            variant='contained'
            color='primary'
          >
            <Grid style={{ width: "160px", height: "25px" }}>
              {loadingButton ? <CircularProgress size={22} color='inherit' /> : "Change password"}
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordAdmin;
