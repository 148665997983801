import React, { useState, useContext, useEffect } from "react";

import GoogleMapReact from "google-map-react";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//material
import { Grid, Button } from "@material-ui/core";

//components
import SearchBoxGoogle from "../../components/common/SearchBoxGoogle";
import Points from "./arcgis/Points";

//context
import { Context as UserContext } from "../../context/UserContext";

const Map = () => {
  const [mapsapi, setMapsapi] = useState({
    apiReady: false,
    map: null,
    googlemaps: null
  });
  const [changeMap, setChangeMap] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const {
    state: { histories },
    fetchHistory
  } = useContext(UserContext);

  useEffect(() => {
    fetchHistory(1, 500, "", true, startDate, endDate);
  }, []);

  const createMapOptions = maps => {
    return {
      styles: [{ stylers: [{ visibility: "on" }] }],
      fullscreenControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT
      }
    };
  };

  const onChangeDate = async (date, type) => {
    if (type === "start") {
      setStartDate(date);
      fetchHistory(1, 500, "", true, date, endDate);
    } else {
      setEndDate(date);
      fetchHistory(1, 500, "", true, startDate, date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ height: "85vh" }}>
        <Grid style={{ height: "100%", width: "100%" }}>
          {changeMap ? (
            <></>
          ) : (
            // <WebMap
            //   id={"ae202c63879a46f9a5f5ca997dee2554"}
            //   mapProperties={{ basemap: "osm" }}
            //   viewProperties={{
            //     zoom: 3
            //   }}
            // >
            //   <Points histories={histories} />

            //   {/* <WebScene id="ae202c63879a46f9a5f5ca997dee2554" /> */}
            // </WebMap>
            <GoogleMapReact
              options={createMapOptions}
              id='gMap'
              defaultCenter={{
                lat: 39.113014,
                lng: -105.358887
              }}
              yesIWantToUseGoogleMapApiInternals={true}
              defaultZoom={2}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                libraries: ["places"]
              }}
              onGoogleApiLoaded={({ map, maps }) => {
                setTimeout(() => {
                  setMapsapi({ map, maps, apiReady: true });
                }, 800);
              }}
            >
              {mapsapi.apiReady ? <SearchBoxGoogle mapsapi={mapsapi} /> : null}
            </GoogleMapReact>
          )}
        </Grid>
      </div>
      <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <KeyboardDatePicker
          PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
          variant='inline'
          inputVariant='outlined'
          format='MM/dd/yyyy'
          margin='normal'
          label='Select start date'
          value={startDate}
          onChange={date => onChangeDate(date, "start")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          style={{ marginRight: "20px" }}
          autoOk={true}
        />
        <KeyboardDatePicker
          PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
          variant='inline'
          inputVariant='outlined'
          format='MM/dd/yyyy'
          margin='normal'
          label='Select end date'
          value={endDate}
          onChange={date => onChangeDate(date, "end")}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          style={{ marginRight: "20px" }}
          autoOk={true}
        />
      </Grid>

      {/* <Button
                style={{ display: 'flex' }}
                fullWidth
                onClick={() => {
                    setChangeMap((a) => !a)
                    setMapsapi({
                        apiReady: false,
                        map: null,
                        googlemaps: null
                    })
                }}>{changeMap ? 'Google' : 'Arcgis'}</Button> */}
    </MuiPickersUtilsProvider>
  );
};

export default Map;
