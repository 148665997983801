import React from "react";

import { CircularProgress, Modal } from "@material-ui/core";

export const LoadingModal = ({ visible }) => {
  return (
    <Modal open={visible}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "10px",
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          zIndex: 456
        }}
      >
        <CircularProgress />
      </div>
    </Modal>
  );
};
