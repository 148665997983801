import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

//material
import {
  Grid,
  Container,
  TextField,
  Typography,
  Button,
  makeStyles,
  CircularProgress,
  Paper,
  IconButton
} from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";

//icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  paperContainer: {
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const ForgotPassword = ({ history }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const {
    state: { errorMessage },
    resetPassword,
    clearErrorMessage
  } = useContext(AuthContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  const onResetPassword = async () => {
    setButtonLoading(true);
    setEmail("");
    await resetPassword(email);
    setButtonLoading(false);
  };

  useEffect(() => {
    clearErrorMessage();
    return () => {
      clearErrorMessage();
    };
  }, []);

  return (
    <div>
      <Typography variant='h3' style={{ paddingTop: "20px", textAlign: "center" }}>
        Tappy Guide
      </Typography>
      <div style={{ height: "80vh", display: "flex", alignItems: "center" }}>
        <Container maxWidth='sm'>
          <Paper elevation={1} className={classes.paperContainer}>
            <Grid className={classes.headerStyle}>
              <Typography style={{ padding: "20px", color: "white" }}>Reset Password</Typography>
              <IconButton onClick={() => history.push("/login")}>
                <ArrowBackIcon style={{ color: "white" }} />
              </IconButton>
            </Grid>
            <Container className={classes.container}>
              <TextField
                className={classes.input}
                variant='outlined'
                required
                color='primary'
                label='Email'
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
              <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                <Button
                  disabled={!email}
                  variant='contained'
                  color='primary'
                  onClick={() => onResetPassword()}
                  style={{ marginTop: 20, marginBottom: 30 }}
                >
                  {buttonLoading ? (
                    <CircularProgress style={{ width: "25px", height: "25px" }} />
                  ) : (
                    "Reset"
                  )}
                </Button>
              </Grid>
            </Container>
          </Paper>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
