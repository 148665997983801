import React, { useState, useContext, useEffect } from "react";

//material
import {
  Grid,
  TextField,
  Typography,
  Button,
  Toolbar,
  makeStyles,
  CircularProgress
} from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";
import { Context as UserContext } from "../context/UserContext";

//components
import ChangePasswordAdmin from "../components/admin/ChangePasswordAdmin";

//icons
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

//style
const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  },
  input: {
    marginTop: "20px"
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    padding: "30px"
  }
}));

const Profile = () => {
  const classes = useStyles();
  const {
    state: { user, errorMessage },
    updateCallCenterUserProfile,
    clearErrorMessage,
    toggleSnackBar
  } = useContext(AuthContext);
  const {
    state: { addUserError },
    clearErrors,
    changeUserPassword
  } = useContext(UserContext);

  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [_id, setId] = useState(user._id);
  const [loadingButton, setLoadingButton] = useState(false);

  const changePassword = async (oldPassword, newPassword, verifyPassword) => {
    const isOk = await changeUserPassword(oldPassword, newPassword, verifyPassword, email, _id);
    if (isOk) {
      toggleSnackBar(true, "Password updated successfully", "success");
      return true;
    } else {
      return false;
    }
  };

  const onUpdateCallCenterUserProfile = async () => {
    setLoadingButton(true);
    try {
      const isOk = await updateCallCenterUserProfile(email, firstName, lastName, _id);
      if (isOk) {
        toggleSnackBar(true, "Profile updated successfully", "success");
      }
      setLoadingButton(false);
    } catch (e) {
      console.log(e);
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    return () => {
      clearErrors();
      clearErrorMessage();
    };
  }, []);

  return (
    <>
      <Grid>
        <Toolbar className={classes.tableBar} elevation={3}>
          <Grid item sm={4} xs={12} md={4} style={{ display: "flex", flexDirection: "row" }}>
            <AccountCircleOutlinedIcon style={{ color: "white", width: 32, height: 32 }} />
            <Typography style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}>
              Profile
            </Typography>
          </Grid>
        </Toolbar>
      </Grid>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={6} sm={6} style={{ display: "flex", flexDirection: "column" }}>
            <Typography>Edit info</Typography>
            <TextField
              size='small'
              className={classes.input}
              variant='outlined'
              color='primary'
              label='Email Address'
              type='email'
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              required
              className={classes.input}
              variant='outlined'
              color='primary'
              label='First Name'
              type='text'
              size='small'
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <TextField
              required
              className={classes.input}
              variant='outlined'
              color='primary'
              label='Last Name'
              type='text'
              size='small'
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
            {errorMessage ? (
              <Typography align='center' style={{ color: "red" }}>
                {errorMessage}
              </Typography>
            ) : null}
            <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
              <Button
                onClick={() => onUpdateCallCenterUserProfile()}
                disabled={!email || !firstName || !lastName}
                className={classes.button}
                variant='contained'
                color='primary'
              >
                <Grid style={{ width: "82px", height: "25px" }}>
                  {loadingButton ? <CircularProgress size={22} color='inherit' /> : "Update"}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <ChangePasswordAdmin changePassword={changePassword} error={addUserError} />
        </Grid>
      </div>
    </>
  );
};

export default Profile;
