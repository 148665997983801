import React, { useContext, useEffect, useState, useRef } from "react";
import "./App.css";
import "fontsource-roboto";
import "react-image-lightbox/style.css";
import "moment-duration-format";
import { BrowserRouter as Router, Switch, withRouter, Route } from "react-router-dom";
import LocalSignin from "./LocalSignin";
import IdleTimer from "react-idle-timer";

//context
import { Context as AuthContext } from "../src/context/AuthContext";
import { Context as AppContext } from "../src/context/AppContext";
import { Context as CallContext } from "../src/context/CallContext";
import { Context as EventContext } from "../src/context/EventContext";
import socket from "../src/context/socket";

//material
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { CssBaseline, Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

//pages
import AuthPage from "./pages/Auth";
import MapPage from "./pages/Map";
import CallPage from "./pages/Calls";
import ProfilePage from "./pages/Profile";
import MainPage from "./pages/Main";
import AdminPage from "./pages/Admin";
import ChatPage from "./pages/Chat";
import Construction from "./pages/Construction";
import ForgotPassword from "./pages/ForgotPassword";
import ClientResetPassword from "./pages/ClientResetPassword";
import License from "./pages/License";
import UserTickets from "./pages/UserTickets";
import TicketInfo from "./pages/TicketInfo";
import Contact from "./pages/Contact";
import EventPage from "./pages/Events";
import NotFound from "./pages/NotFound";

//routes
import PublicRoute from "./routing/PublicRoute";
import PrivateRoute from "./routing/PrivateRoute";
import AdminRoute from "./routing/AdminRoute";

//components
import PageLoading from "./components/PageLoading";
import Navbar from "./components/Navbar";
import CallModal from "./components/call/CallModal";
import Snackbar from "./components/common/Snackbar";
import UploadDialog from "./components/UploadDialog";
import { QuoteProvider } from "./context/QuoteContext";
import { MapProvider } from "./context/MapContext";

const App = withRouter(({ location, history }) => {
  const {
    state: { user, isAuthenticated, loading, isMic },
    userOnOff,
    changeMicReq,
    refreshUserData,
    toggleSnackBar
  } = useContext(AuthContext);
  const { addEvent, getEvents, updateEvent, deleteEvent } = useContext(EventContext);
  const {
    state: {
      room,
      callerName,
      openCallModal,
      caller,
      clientName,
      clientId,
      cancelCooldown,
      fileIsUploading,
      error
    },
    addCalling,
    refuseCall,
    clientEndCall,
    setOpenCallModal,
    closeUploadDialog
  } = useContext(CallContext);
  const {
    state: { idleMinutes },
    getIdleTimer
  } = useContext(AppContext);

  const [darkTheme, setDarkTheme] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const timerRef = useRef();
  const idleTimerRef = useRef();
  const [openIdleDialog, setOpenIdleDialog] = useState(false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#192d3e",
        light: "#192d3e",
        dark: "#192d3e"
      },
      secondary: {
        main: "#61dafb",
        light: "#61dafb",
        dark: "#61dafb"
      },
      background: {
        default: darkTheme ? "#121212" : "#FAFAFA",
        paper: darkTheme ? "1E2125" : "#FFFFFF"
      },
      type: darkTheme ? "dark" : "light"
    }
  });

  useEffect(() => {
    if (!navigator.permissions || !isAuthenticated) {
      return;
    }
    navigator.permissions
      .query({ name: "microphone" })
      .then(function (result) {
        if (result.state !== "granted") {
          //permission has already been granted, no prompt is shown
          changeMicReq(false);
        } else {
          changeMicReq(true);
        }
        result.onchange = () => {
          if (result.state !== "granted") {
            //permission has already been granted, no prompt is shown
            changeMicReq(false);
          } else {
            changeMicReq(true);
          }
        };
      })
      .catch(e => {
        window.alert("You may not be able to use the app unless you use CHROME.");
      });
  }, [isMic, isAuthenticated]);

  const beforeUnloadFunc = e => {
    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    e.returnValue = "You are available, are you sure you want to leave?";
  };

  useEffect(() => {
    console.log(room, "ROOOOOOM");
    if (room || user?.available) {
      window.addEventListener("beforeunload", beforeUnloadFunc);
    }
    socket.on("connect_error", () => {
      console.log("user off");
      userOnOff("idle");
    });

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadFunc);
      socket.off("connect_error");
    };
  }, [room, user]);

  const initialize = async () => {
    const token = localStorage.getItem("jwtToken");
    const sessionID = localStorage.getItem("sessionID");
    if (sessionID) {
      console.log("initialize with sesionID", token, "sesion", sessionID);
      socket.auth = { sessionID, token, userId: user._id };
      socket.connect();
    } else {
      console.log("initialize without sesionID", token);
      socket.auth = { token, userId: user._id };
      socket.connect();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      initialize();
      eventNotifications();
      getEvents("startup");
      getIdleTimer();
    }

    return () => {
      socket.close();
    };
  }, [isAuthenticated]);

  const eventNotifications = async () => {
    socket.on("eventNotification", async data => {
      if (data && data.type === "add") {
        await addEvent(data.event);
        toggleSnackBar(true, "A new appointment awaits for you", "info");
      } else if (data && data.type === "update") {
        console.log("UPDATE NOTIF");
        await updateEvent(data.event);
        const foundId = data.event.accepted.find(e => e === user._id);
        if (foundId) {
          toggleSnackBar(true, "An appointment you attend has been updated", "info");
        }
      } else if (data && data.type === "delete") {
        await deleteEvent(data.event);
        const foundId = data.event.accepted.find(e => e === user._id);
        if (foundId) {
          toggleSnackBar(true, "An appointment you attend has been deleted", "info");
        }
      } else {
        console.log("error adding event");
      }
    });
  };

  useEffect(() => {
    socket.on("session", async ({ sessionID, token }) => {
      // attach the session ID to the next reconnection attempts
      socket.auth = { sessionID };
      // store it in the localStorage
      localStorage.setItem("sessionID", sessionID);
      // save the ID of the user
      socket.token = token;
    });

    socket.on("calling", async data => {
      console.log("getting called");
      setIsChat(data.chat);
      socket.emit("joinRoom", { roomId: data.roomId });
      await addCalling(data);
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        if (stream) {
          history.push("/chat");
          setOpenCallModal(true);
        } else {
          handleRefuse();
        }
      });
    });

    socket.on("callEnded", roomId => {
      console.log("LEAVE ROOM CALLED");
      socket.emit("leaveRoom", { roomId });
      clientEndCall(history);
      setOpenCallModal(false);
      refreshUserData();
    });

    socket.on("callEndedNoAnswer", roomId => {
      console.log("callEndedNoAnswer CALLED");
      socket.emit("leaveRoomChat", { roomId });
      clientEndCall(history);
      setOpenCallModal(false);
      refreshUserData();
    });

    return () => {
      socket.off("calling");
      socket.off("callEnded");
      socket.off("callEndedNoAnswer");
      // socket.disconnect()
    };
  }, []);

  const handleRefuse = customMessage => {
    refuseCall(history, room, user._id, customMessage, caller);
    setOpenCallModal(false);
  };

  const handleAnswer = () => {
    console.log(clientId);
    socket.emit("userAnswered", { roomId: room, userId: user._id, clientId });
    setOpenCallModal(false);
  };

  const handleOnAction = event => {
    // console.log('user did something', event)
  };

  const handleOnActive = event => {
    if (isAuthenticated && !room) {
      initialize();
    }

    console.log("user is active", event);
    console.log("time remaining", timerRef.current.getRemainingTime());
  };

  const handleOnIdle = event => {
    if (room) return;
    userOnOff("idle");
    socket.close();
    console.log("user is idle", event);
    console.log("last active", timerRef.current.getLastActiveTime());
  };

  const handleStartingIdle = event => {
    if (!user.available) return;
    setOpenIdleDialog(true);
  };

  const handleOnActionIdle = event => {
    // console.log('user did something', event)
  };

  const handleOnActiveIdle = event => {
    console.log("user is active", event);
    console.log("time remaining", idleTimerRef.current.getRemainingTime());
  };

  return (
    <ThemeProvider theme={theme}>
      <QuoteProvider>
        <MapProvider>
          {/* <Chatbar> */}
          <div style={{ minHeight: "100vh", backgroundColor: theme.palette.background.paper }}>
            {isAuthenticated ? (
              <IdleTimer
                crossTab={true}
                ref={timerRef}
                timeout={60 * 1000 * idleMinutes}
                onActive={handleOnActive}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                debounce={250}
              />
            ) : null}
            {isAuthenticated ? (
              <IdleTimer
                crossTab={true}
                ref={idleTimerRef}
                onActive={handleOnActiveIdle}
                onAction={handleOnActionIdle}
                timeout={60 * 1000 * (idleMinutes * 0.85)}
                onIdle={handleStartingIdle}
                debounce={250}
              />
            ) : null}
            {location.pathname.includes("/admin") ||
            location.pathname.includes("/client-reset-password") ||
            !isAuthenticated ||
            loading ? null : (
              <Navbar />
            )}
            {room && openCallModal ? (
              <CallModal
                isMic={isMic}
                callerName={callerName}
                clientName={clientName}
                open={openCallModal}
                handleRefuse={handleRefuse}
                handleAnswer={handleAnswer}
                caller={caller}
                cancelCooldown={cancelCooldown}
                isChat={isChat}
              />
            ) : null}
            <LocalSignin />
            <CssBaseline />
            <Snackbar />
            <Dialog open={openIdleDialog} onClose={() => setOpenIdleDialog(false)}>
              <DialogTitle>You are getting idle.</DialogTitle>
              <DialogActions>
                <Button onClick={() => setOpenIdleDialog(false)} color='primary'>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            <UploadDialog
              fileIsUploading={fileIsUploading}
              error={error}
              closeUploadDialog={closeUploadDialog}
            />
            {!loading ? (
              <Switch>
                <PublicRoute exact path='/login' component={AuthPage} />
                <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
                <PublicRoute exact path='/' component={Construction} />
                <Route exact path='/client-reset-password/:token' component={ClientResetPassword} />
                <PublicRoute exact path='/license-agreement' component={License} />
                <PublicRoute exact path='/contact' component={Contact} />
                <PrivateRoute exact path='/home' component={MainPage} />
                <PrivateRoute exact path='/map' component={MapPage} />
                <PrivateRoute exact path='/profile' component={ProfilePage} />
                <PrivateRoute exact path='/ticket' component={TicketInfo} />
                <PrivateRoute exact path='/calls' component={CallPage} />
                <PrivateRoute exact path='/tickets' component={UserTickets} />
                <PrivateRoute exact path='/chat' component={ChatPage} />
                <PrivateRoute exact path='/event' component={EventPage} />
                <AdminRoute path='/admin' component={AdminPage} />
                <Route path='*' component={NotFound} />
              </Switch>
            ) : (
              <PageLoading />
            )}
          </div>
          {/* </Chatbar> */}
        </MapProvider>
      </QuoteProvider>
    </ThemeProvider>
  );
});

const Root = () => (
  <div>
    <Router>
      <App />
    </Router>
  </div>
);

export default Root;
