import React, { createContext, useContext, useState } from "react";
import axios from "axios";

import { Context as CallContext } from "./CallContext";

export const QuoteContext = createContext();

export const QuoteProvider = ({ children }) => {
  const {
    state: { clientId }
  } = useContext(CallContext);

  const [quotes, setQuotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasShownQuotesOnMap, setHasShownQuotesOnMap] = useState(false);
  const [error, setError] = useState(null);

  const [bookedQuote, setBookedQuote] = useState(null);

  const checkForBookedQuote = () => {
    const clientData = localStorage.getItem("callerData");

    const localStorageBooking = JSON.parse(clientData)?.[clientId]?.bookedQuote || null;

    if (localStorageBooking) setBookedQuote(localStorageBooking);
  };

  const resetContext = () => {
    setQuotes([]);
    setIsLoading(false);
    setError(null);
  };

  const getQuotes = async (start_time, end_time) => {
    setQuotes([]);
    setIsLoading(true);

    if (!clientId) return setError("You need to be logged in to cancel a booking");
    const q = "coordinates:42.331,-83.045 distance:70";
    const returns = "offstreet_bookable";

    try {
      const quotes = await axios.get("/api/quote-parking", {
        params: { returns, q, start_time, end_time, clientId }
      });

      setQuotes(quotes.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const bookParkingSpace = async (quoteId, finalPrice, plateNumber) => {
    try {
      setIsLoading(true);

      if (!clientId) return setError("You need to be logged in to book a parking space");

      const reply = await axios.post("/api/quote-bookings", {
        quoteId,
        plateNumber,
        clientId,
        finalPrice
      });

      localStorage.setItem(
        "callerData",
        JSON.stringify({ [clientId]: { bookedQuote: reply.data?.[0] } })
      );

      setBookedQuote(reply.data?.[0]);

      return reply.data?.[0];
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelBooking = async bookingId => {
    setIsLoading(true);
    try {
      if (!clientId) return setError("You need to be logged in to cancel a booking");

      await axios.post(`/api/quote-bookings/${bookingId}`, { clientId });

      setBookedQuote(null);
      localStorage.setItem("callerData", JSON.stringify({ [clientId]: {} }));
    } catch (error) {
      setBookedQuote(null);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetQuotes = () => setQuotes([]);
  const resetBookedQuote = () => setBookedQuote(null);

  return (
    <QuoteContext.Provider
      value={{
        state: {
          quotes,
          bookedQuote,
          hasShownQuotesOnMap,
          isLoading,
          error
        },
        setHasShownQuotesOnMap,
        bookParkingSpace,
        resetContext,
        cancelBooking,
        checkForBookedQuote,
        resetQuotes,
        resetBookedQuote,
        getQuotes
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};
