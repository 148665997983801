import React, { useContext, useEffect, useState } from "react";

//material
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TableFooter,
  makeStyles
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";

//context
import { Context as ClientContext } from "../../context/ClientContext";

//icons
import EditIcon from "@material-ui/icons/Edit";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";

//components
import PageLoading from "../../components/PageLoading";
import UpdateClientFormModal from "../../components/admin/UpdateClientFormModal";
import DeleteAlertButton from "../../components/admin/DeleteAlertButton";
import SearchHeader from "../../components/common/SearchHeader";
import TableRowCollapse from "../../components/common/TableRowCollapse";

//style
const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "20px"
  },
  paperContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  }
}));

const MobileUsers = () => {
  const { state, fetchClients, clearClientErrors, updateClient, deleteClient } =
    useContext(ClientContext);

  const classes = useStyles();

  const [openClientFormDialog, setOpenClientFormDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    _id: "",
    email: "",
    otherInfo: {}
  });
  const columns = [
    { id: "fullName", label: "Full Name", align: "left" },
    { id: "email", label: "Email", align: "center" },
    {
      id: "edit",
      label: "Edit",
      align: "right",
      format: value => (
        <div
          style={{
            paddingRight: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <IconButton
            size='small'
            onClick={() => {
              openUserDialog(value);
            }}
          >
            <EditIcon />
          </IconButton>
          <DeleteAlertButton
            onDelete={_id => onDeleteClient(_id)}
            _id={value._id}
            fullName={value.fullName}
          />
        </div>
      )
    }
  ];

  const onDeleteClient = _id => {
    deleteClient(_id);
  };

  const openUserDialog = value => {
    setEditUser({
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      _id: value._id,
      otherInfo: value.otherInfo
    });
    setOpenClientFormDialog(true);
  };
  const closeUserDialog = () => {
    setEditUser({
      firstName: "",
      lastName: "",
      _id: "",
      email: "",
      otherInfo: {}
    });
    clearClientErrors();
    setOpenClientFormDialog(false);
  };

  const onUpdateClient = async userInfo => {
    const isOk = await updateClient(userInfo);

    if (isOk) {
      setOpenClientFormDialog(false);
    }
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    const data = await fetchClients(newPage + 1, rowsPerPage, search);
    setPaginationData(data);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    const data = await fetchClients(1, +event.target.value, search);
    setPaginationData(data);
  };

  //used to search on hitting enter
  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchAllClients();
    }
  };

  const fetchAllClients = async () => {
    const data = await fetchClients(1, rowsPerPage, search);
    setPaginationData(data);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
  };

  useEffect(() => {
    fetchAllClients();

    return () => {
      clearClientErrors();
    };
  }, []);

  return (
    <>
      <SearchHeader
        onClick={() => fetchAllClients()}
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        onKeyPress={e => handleKeyDown(e)}
        title={"Mobile users"}
        icon={<PhoneIphoneIcon style={{ color: "white", width: 32, height: 32 }} />}
        searchPlaceholder={"Search mobile user"}
      />
      <Grid style={{ margin: "30px" }}>
        <Grid className={classes.gridContainer}>
          <Paper className={classes.paperContainer} elevation={0}>
            {state.isLoading ? (
              <PageLoading page={false} />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                          align={column.align}
                        >
                          <div>{column.label}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.clients.map(row => (
                      <TableRowCollapse row={row} columns={columns} key={row._id} />
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
        {openClientFormDialog ? (
          <UpdateClientFormModal
            error={state.updateClientError}
            user={editUser}
            openClientFormDialog={openClientFormDialog}
            closeUserDialog={closeUserDialog}
            onUpdateClient={onUpdateClient}
          />
        ) : null}
        <Grid className={classes.gridContainer}>
          <Grid container spacing={2} style={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={12} sm={6} md={6}></Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MobileUsers;
