import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import policy from "../pdf/tappy-license.pdf";
import { url } from "../components/utils/url";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

//material
import { Grid, Container, Button, Typography } from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const LicensePage = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "20px",
        width: "200%"
      }}
    >
      <div>
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Document file={{ url: `${url}/pdf` }} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <Grid style={{ alignSelf: "center" }}>
            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Button
                disabled={pageNumber === 1}
                style={{ margin: "10px", width: "80px" }}
                variant='contained'
                onClick={() => {
                  setPageNumber(pageNumber - 1);
                }}
              >
                Previous
              </Button>
              <Typography style={{ alignSelf: "center" }}>
                Page {pageNumber} of {numPages}
              </Typography>
              <Button
                disabled={pageNumber >= numPages}
                style={{ margin: "10px", width: "80px" }}
                variant='contained'
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default LicensePage;
