import React, { useEffect, useContext, useState, useRef } from 'react';
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';

//icons
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import ChatIcon from '@material-ui/icons/Chat';

//context
import { Context as ChatContext } from '../context/ChatContext'
import socket from '../context/socket'
import { Context as AuthContext } from '../context/AuthContext'

//components
import { Scrollbars } from 'react-custom-scrollbars';

//material
import { Typography, IconButton, Avatar, CircularProgress, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, CardMedia, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: 'white',
        borderRadius: 20,
        display: 'flex',
    },
    userChatBubble: {
        backgroundColor: 'grey',
        padding: 15,
        borderRadius: '15px',
        marginLeft: 15,
        justifyContent: 'space-between',
        maxWidth: '240px',
        display: 'flex',
    },
    clientChatBubble: {
        backgroundColor: theme.palette.primary.main,
        padding: 15,
        marginRight: 15,
        borderRadius: '15px',
        marginLeft: '10px',
        maxWidth: '240px',
        display: 'flex',
        flexDirection: 'row'
    },
    scrollBar: {
        borderTop: '1px solid rgba(187,187,187,0.5)',
        overFlowY: 'hidden',
        height: '100%'
    },
}));

const Chatbar = withRouter(({ clientId, roomId }) => {
    const classes = useStyles();

    const [message, setMessage] = useState('');
    const scrollbars = useRef(null)
    const [disableBtn, setDisabledBtn] = useState(true)
    const [image, setImage] = useState('')
    const [imageToShow, setImageToShow] = useState(null)

    const [openModalImage, setOpenModalImage] = useState(false)
    const { state: { chat, hasMore, limit, page, messages }, getMessages, addMessage, receiveMessage, getChat, seenChat, setChat } = useContext(ChatContext)
    const { state: { user } } = useContext(AuthContext)

    useEffect(() => {
        initChat()
    }, [])

    const initChat = async () => {
        const chatId = await getChat(clientId)
        socket.emit('joinRoom', { roomId: chatId })
        await getMessages(1, limit, chatId)
        if (scrollbars.current) {
            scrollbars.current.scrollToBottom()
        }
    }

    useEffect(() => {
        socket.on('receive-message', (data) => {
            receiveMessage({ message: data.newMessage })
            scrollbars.current.scrollToBottom()
        })
        return () => {
            socket.off('receive-message')
            socket.off('update-chat')
            setChat(null)
        }
    }, [])

    useEffect(() => {
        socket.on('update-chat', (data) => {
            seenChat(null, data.chat)
        })
        return () => {
            if (chat) {
                seenChat(chat._id, null)
                console.log('LEAVING ROOM CHAT')
                socket.emit('leaveRoomChat', { roomId: chat._id })
            }
        }
    }, [chat])

    const onLoadMore = async () => {
        if (hasMore) {
            await getMessages(page + 1, limit, chat._id)
        } else {
            return
        }
    }

    const onAddMessage = async () => {
        setDisabledBtn(true)
        setMessage('')
        setImage('')
        await addMessage(message, chat._id, image, roomId)
        scrollbars.current.scrollToBottom()
    }

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !disableBtn) {
            e.preventDefault()
            onAddMessage()
        }
    }

    const vh = (v) => {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    const renderItem = (item, index) => {
        if (item.senderId === user._id) {
            return (
                <Grid style={{ display: 'flex', flexDirection: 'row', margin: 15, justifyContent: 'flex-end' }}>
                    <Grid>
                        <Grid className={classes.userChatBubble}>
                            <Grid style={{ maxWidth: '200px' }}>
                                <Typography style={{ color: 'white', whiteSpace: 'pre-line', wordWrap: 'break-word' }} >{item.message}</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                <Typography style={{ color: 'white', alignSelf: 'flex-end', fontSize: 10, padding: '5px', marginTop: '10px' }} >{moment(item.createdAt).format('hh:mm A')}</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                {index === 0 ? item._id === '' ? <CircularProgress size={15} style={{ alignSelf: 'flex-end', marginLeft: '5px' }} /> : <CheckIcon
                                    style={{
                                        width: '15px', height: '15px', marginLeft: '5px', color: 'lightgreen'
                                    }}
                                /> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid style={{ display: 'flex', flexDirection: 'row', margin: 15, justifyContent: 'flex-start' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                        <Avatar style={{ width: 30, height: 30 }} src={chat.clients[0].profilePicture.thumb}
                        >{chat.clients[0].fullName[0]}</Avatar>
                        <Grid className={classes.clientChatBubble}>
                            <Grid style={{ maxWidth: '200px' }}>
                                <Typography style={{ color: 'white', whiteSpace: 'pre-line', wordWrap: 'break-word' }} >{item.message}</Typography>
                            </Grid>
                            {item.image.thumb ? <CardMedia
                                style={{ width: '100px', height: '100px' }}
                                image={item.image.thumb}
                                onClick={() => {
                                    setOpenModalImage(true)
                                    setImageToShow(item.image.src)
                                }}
                            /> : null}
                            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                <Typography style={{ color: 'white', alignSelf: 'center', fontSize: 10, padding: '5px', marginTop: '10px' }} >{moment(item.createdAt).format('hh:mm A')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <div >
            {openModalImage ?
                <Lightbox
                    mainSrc={imageToShow}
                    onCloseRequest={() => setOpenModalImage(false)}
                    reactModalStyle={{
                        content: {
                            top: '7%',
                            left: '10%'
                        }
                    }}
                /> : null}
            <Grid container style={{ height: vh(53) }}>
                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    {chat ? <>
                        <Scrollbars className={classes.scrollBar} ref={scrollbars} >
                            <InfiniteScroll
                                loadMore={() => onLoadMore()}
                                useWindow={false}
                                hasMore={hasMore}
                                initialLoad={false}
                                isReverse={true}
                                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                threshold={10}
                            >
                                {messages.map((message, index) => (
                                    <div key={message._id}>
                                        {renderItem(message, index)}
                                    </div>
                                ))}
                                {hasMore ? <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} className="loader" key={0}><CircularProgress style={{ alignSelf: 'center' }} size={20} /></Grid> : null}
                            </InfiniteScroll>
                        </Scrollbars>
                        <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel color='primary' htmlFor="standard-adornment-messages">Type a message</InputLabel>
                                <OutlinedInput
                                    className={classes.input}
                                    variant="outlined"
                                    id="standard-adornment-messages"
                                    name="password"
                                    required
                                    color='primary'
                                    value={message}
                                    onChange={(e) => {
                                        setMessage(e.target.value)
                                        setDisabledBtn(false)
                                        if (e.target.value === '') {
                                            setDisabledBtn(true)
                                        }
                                    }}
                                    onKeyPress={(e) => handleKeyDown(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => onAddMessage()}
                                                disabled={disableBtn}
                                            >
                                                <SendIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    labelWidth={130}
                                />
                            </FormControl>
                        </Grid>
                    </> : <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <ChatIcon style={{ width: '100px', height: '100px', alignSelf: 'center' }} />
                    </Grid>}
                </Grid>
            </Grid>
        </div>
    );
})

export default Chatbar
