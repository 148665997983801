import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./App";
import * as serviceWorker from "./serviceWorker";
import adapter from "webrtc-adapter";
//providers
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as UserProvider } from "./context/UserContext";
import { Provider as ClientProvider } from "./context/ClientContext";
import { Provider as CallProvider } from "./context/CallContext";
import { Provider as ChatProvider } from "./context/ChatContext";
import { Provider as StatisticsProvider } from "./context/StatisticsContext";
import { Provider as EventProvider } from "./context/EventContext";
import { Provider as AppProvider } from "./context/AppContext";

ReactDOM.render(
  <AuthProvider>
    <UserProvider>
      <EventProvider>
        <StatisticsProvider>
          <ClientProvider>
            <AppProvider>
              <CallProvider>
                <ChatProvider>
                  <Root />
                </ChatProvider>
              </CallProvider>
            </AppProvider>
          </ClientProvider>
        </StatisticsProvider>
      </EventProvider>
    </UserProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
