import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";

//context
import { Context as AppContext } from "../context/AppContext";

//material
import {
  Grid,
  Dialog,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Container,
  CardHeader,
  Card,
  Typography,
  CardContent,
  makeStyles,
  IconButton,
  CardActionArea,
  CircularProgress,
  Toolbar
} from "@material-ui/core";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const useStyles = makeStyles(theme => ({
  arrowButton: {
    position: "absolute",
    top: 10,
    bottom: 10,
    right: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  dialogContainer: {
    borderRadius: "15px"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  formControl: {
    minWidth: 120,
    marginTop: "20px"
  },
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  }
}));

const UserTickets = ({ history }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("Support");
  const {
    state: { tickets },
    addTicket,
    userGetTickets
  } = useContext(AppContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openTicketForm, setOpenTicketForm] = useState(false);

  const onAddTicket = async () => {
    setTitle("");
    setDescription("");
    await addTicket(title, description, type);
    userGetTickets();
    setOpenTicketForm(false);
  };

  useEffect(() => {
    onGetTickets();
  }, []);

  const onGetTickets = async () => {
    setLoading(true);
    await userGetTickets();
    setLoading(false);
  };

  const handleClose = value => {
    setOpenTicketForm(false);
  };

  const handleClickOpen = () => {
    setOpenTicketForm(true);
  };

  const ticketStatus = status => {
    switch (status) {
      case 0:
        return <Typography style={{ color: "red", alignSelf: "center" }}>Waiting</Typography>;
      case 1:
        return <Typography style={{ color: "orange", alignSelf: "center" }}>Responded</Typography>;
      case 2:
        return <Typography style={{ color: "green", alignSelf: "center" }}>Done</Typography>;
      default:
        return <Typography style={{ color: "red", alignSelf: "center" }}>Waiting</Typography>;
    }
  };

  const renderTicket = (ticket, index) => {
    return (
      <div key={index}>
        <Card style={{ borderRadius: 0 }}>
          <CardActionArea
            component={"a"}
            onClick={() => history.push("/ticket", { ticketId: ticket._id })}
          >
            <CardHeader
              title={
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ alignSelf: "center" }} variant='body2'>
                    {ticket.userId?.fullName}
                  </Typography>
                  <div style={{ marginLeft: "20px", display: "flex", flexDirection: "row" }}>
                    {ticketStatus(ticket.status)}
                  </div>
                </Grid>
              }
              subheader={
                <>
                  <Typography variant='subtitle2' color='textSecondary'>
                    {moment(ticket.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                  <Typography style={{ alignSelf: "center" }}>{ticket.type}</Typography>
                </>
              }
            />
            <CardContent>
              <Typography style={{ fontSize: 18 }} variant='body2' component='p'>
                Subject: {ticket.title}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary" component="p" style={{ marginRight: '40px' }}>
                                {ticket.description}
                            </Typography> */}
              <Grid className={classes.arrowButton}>
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Grid>
        <Toolbar className={classes.tableBar} elevation={3}>
          <Grid item sm={4} xs={12} md={4} style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignSelf: "center"
              }}
            >
              <HelpOutlineOutlinedIcon style={{ color: "white", width: 32, height: 32 }} />
              <Typography style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}>
                Support
              </Typography>
            </Grid>
            <IconButton style={{ marginLeft: "20px" }} onClick={handleClickOpen} color='secondary'>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Toolbar>
      </Grid>
      <Container style={{ marginTop: "20px" }}>
        <div>
          <Dialog
            open={openTicketForm}
            maxWidth={"xl"}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'
            classes={{
              paper: classes.dialogContainer
            }}
          >
            <Grid className={classes.headerStyle}>
              <Typography style={{ margin: "20px", color: "white" }}>Add Ticket</Typography>
            </Grid>
            <DialogContent>
              <TextField
                label='Title'
                variant='outlined'
                value={title}
                fullWidth
                onChange={e => setTitle(e.target.value)}
              />
              <TextField
                style={{ marginTop: "20px" }}
                variant='outlined'
                label='Description'
                value={description}
                multiline
                rows={6}
                fullWidth
                onChange={e => setDescription(e.target.value)}
              />
              <FormControl variant='outlined' className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>Type</InputLabel>
                <Select
                  fullWidth
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={type}
                  onChange={e => setType(e.target.value)}
                  label='Type'
                >
                  <MenuItem value={"Support"}>Support</MenuItem>
                  <MenuItem value={"Feedback"}>Feedback</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            {/* {error ? <Typography align='center' style={{ color: 'red' }}>{error}</Typography> : null} */}
            <DialogActions>
              <Button
                style={{ alignSelf: "flex-end", marginTop: "20px" }}
                fullWidth
                variant='contained'
                color='primary'
                onClick={() => handleClose()}
              >
                Close
              </Button>
              <Button
                style={{ alignSelf: "flex-end", marginTop: "20px" }}
                disabled={!title || !description}
                fullWidth
                variant='contained'
                color='primary'
                onClick={() => onAddTicket()}
              >
                Add ticket
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Grid style={{ margin: "20px" }}>
          <Grid>
            {!loading ? (
              <div>
                {tickets.map((ticket, index) => (
                  <div style={{ marginTop: "20px" }} key={index}>
                    {renderTicket(ticket, index)}
                  </div>
                ))}
              </div>
            ) : (
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: "50px"
                }}
              >
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withRouter(UserTickets);
