import WebMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import React, { useEffect, useState } from "react";

import esriConfig from "@arcgis/core/config.js";

export const useArcgisMap = (mapRef, { callerLat, lat, lng }) => {
  const [arcgisMap, setArcgisMap] = useState({
    map: null,
    view: null,
    apiReady: false
  });

  useEffect(() => {
    if (!mapRef.current) return;
    let view;

    esriConfig.apiKey = process.env.REACT_APP_ARCGIS_KEY;

    const map = new WebMap({
      portalItem: {
        id: "ae202c63879a46f9a5f5ca997dee2554"
      }
    });

    view = new MapView({
      map,
      container: mapRef.current,
      center: [lng, lat],
      zoom: callerLat ? 18 : 4
    });

    setTimeout(() => {
      setArcgisMap({ map, view, apiReady: true });
    }, 1000);

    return () => {
      if (view) view.destroy();
    };
  }, []);

  return { arcgisMap };
};
