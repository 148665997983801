import React, { useEffect, useRef } from "react";
import "./SearchBoxGoogle.css";

//material
import { IconButton } from "@material-ui/core";

//icons
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

const SearchBoxGoogle = ({ mapsapi: { maps, map, apiReady }, lat = 24, lng = 35 }) => {
  const latRef = useRef(lat);
  const lngRef = useRef(lng);

  useEffect(() => {
    latRef.current = lat;
    lngRef.current = lng;
  }, [lat, lng]);

  useEffect(() => {
    initMap();
  }, []);

  const initMap = async () => {
    if (!apiReady || !maps || !map) return;

    const btnLocation = document.getElementById("btn-location");
    const input = document.getElementById("pac-input");

    const autocomplete = new maps.places.Autocomplete(input);
    autocomplete.bindTo("bounds", map);
    // Specify just the place data fields that you need.
    autocomplete.setFields(["place_id", "geometry", "name", "formatted_address"]);
    map.controls[maps.ControlPosition.TOP_RIGHT].push(input);
    map.controls[maps.ControlPosition.RIGHT_BOTTOM].push(btnLocation);
    const infowindow = new maps.InfoWindow();
    const infowindowContent = document.getElementById("infowindow-content");
    infowindow.setContent(infowindowContent);
    const marker = new maps.Marker({ map: map });
    marker.addListener("click", () => {
      infowindow.open(map, marker);
    });
    btnLocation.addEventListener("click", function () {
      map.setCenter({ lat: latRef.current, lng: lngRef.current });
    });

    autocomplete.addListener("place_changed", () => {
      infowindow.close();
      const place = autocomplete.getPlace();
      console.log(place, "gmaps place");
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }
      // Set the position of the marker using the place ID and location.
      marker.setPlace({
        placeId: place.place_id,
        location: place.geometry.location
      });
      marker.setVisible(true);
      infowindowContent.children.namedItem("place-name").textContent = place.name;
      infowindowContent.children.namedItem("place-address").textContent = place.formatted_address;
      infowindow.open(map, marker);
    });

    return true;
  };

  return (
    <div>
      <input id='pac-input' type='text' className='controls' placeholder='Enter a location' />
      <div id='infowindow-content'>
        <span className='title' id='place-name'></span>
        <br />
        <span id='place-address'></span>
      </div>
      <IconButton
        style={{ width: 50, height: 50, backgroundColor: "rgba(123,123,123, 0.1)", margin: 20 }}
        id='btn-location'
      >
        <GpsFixedIcon />
      </IconButton>
    </div>
  );
};

export default SearchBoxGoogle;
