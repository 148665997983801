import createDataContext from './createDataContext';
import axios from 'axios';
import moment from 'moment-timezone'

const authReducer = (state, action) => {
    switch (action.type) {
        case 'add_card_data':
            return { ...state, cardData: action.payload }
        case 'add_card_data_users':
            return { ...state, cardDataClients: action.payload }
        case 'add_additional_data':
            return { ...state, moreInfoData: action.payload }
        case 'get_total_calls_each_month':
            return { ...state, totalCallsEachMonth: action.payload }
        case 'get_users_data':
            return { ...state, users: action.payload.docs }
        case 'get_clients_data':
            return { ...state, clients: action.payload.docs }
        case 'get_no_guides_history_data':
            return { ...state, noGuidesHistory: action.payload }
        case 'get_grouped_calls':
            return { ...state, groupedCalls: action.payload }
        default:
            return state;
    }
}

const getOverallCalls = (dispatch) => {
    return async (startDate, endDate) => {
        try {
            const response = await axios.get(`/api/get-overall-calls?startDate=${startDate}&endDate=${endDate}`)
            console.log(response, 'OVERALLCALLS')
            dispatch({ type: 'add_card_data', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const registeredMobileUsersData = (dispatch) => {
    return async (startDate, endDate) => {
        try {
            const response = await axios.get(`/api/registered-mobile-users-data?startDate=${startDate}&endDate=${endDate}`)
            console.log(response, 'OVERALLCALLS USERS')
            dispatch({ type: 'add_card_data_users', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const getOverallCallsInfo = (dispatch) => {
    return async (startDate, endDate) => {
        try {
            const response = await axios.get(`/api/get-additional-info-calls?startDate=${startDate}&endDate=${endDate}`)
            console.log(response, 'getOverallCallsInfo')
            dispatch({ type: 'add_additional_data', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const getTotalCallsEachMonth = (dispatch) => {
    return async (year) => {
        try {
            let arrayVal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            const response = await axios.post('/api/get-total-calls-each-month', { year })
            console.log(response, 'TOTALCALLS')
            if (response.data.length !== 0) {
                arrayVal = Object.values(response.data[0].data)
                console.log(arrayVal, "VALVLAVLAL")
            }
            dispatch({ type: 'get_total_calls_each_month', payload: arrayVal })
        } catch (e) {
            console.log(e)
        }
    }
}

const searchUsersData = (dispatch) => {
    return async (page, limit, search, startDate, endDate) => {
        try {
            const response = await axios.post('/api/s-get-users-data', { page, limit, search, startDate, endDate })
            console.log(response.data, 'SEARCHREPSONES')
            response.data.docs.forEach((d) => {
                d.formattedTotalDuration = moment.duration(d.totalDuration, "seconds").format("h:mm:ss", { trim: false })
                d.formattedDurationInterval = moment.duration(d.durationInterval, "seconds").format("h:mm:ss", { trim: false })
            })
            dispatch({ type: 'get_users_data', payload: response.data })
            console.log(response.data, 'after')
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}

const searchClientsData = (dispatch) => {
    return async (page, limit, search, startDate, endDate) => {
        try {
            const response = await axios.post('/api/s-get-clients-data', { page, limit, search, startDate, endDate })
            console.log(response.data, 'SEARCHREPSONES Clients')
            response.data.docs.forEach((d) => {
                d.formattedTotalDuration = moment.duration(d.totalDuration, "seconds").format("h:mm:ss", { trim: false })
                d.formattedDurationInterval = moment.duration(d.durationInterval, "seconds").format("h:mm:ss", { trim: false })
            })
            dispatch({ type: 'get_clients_data', payload: response.data })
            console.log(response.data, 'afterClients')
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}

const getHistoryWithNoGuides = (dispatch) => {
    return async (startDate, endDate) => {
        try {
            const response = await axios.get(`/api/get-no-guides-history?startDate=${startDate}&endDate=${endDate}`)
            const newArr = response.data.map((history) => {

                return ({ ...history, title: moment(history.createdAt[0]).tz('America/Detroit').format('h A') })
            })
            dispatch({ type: 'get_no_guides_history_data', payload: newArr })
            console.log(response, 'getHistoryWithNoGuides')
            return newArr
        } catch (e) {
            console.log(e)
        }
    }
}

const getCallsGroupedByLocation = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/get-calls-grouped-by-location`)
            console.log(response, 'grouped')
            dispatch({ type: 'get_grouped_calls', payload: response.data })
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}



const cardDataInit = [
    { title: 'Today', value: 0 },
    { title: 'This Month', value: 0 },
    { title: 'Past 3 Months', value: 0 },
    { title: 'This Year', value: 0 }]

const moreInfoDataInit = [
    { title: 'Total Calls', value: 0 },
    { title: 'Total Calls Selected Dates', value: 0 },
    { title: 'Average Call Duration', value: 0 },
    { title: 'Average Call Duration Selected Dates', value: 0 },
    { title: 'Total Call Duration', value: 0 },
    { title: 'Total Call Duration Selected Dates', value: 0 },
]

export const { Provider, Context } = createDataContext(authReducer,
    { getOverallCalls, getTotalCallsEachMonth, getOverallCallsInfo, getCallsGroupedByLocation, searchUsersData, searchClientsData, registeredMobileUsersData, getHistoryWithNoGuides },
    { groupedCalls: [], totalCallsEachMonth: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], users: [], clients: [], cardData: cardDataInit, moreInfoData: moreInfoDataInit, cardDataClients: cardDataInit, noGuidesHistory: [] })