import React, { useContext, useEffect, useState } from "react";

//material
import {
  Grid,
  makeStyles,
  Switch,
  Typography,
  TableCell,
  Toolbar,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableFooter,
  TablePagination,
  CircularProgress
} from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";
import { Context as CallContext } from "../context/CallContext";
import socket from "../context/socket";

//components
import CallView from "../components/call/CallView";
import SearchHeader from "../components/common/SearchHeader";

//icons
import DuoIcon from "@material-ui/icons/Duo";

const useStyles = makeStyles(theme => ({
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  }
}));

const Calls = ({ history }) => {
  const {
    state: { user, isMic },
    userOnOff,
    changeMicReq
  } = useContext(AuthContext);
  const {
    state: { calls, recordFile, historyId },
    fetchCalls,
    createCall,
    setOpenCallModal,
    fetchOneCall,
    cancelTheCooldown,
    addHistoryRecord
  } = useContext(CallContext);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);

  const classes = useStyles();
  const onSwitchChange = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (!stream) {
        changeMicReq(false);
        return;
      }
      userOnOff();
      changeMicReq(true);
      stream.getTracks().forEach(track => track.stop());

      return;
    } catch (e) {
      console.log(e.name, "error name");
      changeMicReq(false);
      if (
        e.name === "OverconstrainedError" ||
        e.name === "TypeError" ||
        e.name === "NotFoundError"
      ) {
        alert("No microphone found!");
      } else {
        alert("Something went wrong or browser not supported!");
      }
    }
  };

  const fetchAllCalls = async () => {
    const data = await fetchCalls(1, rowsPerPage, search);
    setPaginationData(data);
    setLoaded(true);
  };

  useEffect(() => {
    if (recordFile && historyId) addHistoryRecord(recordFile);
  }, [recordFile, historyId]);

  useEffect(() => {
    fetchAllCalls(1, rowsPerPage, search);
    if (!socket) return;

    socket.on("updateList", roomId => fetchOneCall(roomId));

    return () => socket.off("updateList");
  }, [socket]);

  const onCreateCall = async (clientId, roomId, clientName) => {
    socket.emit("userCalling", { clientId, roomId, userId: user._id }, data => {
      if (data && data.message === 404) return;

      createCall(clientId, roomId, clientName, socket, user._id);
      history.push("/chat");
      setOpenCallModal(true);
      cancelTheCooldown();
    });
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    const data = await fetchCalls(newPage + 1, rowsPerPage, search);
    setPaginationData(data);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    const data = await fetchCalls(1, +event.target.value, search);
    setPaginationData(data);
  };

  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchAllCalls();
    }
  };

  useEffect(() => {
    if (search === "") fetchAllCalls();
  }, [search]);

  return (
    <Grid component={Paper}>
      <Grid>
        <Toolbar className={classes.tableBar} elevation={3}>
          <Grid item sm={5} xs={12} md={5} style={{ display: "flex", flexDirection: "row" }}>
            <DuoIcon style={{ color: "white", width: 32, height: 32 }} />
            <Typography style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}>
              Call panel
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            md={6}
            style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
          >
            <Typography component='div'>
              <Grid component='label' container alignItems='center' spacing={1}>
                <Grid item>
                  <Typography style={{ color: "white" }}>Away</Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={user.available}
                    onChange={() => onSwitchChange()}
                    color='secondary'
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ color: "white" }}>Available</Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <SearchHeader
            onClick={() => fetchAllCalls()}
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            onKeyPress={e => handleKeyDown(e)}
            searchPlaceholder={"Search in calls"}
          />
        </Toolbar>
      </Grid>
      {loaded ? (
        calls.length > 0 ? (
          <TableContainer style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Table>
              <TableBody>
                {calls.map(call => {
                  return (
                    <TableRow hover tabIndex={-1} key={call._id}>
                      <TableCell>
                        <div>
                          <CallView
                            available={user.available}
                            roomId={call._id}
                            call={call}
                            isMic={isMic}
                            onClick={() => onCreateCall(call.client, call._id, call.name)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignSelf: "center"
            }}
          >
            <Typography style={{ margin: "50px" }}>No call records found</Typography>
          </Grid>
        )
      ) : (
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center"
          }}
        >
          <CircularProgress style={{ margin: "50px" }} />
        </Grid>
      )}
    </Grid>
  );
};

export default Calls;
