import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";

//context
import { Context as AppContext } from "../../context/AppContext";

//material
import {
  Grid,
  AppBar,
  Select,
  MenuItem,
  Box,
  Tabs,
  Tab,
  Container,
  CardHeader,
  Card,
  Typography,
  CardContent,
  makeStyles,
  IconButton,
  CardActionArea,
  CircularProgress
} from "@material-ui/core";

//icons
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
  arrowButton: {
    position: "absolute",
    top: 10,
    bottom: 10,
    right: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const AdminTickets = ({ history }) => {
  const {
    state: { tickets },
    adminGetTickets,
    changeTicketStatus
  } = useContext(AppContext);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    onGetTicketsInit();
  }, []);

  const onGetTicketsInit = async () => {
    setLoading(true);
    const data = await adminGetTickets(1, limit, "Support");
    setPaginationData(data);
    setLoading(false);
  };

  const onGetTickets = async (page, limit, index) => {
    const type = index === 0 ? "Support" : "Feedback";
    const data = await adminGetTickets(page, limit, type);
    setPaginationData(data);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setLimit(data.limit);
    setHasMore(data.hasNextPage);
  };

  const onChangeTicketStatus = async (e, ticketId) => {
    changeTicketStatus(ticketId, e.target.value);
  };

  const onLoadMore = async () => {
    if (hasMore) {
      await onGetTickets(page + 1, limit);
    } else {
      return;
    }
  };

  const handleChange = (event, newValue) => {
    setPageIndex(newValue);
    switch (newValue) {
      case 0:
        onGetTickets(1, limit, newValue);
        return;
      case 1:
        onGetTickets(1, limit, newValue);
        return;
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const ticketStatus = status => {
    switch (status) {
      case 0:
        return { color: "red" };
      case 1:
        return { color: "orange" };
      case 2:
        return { color: "green" };
      default:
        return { color: "red" };
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={2}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  const renderTicket = (ticket, index) => {
    return (
      <div key={index}>
        <Card style={{ borderRadius: 0 }}>
          <CardActionArea
            component={"a"}
            onClick={() => history.push("/ticket", { ticketId: ticket._id })}
          >
            <CardHeader
              title={
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ alignSelf: "center" }} variant='body2'>
                    {ticket.userId?.fullName}
                  </Typography>
                  {ticket.type === "Support" ? (
                    <div style={{ marginLeft: "20px", display: "flex", flexDirection: "row" }}>
                      <Select
                        onClick={e => e.stopPropagation()}
                        disableUnderline
                        style={ticketStatus(ticket.status)}
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={ticket.status}
                        onChange={e => onChangeTicketStatus(e, ticket._id)}
                      >
                        <MenuItem onClick={e => e.stopPropagation()} value={0}>
                          Waiting
                        </MenuItem>
                        <MenuItem onClick={e => e.stopPropagation()} value={1}>
                          Responded
                        </MenuItem>
                        <MenuItem onClick={e => e.stopPropagation()} value={2}>
                          Done
                        </MenuItem>
                      </Select>
                      {/* <IconButton onMouseDown={event => event.stopPropagation()} onClick={event => event.stopPropagation()}>
                                        <DeleteIcon />
                                    </IconButton> */}
                    </div>
                  ) : null}
                </Grid>
              }
              subheader={
                <Typography variant='subtitle2' color='textSecondary'>
                  {moment(ticket.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                </Typography>
              }
            />
            <CardContent>
              <Typography style={{ fontSize: 18 }} variant='body2' component='p'>
                Subject: {ticket.title}
              </Typography>
              {/* <Typography variant="body2" color="textSecondary" component="p" style={{ marginRight: '40px' }}>
                                {ticket.description}
                            </Typography> */}
              <Grid className={classes.arrowButton}>
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  };

  return (
    <div>
      <AppBar position='static'>
        <Tabs value={pageIndex} onChange={handleChange} aria-label='simple tabs example'>
          <Tab label='Support' {...a11yProps(0)} />
          <Tab label='Feedback' {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={pageIndex} index={0}>
        <Grid
          container
          style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Container>
                <Grid style={{ margin: "20px" }}>
                  {!loading ? (
                    tickets.length === 0 ? (
                      <Typography align='center'>No tickets</Typography>
                    ) : (
                      <div>
                        <InfiniteScroll
                          loadMore={() => onLoadMore()}
                          useWindow={true}
                          hasMore={hasMore}
                          initialLoad={false}
                          isReverse={false}
                          loader={
                            hasMore ? (
                              <Grid
                                key={0}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  borderRadius: 0
                                }}
                              >
                                <CircularProgress style={{ alignSelf: "center" }} size={20} />
                              </Grid>
                            ) : null
                          }
                        >
                          {tickets.map((ticket, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                              {renderTicket(ticket, index)}
                            </div>
                          ))}
                        </InfiniteScroll>
                      </div>
                    )
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "50px"
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={pageIndex} index={1}>
        <Grid
          container
          style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Container>
                <Grid style={{ margin: "20px" }}>
                  {!loading ? (
                    tickets.length === 0 ? (
                      <Typography align='center'>No tickets</Typography>
                    ) : (
                      <div>
                        <InfiniteScroll
                          loadMore={() => onLoadMore()}
                          useWindow={true}
                          hasMore={hasMore}
                          initialLoad={false}
                          isReverse={false}
                          loader={
                            hasMore ? (
                              <Grid
                                key={0}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  borderRadius: 0
                                }}
                              >
                                <CircularProgress style={{ alignSelf: "center" }} size={20} />
                              </Grid>
                            ) : null
                          }
                        >
                          {tickets.map((ticket, index) => (
                            <div style={{ marginTop: "20px" }} key={index}>
                              {renderTicket(ticket, index)}
                            </div>
                          ))}
                        </InfiniteScroll>
                      </div>
                    )
                  ) : (
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "50px"
                      }}
                    >
                      <CircularProgress />
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default withRouter(AdminTickets);
