import React from "react";

//material
import { Paper, InputBase, IconButton, makeStyles } from "@material-ui/core";

//icons
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: theme.palette.background.default
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  }
}));

const SearchField = ({ onChange, onClick, value, onKeyPress, searchPlaceholder, width = 500 }) => {
  const classes = useStyles();

  return (
    <div style={{ width }}>
      <Paper component='form' className={classes.root}>
        <IconButton className={classes.iconButton} aria-label='menu' onClick={onClick}>
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={searchPlaceholder}
          inputProps={{ "aria-label": "search" }}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </Paper>
    </div>
  );
};

export default SearchField;
