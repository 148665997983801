import React from "react";

//material
import { Card, CardContent, Typography, CardHeader, Grid, makeStyles } from "@material-ui/core";

//style
const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.secondary.main,
    textAlign: "center"
  }
}));

const SquareCard = ({ data, type }) => {
  const classes = useStyles();

  return (
    <Grid style={{ width: "100%" }}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Typography>{data.title}</Typography>
          <Typography variant='h4' className={classes.title} color='textSecondary'>
            {data.value}
          </Typography>
          <Typography style={{ fontSize: 12, textAlign: "right" }}>{type}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SquareCard;
