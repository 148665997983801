import React, { useContext } from "react";

//material
import { Container, Grid, Typography, Card, CardMedia, makeStyles } from "@material-ui/core";

//components
import Login from "../components/auth/Login";

//context
import { Context as AuthContext } from "../context/AuthContext";

//img
import logo from "../images/appstore.png";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  background: {
    backgroundColor: "#0c1720",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "row"
  },
  gridContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  media: {
    height: "200px",
    width: "200px"
  }
}));

const AuthPage = ({ history }) => {
  const classes = useStyles();
  const {
    state: { errorMessage, isAuthenticated },
    signin
  } = useContext(AuthContext);

  if (isAuthenticated) {
    return null;
  }

  return (
    <Grid container className={classes.background}>
      <Grid
        className={classes.gridContainer}
        item
        xs={12}
        md={9}
        sm={9}
        style={{ alignSelf: "center" }}
      >
        <Container
          style={{ flexDirection: "column", display: "flex", justifyContent: "center" }}
          maxWidth='md'
        >
          <Card className={classes.media}>
            <CardMedia className={classes.media} image={logo} />
          </Card>
          <Typography variant='h2' style={{ color: "white", marginTop: "20px" }}>
            Welcome to Tappy Guide!
          </Typography>
          <Typography variant='h6' style={{ color: "white", marginTop: "20px" }}>
            Tappy Guide is a mobile application for people with disabilities, senior citizens and
            veterans. We are the complete tool for an active lifestyle.
          </Typography>
        </Container>
      </Grid>
      <Grid
        className={classes.gridContainer}
        style={{ backgroundColor: "white" }}
        item
        xs={12}
        md={3}
        sm={3}
      >
        <Login history={history} errorMessage={errorMessage} signin={signin} />
      </Grid>
    </Grid>
  );
};

export default AuthPage;
