import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Rnd } from "react-rnd";
import "./VideoPlayer.css";

//material
import { Button, Grid, makeStyles } from "@material-ui/core";

//icons
import SignalCellular0BarIcon from "@material-ui/icons/SignalCellular0Bar";

//style
const useStyles = makeStyles(theme => ({
  backgroundButton: {
    backgroundColor: "rgb(123, 123, 123, 0.1)"
  },
  buttonStyle: {
    color: "black"
  }
}));

const VideoPlayer = ({ url, type, playing, isVideo, myRef }) => {
  const classes = useStyles();
  const [width, setWidth] = useState(190);
  const [height, setHeight] = useState(339);
  const [x, setX] = useState(25);
  const [y, setY] = useState(100);

  const update = size => {
    setWidth(190 * size);
    setHeight(339 * size);
  };

  return (
    <Rnd
      lockAspectRatio={true}
      default={{
        x,
        y,
        width: !isVideo ? 0 : width,
        height: !isVideo ? 0 : height
      }}
      size={!isVideo ? { width: 0, height: 0 } : { width, height }}
      position={{ x, y }}
      minHeight={!isVideo ? 0 : 339}
      minWidth={!isVideo ? 0 : 190}
      bounds='parent'
      onResize={(e, direction, ref, delta, position) => {
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setX(position.x);
        setY(position.y);
      }}
      onDragStop={(e, d) => {
        setX(d.x);
        setY(d.y);
      }}
      style={{ display: "flex", flexDirection: "row", position: "absolute", zIndex: 100 }}
      resizeHandleComponent={{
        bottomRight: isVideo ? <SignalCellular0BarIcon /> : null
      }}
      resizeHandleStyles={{
        bottomRight: {
          width: "32px",
          height: "32px"
        }
      }}
    >
      <div style={isVideo ? { width: "100%", height } : { width: 0, height: 0 }}>
        <div className='player-wrapper'>
          <ReactPlayer
            ref={myRef}
            height={isVideo ? height : 0}
            width={isVideo ? width : 0}
            url={url}
            // src={url}
            controls={false}
            type={type}
            // light={light}
            playing={playing}
            playsinline={true}
          />
        </div>
      </div>
      {isVideo ? (
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Grid className={classes.backgroundButton}>
            <Button className={classes.buttonStyle} onClick={() => update(1)}>
              1x
            </Button>
          </Grid>
          <Grid className={classes.backgroundButton}>
            <Button className={classes.buttonStyle} onClick={() => update(1.5)}>
              2x
            </Button>
          </Grid>
          <Grid className={classes.backgroundButton}>
            <Button className={classes.buttonStyle} onClick={() => update(2)}>
              4x
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Rnd>
  );
};

export default VideoPlayer;
