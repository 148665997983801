import React, { useContext, useEffect, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";

//material
import {
  Grid,
  Divider,
  Avatar,
  Container,
  Paper,
  InputBase,
  CardHeader,
  Card,
  Typography,
  CardContent,
  makeStyles,
  IconButton,
  CardActionArea,
  CircularProgress
} from "@material-ui/core";

//context
import { Context as AuthContext } from "../context/AuthContext";
import { Context as AppContext } from "../context/AppContext";

//icons
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: theme.palette.background.default,
    borderColor: "grey"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    borderColor: "black"
  },
  iconButtonActive: {
    padding: 10,
    color: theme.palette.primary.main
  },
  iconButtonDisabled: {
    padding: 10
  },
  scrollBar: {
    borderTop: "1px solid rgba(187,187,187,0.5)",
    overFlowY: "hidden",
    height: "100%"
  }
}));

const TicketInfo = ({ location, history }) => {
  const classes = useStyles();
  const {
    state: { user }
  } = useContext(AuthContext);
  const {
    state: { comments },
    getOneTicket,
    addTicketComment,
    getTicketComments
  } = useContext(AppContext);
  const [comment, setComment] = useState("");
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);
  const [posting, setPosting] = useState(false);
  const scrollbars = useRef(null);

  useEffect(() => {
    if (!location.state) {
      history.push("/tickets");
    } else {
      onGetOneTicket();
    }
  }, []);

  const onGetOneTicket = async () => {
    try {
      setLoadingTicket(true);
      const ticket = await getOneTicket(location.state.ticketId);
      setLoadingTicket(false);

      setTicket(ticket);
      if (ticket) {
        setLoading(true);
        await getTicketComments(ticket._id);
      }
      setLoading(false);
      setTimeout(() => {
        if (scrollbars.current) {
          scrollbars.current.scrollToBottom();
        }
      }, 500);
    } catch (e) {
      setLoading(false);
      setLoadingTicket(false);
    }
  };

  const onKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!comment) return;
      onAddTicketComment();
    }
  };

  const onAddTicketComment = async () => {
    setPosting(true);
    setComment("");
    const ticketComment = await addTicketComment(ticket._id, comment);
    if (ticketComment) {
      getTicketComments(ticket._id);
      const renewTicket = await getOneTicket(location.state.ticketId);
      setTicket(renewTicket);
    }
    setPosting(false);
    setTimeout(() => {
      if (scrollbars.current) {
        scrollbars.current.scrollToBottom();
      }
    }, 500);
  };

  const ticketStatus = status => {
    switch (status) {
      case 0:
        return <Typography style={{ color: "red", alignSelf: "center" }}>Waiting</Typography>;
      case 1:
        return <Typography style={{ color: "orange", alignSelf: "center" }}>Responded</Typography>;
      case 2:
        return <Typography style={{ color: "green", alignSelf: "center" }}>Done</Typography>;
      default:
        return <Typography style={{ color: "red", alignSelf: "center" }}>Waiting</Typography>;
    }
  };

  const renderComments = (comment, ticket) => {
    if (comment.userId._id === user._id) {
      return (
        <>
          <Grid
            container
            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Grid item sm={4} md={4} xs={4} style={{ paddingLeft: "15px", alignSelf: "center" }}>
              <Typography variant='subtitle2' color='textSecondary' style={{ alignSelf: "center" }}>
                {moment(comment.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </Grid>
            <Grid
              item
              sm={8}
              md={8}
              xs={8}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingRight: "15px"
              }}
            >
              <Paper style={{ margin: "10px", maxWidth: "80%" }} elevation={0}>
                <Typography
                  style={{ margin: "10px", whiteSpace: "pre-line", wordWrap: "break-word" }}
                >
                  {comment.comment}
                </Typography>
              </Paper>
              <Avatar style={{ alignSelf: "center" }}>{comment.userId?.fullName[0]}</Avatar>
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid
            container
            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
          >
            <Grid
              item
              sm={8}
              md={8}
              xs={8}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingLeft: "15px"
              }}
            >
              <Avatar style={{ alignSelf: "center", backgroundColor: "green", fontSize: 10 }}>
                Admin
              </Avatar>
              <Paper style={{ margin: "10px", maxWidth: "80%" }} elevation={0}>
                <Typography
                  style={{ margin: "10px", whiteSpace: "pre-line", wordWrap: "break-word" }}
                >
                  {comment.comment}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              sm={4}
              md={4}
              xs={4}
              style={{
                paddingRight: "20px",
                alignSelf: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end"
              }}
            >
              <Typography variant='subtitle2' color='textSecondary' style={{ alignSelf: "center" }}>
                {moment(comment.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  if (loadingTicket) {
    return (
      <Grid
        style={{
          height: "85vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <CircularProgress style={{ alignSelf: "center" }} />
      </Grid>
    );
  }

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "85vh",
        marginTop: "1%"
      }}
    >
      <Grid item xs={12} md={12} sm={12}>
        <Paper elevation={3} square>
          {ticket ? (
            <CardHeader
              title={
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ alignSelf: "center" }} variant='body2'>
                    {ticket.userId?.fullName}
                  </Typography>
                  {ticket.type === "Support" ? (
                    <div style={{ marginLeft: "20px", display: "flex", flexDirection: "row" }}>
                      {ticketStatus(ticket.status)}
                    </div>
                  ) : null}
                </Grid>
              }
              subheader={
                <>
                  <Typography variant='subtitle2' color='textSecondary'>
                    {moment(ticket.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                  </Typography>
                  <Typography style={{ fontSize: 18 }} variant='body2' component='p'>
                    Title: {ticket.title}
                  </Typography>
                  <Typography style={{ fontSize: 18 }} variant='body2' component='p'>
                    Description: {ticket.description}
                  </Typography>
                </>
              }
            />
          ) : null}
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{ borderLeft: "0.5px solid #ccc", borderRight: "0.5px solid #ccc" }}
      >
        {ticket ? (
          <Grid>
            {!loading ? (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ display: "flex", flexDirection: "column", height: "680px" }}
              >
                <Scrollbars className={classes.scrollBar} ref={scrollbars}>
                  {comments.map((comment, index) => (
                    <div key={index}>{renderComments(comment, ticket)}</div>
                  ))}
                </Scrollbars>
                {posting ? <Typography style={{ margin: "10px" }}>Posting...</Typography> : null}
              </Grid>
            ) : (
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "680px"
                }}
              >
                <CircularProgress style={{ alignSelf: "center" }} />
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "85vh"
            }}
          >
            <Typography align='center'>No ticket found</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        style={{ width: "100%", height: "40px", marginBottom: "20px" }}
      >
        <Paper component='form' className={classes.root} variant='outlined'>
          <InputBase
            className={classes.input}
            placeholder={"Add a comment"}
            inputProps={{ "aria-label": "search" }}
            value={comment}
            onChange={e => setComment(e.target.value)}
            onKeyPress={onKeyPress}
            multiline
            rowsMax={4}
          />
          <IconButton
            className={!comment ? classes.iconButtonDisabled : classes.iconButtonActive}
            disabled={!comment}
            aria-label='menu'
            onClick={() => onAddTicketComment()}
          >
            <SendIcon />
          </IconButton>
        </Paper>
      </Grid>
    </Container>
  );
};

export default withRouter(TicketInfo);
