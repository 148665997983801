import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

//material
import {
  makeStyles,
  Typography,
  Container,
  CssBaseline,
  Grid,
  Button,
  Paper,
  List,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from "@material-ui/core";

//images
import ContactPicture from "../images/appstore.png";

//icons
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles(theme => ({
  heroContent: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(8, 0, 6)
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  typoTitle: {
    color: "white",
    alignSelf: "center"
  },
  nameTitle: {
    color: theme.palette.primary.main,
    alignSelf: "center"
  }
}));

const ContactPage = () => {
  const classes = useStyles();
  const [contactForm, setContactForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    text: "",
    agree: false
  });
  const [errorMessage, setErrorMessage] = useState("");

  const clearFormState = () => {
    setContactForm({
      firstName: "",
      lastName: "",
      email: "",
      text: "",
      agree: false
    });
  };

  const postEmail = async () => {
    try {
      const response = await axios.post("/api/contactform", contactForm);
      clearFormState();
      setErrorMessage("");
    } catch (e) {
      console.log(e.response);
      if (e.response) {
        setErrorMessage(e.response.data.error);
      }
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Paper className={classes.heroContent} elevation={3}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className={classes.gridItem}>
                <Typography className={classes.typoTitle} variant='h3'>
                  <strong>Contact us</strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                <img style={{ width: "100px", height: "100px" }} src={ContactPicture}></img>
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <Container style={{ paddingTop: "20px", paddingBottom: "50px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: "40px" }}>
                  <Typography className={classes.nameTitle} variant='h4'>
                    <strong>Tappy Guide</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary='+8127318923' />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <RoomIcon className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary='street' />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <EmailIcon className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary='office@tappyguide.ro' />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FacebookIcon className={classes.iconColor} />
                      </ListItemIcon>
                      <ListItemText primary='/tappyguide' />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='firstName'
                    label='First Name'
                    name='firstName'
                    onChange={e => setContactForm({ ...contactForm, firstName: e.target.value })}
                    value={contactForm.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin='normal'
                    fullWidth
                    required
                    id='lastName'
                    label='Last Name'
                    name='lastName'
                    onChange={e => setContactForm({ ...contactForm, lastName: e.target.value })}
                    value={contactForm.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label='Email address'
                    name='email'
                    onChange={e => setContactForm({ ...contactForm, email: e.target.value })}
                    value={contactForm.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    style={{ width: "100%", marginTop: "20px" }}
                    rows={9}
                    multiline
                    margin='normal'
                    variant='outlined'
                    required
                    id='text'
                    label='Message'
                    name='text'
                    onChange={e => setContactForm({ ...contactForm, text: e.target.value })}
                    value={contactForm.text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Checkbox
                    checked={contactForm.agree}
                    onChange={e => setContactForm({ ...contactForm, agree: e.target.checked })}
                    value={contactForm.agree}
                  />
                  <Typography style={{ alignSelf: "center" }}>
                    Yes, I consent to my data being stored according to the guidlines set out in the{" "}
                    <Link to='/license-agreement'>Privacy policy</Link>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Button
                    disabled={
                      contactForm.agree === false ||
                      contactForm.email === "" ||
                      contactForm.text === ""
                    }
                    onClick={() => {
                      postEmail();
                    }}
                    style={{ width: "100px", color: "white" }}
                    variant='contained'
                    color='secondary'
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
};

export default ContactPage;
