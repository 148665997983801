import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import "./MyModalStyle.css";

export const MyModal = ({ modalOpen, onClose, setTimesOfFormats, setModalOpen }) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);

    // Check if the end time is before the start time
    if (endDateTime < startDateTime) {
      setError("End time cannot be before the start time.");
      return;
    }

    // Get the current date and time
    const now = new Date();

    // Check if either start time or end time is before the current date and time
    if (startDateTime < now || endDateTime < now) {
      setError("Start time and end time must be in the future.");
      return;
    }

    // Format the start and end times to ISO 8601 format
    const startTimeFormatted = startDateTime.toISOString();
    const endTimeFormatted = endDateTime.toISOString();

    setTimesOfFormats({
      startTime: startTimeFormatted,
      endTime: endTimeFormatted
    });

    console.log({
      startTimeF: startTimeFormatted,
      endTimeF: endTimeFormatted,
      startTime: startTime,
      endTime: endTime
    });
    setModalOpen(false);
    setError("");
  };
  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      {/* Content of the modal goes here */}
      <div className='container'>
        <form className='form-container' onSubmit={handleSubmit}>
          <div className='inputDiv'>
            <label htmlFor='start-time' className='form-label'>
              Start Time:
            </label>
            <input
              className='form-input'
              type='datetime-local'
              id='start-time'
              name='start-time'
              value={startTime}
              onChange={e => setStartTime(e.target.value)}
              required
            />
          </div>
          <div className='inputDiv'>
            <label htmlFor='end-time' className='form-label'>
              End Time:
            </label>
            <input
              className='form-input'
              type='datetime-local'
              id='end-time'
              name='end-time'
              value={endTime}
              onChange={e => setEndTime(e.target.value)}
              required
            />
          </div>
          <p className='errorP'>{error}</p>
          <button type='submit' className='form-submit-button'>
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
};
