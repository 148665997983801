import React, { useState, useContext, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

//material
import { Grid, Typography, Popover, makeStyles, Container } from "@material-ui/core";

//context
import { Context as EventContext } from "../context/EventContext";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}));

const MainPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState("");
  const {
    state: { acceptedEvents },
    getAcceptedEvents
  } = useContext(EventContext);

  useEffect(() => {
    getAcceptedEvents();
  }, []);

  const openPopover = (eventObj, v) => {
    setSelectedEvent(eventObj);
    setAnchorEl(v.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid className={classes.container}>
      <Container className='App' style={{ marginTop: "2%" }}>
        <Calendar
          localizer={localizer}
          defaultView='month'
          events={acceptedEvents}
          style={{ height: "90vh" }}
          onSelectEvent={(e, v) => {
            openPopover(e, v);
          }}
        />
      </Container>
      <Popover
        id={anchorEl}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid style={{ maxWidth: "500px" }}>
          <Typography className={classes.typography}>{selectedEvent.title}</Typography>
          <Typography className={classes.typography}>
            Destination: {selectedEvent.destination}
          </Typography>
          <Typography className={classes.typography}>Reason: {selectedEvent.reason}</Typography>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default MainPage;
