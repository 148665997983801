import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//material
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CircularProgress,
  makeStyles,
  Toolbar,
  Grid,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TableContainer,
  TablePagination,
  Button
} from "@material-ui/core";

//context
import { Context as UserContext } from "../../context/UserContext";

//component
import SquareCard from "../../components/common/squareCard";

//icons
import StarRateIcon from "@material-ui/icons/StarRate";

//style
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  }
}));

const AgentInfo = ({ location, history }) => {
  const id = location.state.userId;
  const {
    state: { user, isLoading, comments, userLogs },
    fetchCallCenterCommentsUser,
    fetchCallCenterUser,
    getUserLogs
  } = useContext(UserContext);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [logsPage, setLogsPage] = useState(1);

  const [hasMore, setHasMore] = useState(false);
  const [hasMoreLogs, setHasMoreLogs] = useState(false);

  const [commLoading, setCommLoading] = useState(false);
  const [dataOnDate, setDataOnDate] = useState(null);

  const [limit, setLimit] = useState(50);
  const [logsLimit, setLogsLimit] = useState(5);

  const [count, setCount] = useState(1);
  const [logsCount, setLogsCount] = useState(1);
  const [startLogsDate, setStartLogsDate] = useState(new Date());
  const [endLogsDate, setEndLogsDate] = useState(new Date());

  const logsType = "Availability";

  useEffect(() => {
    if (!id) {
      history.back();
    }
    fetchCallCenterUser(id);
    onGetUserLogs(logsPage, logsLimit, logsType, startLogsDate, endLogsDate);
    getCommentsInit(id, 1, limit);
  }, []);

  const onGetUserLogs = async (logsPage, logsLimit, logsType, startLogsDate, endLogsDate) => {
    const logs = await getUserLogs(id, logsPage, logsLimit, logsType, startLogsDate, endLogsDate);
    setLogsPaginationData(logs.logs);
    setDataOnDate(logs.history[0]);
  };

  const getComments = async (userId, page, limit) => {
    const comm = await fetchCallCenterCommentsUser(userId, page, limit);
    setPaginationData(comm);
  };

  const getCommentsInit = async (userId, page, limit) => {
    setCommLoading(true);
    const comm = await fetchCallCenterCommentsUser(userId, page, limit);
    setPaginationData(comm);
    setCommLoading(false);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setLimit(data.limit);
    setCount(data.totalDocs);
    setHasMore(data.hasNextPage);
  };

  const setLogsPaginationData = async data => {
    if (!data) return;
    setLogsPage(data.page);
    setLogsLimit(data.limit);
    setLogsCount(data.totalDocs);
    setHasMoreLogs(data.hasNextPage);
  };

  const logsColumn = [
    {
      id: "createdAt",
      label: "Time",
      align: "left",
      format: value => (
        <div>
          <Typography> {moment(value.createdAt).format("h:mm A")}</Typography>
        </div>
      )
    },
    {
      id: "active",
      label: "Active",
      align: "left",
      format: value => (
        <div>
          <Typography> {value.typeBoolean === true ? "Available" : "Unavailable"}</Typography>
        </div>
      )
    },
    { id: "description", label: "Description", align: "left" },
    {
      id: "date",
      label: "Date",
      align: "left",
      format: value => (
        <div>
          <Typography> {moment(value.createdAt).format("MMMM DD")}</Typography>
        </div>
      )
    },
    { id: "browser", label: "Browser", align: "left" }
  ];

  const handleChangePage = async (event, newPage) => {
    onGetUserLogs(newPage + 1, logsLimit, logsType, startLogsDate, endLogsDate);
  };

  const handleChangeRowsPerPage = async event => {
    onGetUserLogs(1, +event.target.value, logsType, startLogsDate, endLogsDate);
  };

  if (isLoading) {
    return (
      <Grid
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "90vh" }}
        className='loader'
      >
        <CircularProgress style={{ alignSelf: "center" }} size={20} />
      </Grid>
    );
  }

  const onLoadMore = async () => {
    if (hasMore) {
      await getComments(id, page + 1, limit);
    } else {
      return;
    }
  };

  const onChangeDate = async (date, type) => {
    if (type === "start") {
      setStartLogsDate(date);
      onGetUserLogs(1, logsLimit, logsType, date, endLogsDate);
    } else {
      setEndLogsDate(date);
      onGetUserLogs(1, logsLimit, logsType, startLogsDate, date);
    }
  };

  const renderItem = item => {
    return (
      <Card elevation={0} style={{ borderRadius: 10, marginTop: "1%", backgroundColor: "#ededed" }}>
        <CardHeader
          avatar={
            <Avatar
              src={item.clientId.profilePicture.thumb}
              aria-label='recipe'
              className={classes.avatar}
            >
              {item.clientId.firstName[0]}
            </Avatar>
          }
          title={
            <Grid>
              <Typography>{item.clientId.fullName}</Typography>
              <Grid>
                {Array(item.userRate)
                  .fill()
                  .map((rate, index) => (
                    <StarRateIcon
                      key={index}
                      stroke={"grey"}
                      strokeWidth={0.5}
                      style={{ color: "yellow" }}
                    />
                  ))}
              </Grid>
            </Grid>
          }
          subheader={moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
        />
        <CardContent>
          <Typography variant='body2' color='textSecondary' component='p'>
            {item.clientDescription}
          </Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <Grid>
          <Toolbar className={classes.tableBar} elevation={3}>
            <Grid item sm={6} xs={12} md={6} style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}
              >{`${user.fullName}(${user.email})`}</Typography>
            </Grid>
          </Toolbar>
        </Grid>
        <Container
          maxWidth='md'
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "30px"
          }}
        >
          <Grid item style={{ flexGrow: 1 }}>
            <Grid
              container
              justifyContent='center'
              spacing={2}
              style={{
                margin: 0,
                width: "100%"
              }}
            >
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{ title: "Total Calls", value: user.data ? user.data?.totalCalls : 0 }}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{
                    title: "Total Duration",
                    value: moment
                      .duration(user.data?.totalDuration, "seconds")
                      .format("h:mm:ss", { trim: false })
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{ title: "Rating Score", value: user.data ? user.data?.rating : 0 }}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard data={{ title: "Total Ratings", value: count }} />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent='center'
              spacing={2}
              style={{
                margin: 0,
                width: "100%"
              }}
            >
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{ title: "Total Calls", value: dataOnDate ? dataOnDate.totalCalls : 0 }}
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{
                    title: "Total Duration",
                    value: dataOnDate
                      ? moment
                          .duration(dataOnDate.totalDuration, "seconds")
                          .format("h:mm:ss", { trim: false })
                      : 0
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3} sm={3}>
                <SquareCard
                  data={{
                    title: "Total Availability",
                    value: dataOnDate ? dataOnDate.availability : 0
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Paper className={classes.paperContainer} elevation={0}>
            <Grid item style={{ flexGrow: 1, marginTop: "50px" }}>
              <Card>
                <CardHeader
                  title={
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                      }}
                    >
                      <Typography align='center' style={{ alignSelf: "center" }}>
                        User availability
                      </Typography>
                      <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <KeyboardDatePicker
                          PopoverProps={{
                            anchorOrigin: { vertical: "bottom", horizontal: "right" }
                          }}
                          variant='inline'
                          inputVariant='outlined'
                          format='MM/dd/yyyy'
                          margin='normal'
                          label='Select start date'
                          value={startLogsDate}
                          onChange={date => onChangeDate(date, "start")}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          style={{ marginRight: "20px" }}
                          autoOk={true}
                        />
                        <KeyboardDatePicker
                          PopoverProps={{
                            anchorOrigin: { vertical: "bottom", horizontal: "right" }
                          }}
                          variant='inline'
                          inputVariant='outlined'
                          format='MM/dd/yyyy'
                          margin='normal'
                          label='Select end date'
                          value={endLogsDate}
                          onChange={date => onChangeDate(date, "end")}
                          KeyboardButtonProps={{
                            "aria-label": "change date"
                          }}
                          style={{ marginRight: "20px" }}
                          autoOk={true}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </Card>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {logsColumn.map(column => (
                      <TableCell
                        key={column.id}
                        style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                        align={column.align}
                      >
                        <div>{column.label}</div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {
                  <TableBody>
                    {userLogs.map(row => {
                      return (
                        <TableRow hover tabIndex={-1} key={row._id}>
                          {logsColumn.map(column => {
                            const value = row[column.id];
                            return (
                              <TableCell align={column.align} key={column.id}>
                                <div>{column.format ? column.format(row) : value}</div>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                }
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={logsCount}
                      rowsPerPage={logsLimit}
                      page={logsPage - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
          <Grid item style={{ marginTop: "50px" }}>
            {!commLoading ? (
              <Grid>
                {comments.map((comment, index) => (
                  <div key={comment._id}>{renderItem(comment)}</div>
                ))}
                {hasMore ? (
                  <Card
                    key={0}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      borderRadius: 0
                    }}
                  >
                    <Button onClick={() => onLoadMore()}>Load more</Button>
                  </Card>
                ) : null}
              </Grid>
            ) : (
              <Card
                key={0}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  borderRadius: 0
                }}
              >
                <CircularProgress style={{ alignSelf: "center" }} size={20} />
              </Card>
            )}
          </Grid>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default withRouter(AgentInfo);
