import createDataContext from './createDataContext';
import axios from 'axios';
import moment from 'moment'

const eventReducer = (state, action) => {
    switch (action.type) {
        case 'get_accepted_events':
            const formattedDate = action.payload.map((item) => {
                return { ...item, start: moment(item.start).toDate(), end: moment(item.end).toDate() }
            })
            return { ...state, acceptedEvents: formattedDate }
        case 'get_events':
            return { ...state, events: action.payload }
        case 'change_notification':
            return { ...state, notification: action.payload }
        case 'add_event':
            return { ...state, events: [...state.events, action.payload] }
        case 'update_event':
            const eventIndexToUpdate = state.events.findIndex(e => e._id === action.payload._id)
            if (eventIndexToUpdate === -1) {
                return { ...state }
            }
            let eventsUpdated = [...state.events]
            eventsUpdated[eventIndexToUpdate] = action.payload
            console.log(action.payload, 'update EVENT')
            return { ...state, events: eventsUpdated }
        case 'delete_event':
            const eventIndexToDelete = state.events.findIndex(e => e._id === action.payload._id)
            if (eventIndexToDelete === -1) {
                return { ...state }
            }
            let events = [...state.events]
            events.splice(eventIndexToDelete, 1)
            return { ...state, events }
        case 'accept_decline_event':
            const eventIndex = state.events.findIndex(e => e._id === action.payload._id)
            state.events[eventIndex].accepted = action.payload.accepted
            console.log(state.events, 'REDUCER EVENT')
            return { ...state }
        //logs
        case 'get_events_log':
            if (action.payload.page === 1) {
                return { ...state, eventsLog: action.payload.docs, hasMoreLogs: action.payload.hasNextPage, pageLogs: action.payload.page }
            } else {
                return { ...state, eventsLog: [...state.eventsLog, ...action.payload.docs], pageLogs: action.payload.page, hasMoreLogs: action.payload.hasNextPage }
            }
        case 'get_events_admin':
            return { ...state, eventsAdmin: action.payload.docs }
        default:
            return state;
    }
}

const getAcceptedEvents = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/get-accepted-events-user`)
            console.log(response, 'accepted EVENTS')
            dispatch({ type: 'get_accepted_events', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const getEvents = (dispatch) => {
    return async (info) => {
        try {
            const response = await axios.get(`/api/get-events-user`)
            console.log(response, 'EVENTS')
            if (info === 'startup' && response.data.length > 0) {
                dispatch({ type: 'change_notification', payload: true })
            } else {
                const seenEvents = await axios.post(`/api/seen-events-user`)
                console.log(seenEvents, 'seen events')
            }
            dispatch({ type: 'get_events', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const addEvent = (dispatch) => {
    return async (event) => {
        try {
            dispatch({ type: 'add_event', payload: event })
            dispatch({ type: 'change_notification', payload: true })
        } catch (e) {
            console.log(e)
        }
    }
}

const updateEvent = (dispatch) => {
    return async (event) => {
        try {
            dispatch({ type: 'update_event', payload: event })
            dispatch({ type: 'change_notification', payload: true })
        } catch (e) {
            console.log(e)
        }
    }
}

const deleteEvent = (dispatch) => {
    return async (event) => {
        try {
            dispatch({ type: 'delete_event', payload: event })
            dispatch({ type: 'change_notification', payload: true })
        } catch (e) {
            console.log(e)
        }
    }
}

const changeNotificationStatus = (dispatch) => {
    return async (notification) => {
        try {
            dispatch({ type: 'change_notification', payload: notification })
        } catch (e) {
            console.log(e)
        }
    }
}

const acceptEvent = (dispatch) => {
    return async (eventId) => {
        try {
            const response = await axios.post(`/api/accept-event-user`, { eventId })
            console.log(response, 'accept event')
            dispatch({ type: 'accept_decline_event', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

const declineEvent = (dispatch) => {
    return async (eventId) => {
        try {
            const response = await axios.post(`/api/decline-event-user`, { eventId })
            console.log(response, 'decline event')
            dispatch({ type: 'accept_decline_event', payload: response.data })
        } catch (e) {
            console.log(e)
        }
    }
}

//admin
const getEventsLog = (dispatch) => {
    return async (page, limit, search) => {
        try {
            const response = await axios.get(`/api/get-event-logs?page=${page}&limit=${limit}&search=${search}`)
            console.log(response, 'logs event')
            dispatch({ type: 'get_events_log', payload: response.data })

        } catch (e) {
            console.log(e)
        }
    }
}

const getEventsAdmin = (dispatch) => {
    return async (page, limit) => {
        try {
            const response = await axios.get(`/api/admin-get-events?page=${page}&limit=${limit}`)
            console.log(response, 'logs event')
            dispatch({ type: 'get_events_admin', payload: response.data })
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}



export const { Provider, Context } = createDataContext(eventReducer,
    { getAcceptedEvents, getEvents, acceptEvent, declineEvent, addEvent, changeNotificationStatus, updateEvent, deleteEvent, getEventsLog, getEventsAdmin },
    { acceptedEvents: [], eventsAdmin: [], events: [], notification: false, eventsLog: [], pageLogs: 1, limitLogs: 50, hasMoreLogs: false })