import { Modal } from "@material-ui/core";

import React from "react";
import { useQuoteCtx } from "../utils/hooks/useQuoteCtx";
import { Close } from "@material-ui/icons";

function customFormat(date) {
  if (!date) return "";

  const dateObj = new Date(date);

  let month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
  let day = dateObj.getDate().toString().padStart(2, "0");
  let year = dateObj.getFullYear().toString().slice(-2); // Get the last 2 digits of the year

  let hours = dateObj.getHours();
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    hours = hours === 12 ? hours : hours - 12;
  } else if (hours === 0) {
    hours = 12; // Convert 0 hours to 12 for 12-hour format
  }

  let minutes = dateObj.getMinutes().toString().padStart(2, "0");
  let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes} ${period}`;

  return `${month}/${day}/${year} ${formattedTime}`;
}
export const ReservedParkingModal = ({ modalOpen, onClose }) => {
  const {
    state: { bookedQuote, error, isLoading },
    cancelBooking
  } = useQuoteCtx();

  const locationData = bookedQuote?._embedded?.["pw:location"];

  const onCancel = async () => {
    const customerEmail = bookedQuote?._embedded?.["pw:customer"]?.email;

    if (!customerEmail) return console.log("no customer email");

    await cancelBooking(bookedQuote?.id, customerEmail);
    onClose();
  };

  return (
    <Modal
      open={modalOpen}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "10px",
          width: "100%",
          maxWidth: "400px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          zIndex: 100
        }}
      >
        <Close onClick={onClose} style={{ cursor: "pointer" }} />
        <h1 className='name'>{locationData?.name} </h1>
        <h1 className='name'>{locationData?.city} city</h1>
        <h1 className='name'>{locationData?.address1} street</h1>

        {error && <p className='errorP'>{error?.error}</p>}

        {bookedQuote?.cancellable === false && (
          <p className='errorP'>You can't cancel this booking</p>
        )}

        <p>Start time: {customFormat(bookedQuote?.start_time)}</p>
        <p>End time: {customFormat(bookedQuote?.end_time)}</p>

        <div className='row'>
          <p className='optionP'>price: ${bookedQuote?.fullPrice?.USD || 0} USD</p>
          <button
            onClick={onCancel}
            disabled={!bookedQuote?.cancellable}
            className='button'
            style={{ backgroundColor: "red" }}
          >
            {isLoading ? "Loading" : "CANCEL"}
          </button>
        </div>
      </div>
    </Modal>
  );
};
