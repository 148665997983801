import { useState } from "react";

import React from "react";
import { Close } from "@material-ui/icons";

import "./PopupStyle.css";
import { useQuoteCtx } from "../utils/hooks/useQuoteCtx";

function extractKeyValuePairs(str) {
  if (!str) return null;
  const decodedStr = decodeURIComponent(str);
  const stringWithRemovedPlusses = decodedStr.replace(/\+/g, "");
  const stirngWithFixedMissingCommas = stringWithRemovedPlusses
    .replace(/"\s*"/g, '", "')
    .replace(/}\s*"/g, '}, "')
    .replace(/]\s*"/g, '], "')
    .replace(/,\s*"/g, ', "');

  let obj = {};
  try {
    console.log("cleanedStr", stirngWithFixedMissingCommas);

    obj = JSON.parse(stirngWithFixedMissingCommas);
  } catch (error) {
    return null;
  }

  return obj;
}

export const PopUp = ({
  mapRef,
  anchorX,
  anchorY,
  handleDirectionsRender,
  fullLocationData,
  map,
  setFullLocationData
}) => {
  const [error, setError] = useState("");
  const [plateNumber, setPlateNumber] = useState("");

  const { bookParkingSpace } = useQuoteCtx();

  if (anchorX === null || anchorY === null || !fullLocationData) return <></>;

  const handleBook = async (quoteId, finalPrice) => {
    if (!plateNumber) {
      setError("Plese fill the plate number");
      return;
    }

    const reply = await bookParkingSpace(quoteId, finalPrice, plateNumber);

    if (reply) {
      setFullLocationData(null);
      handleDirectionsRender(reply);
    }
  };

  const locationData = fullLocationData._embedded["pw:location"];

  const cancellableStatus = fullLocationData?.purchase_options[0]?.cancellable_status;

  const validationSteps = fullLocationData?.purchase_options[0]?.validation?.validation_steps;

  const disclaimers = extractKeyValuePairs(fullLocationData?.purchase_options[0]?.disclaimers[0]);

  const amenities = fullLocationData?.purchase_options[0]?.amenities;

  const locationImage = locationData?.photos?.[0]?.sizes?.original?.URL;

  const arcgisStyles = {
    position: "absolute",
    top: anchorY,
    left: anchorX,
    padding: "10px",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#fff",
    maxHeight: "600px",
    overflowY: "scroll",
    borderRadius: "4px"
  };

  const googleStyles = {
    backgroundColor: "#fff",
    borderRadius: "4px",
    position: "absolute",
    zIndex: 456,
    overflowY: "scroll",
    minWidth: "400px",
    maxHeight: "600px",
    padding: "10px"
  };

  return (
    <div onWheel={e => e.stopPropagation()} style={map === "arcgis" ? arcgisStyles : googleStyles}>
      <Close style={{ cursor: "pointer" }} onClick={() => setFullLocationData(null)} />
      <img
        src={locationImage}
        alt='location Image'
        style={{
          width: "100%",
          height: "200px"
        }}
      />
      <h1 className='name'>{locationData.name} </h1>
      {locationData?.description && <p>{locationData.description}</p>}
      <h1 className='name'>{locationData.city} city</h1>
      <h1 className='name'>{locationData.address1} street</h1>

      <div className='input-container'>
        <label className='label' htmlFor='username'>
          PlateNumber
        </label>
        <input
          className='input'
          type='text'
          id='plateNumber'
          name='plateNumber'
          value={plateNumber}
          onChange={e => setPlateNumber(e.target.value)}
        />
        {cancellableStatus && (
          <p
            style={{
              textAlign: "center"
            }}
            className='cancellableP'
          >
            {cancellableStatus.message}
          </p>
        )}
      </div>
      <p className='errorP'>{error}</p>
      {disclaimers && (
        <>
          <h4>Disclaimers</h4>
          {Object.keys(disclaimers).map((key, index) => (
            <div key={index} className='row'>
              <p className='optionP'>
                {index + 1}. {key}:{" "}
                {typeof disclaimers[key] !== "object"
                  ? typeof disclaimers[key] === "boolean"
                    ? disclaimers[key]
                      ? "Yes"
                      : "No"
                    : disclaimers[key]
                  : ""}
              </p>
            </div>
          ))}
        </>
      )}

      <h4>Validation Steps</h4>
      {validationSteps &&
        validationSteps.map((step, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: 20
            }}
          >
            <img
              src={step.icon.path}
              style={{
                height: 50,
                width: 50,
                borderRadius: 10
              }}
            />
            <p className='optionP'>
              {index + 1}. {step.instructions}
            </p>
          </div>
        ))}
      <h4>Amenities</h4>
      {amenities &&
        amenities.map((amenity, index) => (
          <div key={index} className='row'>
            <p
              style={{
                color: amenity.enabled ? "green" : "red"
              }}
              className='optionP'
            >
              {index + 1}. {amenity.name}
            </p>
          </div>
        ))}
      {fullLocationData.purchase_options.map(option => {
        if (
          option.space_availability.status === "available" ||
          option.space_availability.status === "limited"
        ) {
          return (
            <div key={option.price.USD} className='row'>
              <p className='optionP'>price: {option.price.USD} USD</p>
              <button className='button' onClick={() => handleBook(option.id, option.price.USD)}>
                book
              </button>
            </div>
          );
        }
        return <></>;
      })}
    </div>
  );
};
