import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment'

import DateFnsUtils from '@date-io/date-fns';
import { Bar } from 'react-chartjs-2';

//material
import {
    Grid,
    Typography,
    Divider,
    AppBar,
    Tabs,
    Tab,
    Box,
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    Paper,
    IconButton,
    makeStyles,
    TableContainer,
    TablePagination,
    Button
} from '@material-ui/core'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { CSVLink } from "react-csv";

//context
import { Context as StatisticsContext } from '../../context/StatisticsContext'

//components
import SquareCard from '../../components/common/squareCard'
import GridListInfo from '../../components/common/GridListInfo'
import SearchField from '../../components/common/SearchField'

//icons
import DescriptionIcon from '@material-ui/icons/Description';

//style
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tableHeader: {
        backgroundColor: theme.palette.primary.main
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Statistics = () => {
    const { state: { cardData, totalCallsEachMonth, users, clients, moreInfoData, cardDataClients, groupedCalls },
        getOverallCalls, getTotalCallsEachMonth, searchUsersData, getOverallCallsInfo, searchClientsData, registeredMobileUsersData, getCallsGroupedByLocation } = useContext(StatisticsContext)
    const [overallSelectedDateStart, setOverallSelectedDateStart] = useState(new Date());
    const [overallSelectedDateEnd, setOverallSelectedDateEnd] = useState(new Date());
    const [userSelectedDateStart, setUserSelectedDateStart] = useState(new Date());
    const [userSelectedDateEnd, setUserSelectedDateEnd] = useState(new Date());
    const [clientSelectedDateStart, setClientSelectedDateStart] = useState(new Date());
    const [clientSelectedDateEnd, setClientSelectedDateEnd] = useState(new Date());
    const [searchClient, setSearchClient] = useState('')
    const [searchUser, setSearchUser] = useState('')
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [count, setCount] = useState(1)

    const [pageIndex, setPageIndex] = useState(0);
    const classes = useStyles();

    const columnsUsers = [
        {
            id: 'userId', label: 'Agent Name', align: "left",
            format: value => (
                <Typography>{value.userId.fullName}</Typography>
            )
        },
        { id: 'totalCalls', label: 'Total Calls', align: "left" },
        { id: 'missedCalls', label: 'Missed Calls', align: "left" },
        {
            id: 'totalDuration', label: 'Total duration of Calls', align: "left",
            format: value => (
                <Typography>{moment.duration(value.totalDuration, "seconds").format("h:mm:ss", { trim: false })}</Typography>
            )
        },
        { id: 'dayCalls', label: `Calls: ${moment(userSelectedDateStart).format('MM/DD/YYYY')} - ${moment(userSelectedDateEnd).format('MM/DD/YYYY')}`, align: "left" },
        {
            id: 'durationInterval', label: `Duration: ${moment(userSelectedDateStart).format('MM/DD/YYYY')} - ${moment(userSelectedDateEnd).format('MM/DD/YYYY')}`, align: "left",
            format: value => (
                <Typography>{moment.duration(value.durationInterval, "seconds").format("h:mm:ss", { trim: false })}</Typography>
            )
        },
        { id: 'monthCalls', label: `Calls: ${moment(userSelectedDateStart).format('MMMM')}`, align: "left" },
        {
            id: 'rating', label: 'Rating', align: "left",
            format: value => (
                <Typography>{parseFloat(value.rating).toFixed(2)}</Typography>
            )
        },
    ];

    const columnsClients = [
        {
            id: 'clientId', label: 'Client Name', align: "left",
            format: value => (
                <Typography>{value.clientId.fullName}</Typography>
            )
        },
        { id: 'totalCalls', label: 'Total Calls', align: "left" },
        {
            id: 'totalDuration', label: 'Total duration of Calls', align: "left",
            format: value => (
                <Typography>{moment.duration(value.totalDuration, "seconds").format("h:mm:ss", { trim: false })}</Typography>
            )
        },
        { id: 'dayCalls', label: `Calls: ${moment(clientSelectedDateStart).format('MM/DD/YYYY')} - ${moment(clientSelectedDateEnd).format('MM/DD/YYYY')}`, align: "left" },
        {
            id: 'durationInterval', label: `Duration: ${moment(clientSelectedDateStart).format('MM/DD/YYYY')} - ${moment(clientSelectedDateEnd).format('MM/DD/YYYY')}`, align: "left",
            format: value => (
                <Typography>{moment.duration(value.durationInterval, "seconds").format("h:mm:ss", { trim: false })}</Typography>
            )
        },
        { id: 'monthCalls', label: `Calls: ${moment(clientSelectedDateStart).format('MMMM')}`, align: "left" },
        // {
        //     id: 'rating', label: 'Rating', align: "left",
        //     format: value => (
        //         <Typography>{parseFloat(value.rating).toFixed(2)}</Typography>
        //     )
        // },
    ];

    const handleChange = (event, newValue) => {
        console.log(newValue)
        setPageIndex(newValue)
        switch (newValue) {
            case 0:
                getCallsGroupedByLocation()
                getOverallCalls(overallSelectedDateStart, overallSelectedDateEnd)
                getTotalCallsEachMonth(moment(overallSelectedDateStart).format('YYYY'))
                getOverallCallsInfo(overallSelectedDateStart, overallSelectedDateEnd)
                return
            case 1:
                return onSearchUsers()
            case 2:
                registeredMobileUsersData(clientSelectedDateStart, clientSelectedDateEnd)
                return onSearchClients()
        }
    };

    const handleOverallDateChange = async (date, type) => {
        if (type === 'start') {
            setOverallSelectedDateStart(date)
            getOverallCalls(date, overallSelectedDateEnd)
            getTotalCallsEachMonth(moment(date).format('YYYY'))
            getOverallCallsInfo(date, overallSelectedDateEnd)
        } else {
            setOverallSelectedDateEnd(date)
            getOverallCalls(overallSelectedDateStart, date)
            getOverallCallsInfo(overallSelectedDateStart, date)
        }
    };

    const handleUserDateChange = async (date, type) => {
        let data
        if (type === 'start') {
            setUserSelectedDateStart(date)
            data = await searchUsersData(1, rowsPerPage, searchUser, date, userSelectedDateEnd)

        } else {
            setUserSelectedDateEnd(date)
            data = await searchUsersData(1, rowsPerPage, searchUser, userSelectedDateStart, date)
        }
        setPaginationData(data)
        console.log(date)
    };

    const handleClientDateChange = async (date, type) => {
        let data
        if (type === 'start') {
            setClientSelectedDateStart(date)
            data = await searchClientsData(1, rowsPerPage, searchUser, date, clientSelectedDateEnd)
            await registeredMobileUsersData(date, clientSelectedDateEnd)

        } else {
            setClientSelectedDateEnd(date)
            data = await searchClientsData(1, rowsPerPage, searchUser, clientSelectedDateStart, date)
            await registeredMobileUsersData(clientSelectedDateStart, date)
        }
        setPaginationData(data)
        console.log(date)
    };

    useEffect(() => {
        getCallsGroupedByLocation()
        getOverallCalls(overallSelectedDateStart, overallSelectedDateEnd)
        getTotalCallsEachMonth(moment(overallSelectedDateStart).format('YYYY'))
        getOverallCallsInfo(overallSelectedDateStart, overallSelectedDateEnd)
    }, [])

    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Calls',
                backgroundColor: 'rgba(97,221,252,0.2)',
                borderColor: 'rgba(97,221,252,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(97,221,252,0.4)',
                hoverBorderColor: 'rgba(97,221,252,1)',
                data: totalCallsEachMonth
            }
        ]
    };

    const onSearchUsers = async () => {
        const data = await searchUsersData(1, rowsPerPage, searchUser, userSelectedDateStart, userSelectedDateEnd)
        setPaginationData(data)
    }

    const onSearchClients = async () => {
        const data = await searchClientsData(1, rowsPerPage, searchClient, clientSelectedDateStart, clientSelectedDateEnd)
        setPaginationData(data)
    }

    const setPaginationData = async (data) => {
        if (!data) return
        setPage(data.page)
        setRowsPerPage(data.limit)
        setCount(data.totalDocs)
    }

    const onSearchUsersKeyboard = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            onSearchUsers()
        }
    }
    const onSearchClientsKeyboard = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            onSearchClients()
        }
    }

    //table change page fetches the users
    const handleChangePageUsers = async (event, newPage) => {
        const data = await searchUsersData(newPage + 1, rowsPerPage, searchUser, userSelectedDateStart, userSelectedDateEnd);
        setPaginationData(data)
    };
    const handleChangePageClients = async (event, newPage) => {
        const data = await searchClientsData(newPage + 1, rowsPerPage, searchClient, clientSelectedDateStart, clientSelectedDateEnd);
        setPaginationData(data)
    };
    //table row change fetches the users as the parameters go
    const handleChangeRowsPerPageUsers = async event => {
        const data = await searchUsersData(1, +event.target.value, searchUser, userSelectedDateStart, userSelectedDateEnd);
        setPaginationData(data)
    };
    const handleChangeRowsPerPageClients = async event => {
        const data = await searchClientsData(1, +event.target.value, searchClient, clientSelectedDateStart, clientSelectedDateEnd);
        setPaginationData(data)
    };

    const csvHeadersUser = [
        { label: 'Operator', key: 'userId.fullName' },
        { label: 'Total calls', key: 'totalCalls' },
        { label: 'Total duration', key: 'formattedTotalDuration' },
        { label: `Calls :(${moment(userSelectedDateStart).format('MM/DD/YYYY')} - ${moment(userSelectedDateEnd).format('MM/DD/YYYY')})`, key: 'dayCalls' },
        { label: `Duration: (${moment(userSelectedDateStart).format('MM/DD/YYYY')} - ${moment(userSelectedDateEnd).format('MM/DD/YYYY')})`, key: 'formattedDurationInterval' },
        { label: `Calls: ${moment(userSelectedDateStart).format('MMMM')}`, key: 'monthCalls' },
        { label: 'Rating', key: 'rating' },
    ];

    const csvHeadersClient = [
        { label: 'Operator', key: 'clientId.fullName' },
        { label: 'Total calls', key: 'totalCalls' },
        { label: 'Total duration', key: 'formattedTotalDuration' },
        { label: `Calls: (${moment(clientSelectedDateStart).format('MM/DD/YYYY')} - ${moment(clientSelectedDateEnd).format('MM/DD/YYYY')})`, key: 'dayCalls' },
        { label: `Duration: (${moment(clientSelectedDateStart).format('MM/DD/YYYY')} - ${moment(clientSelectedDateEnd).format('MM/DD/YYYY')})`, key: 'formattedDurationInterval' },
        { label: `Calls: ${moment(clientSelectedDateStart).format('MMMM')}`, key: 'monthCalls' },
        // { label: 'Rating', key: 'rating' },
    ];

    const csvHeaderGroupedCalls = [
        { label: 'From', key: '_id' },
        { label: 'Count', key: 'count' },
    ];

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={classes.root}>
                <AppBar position="static">
                    <Tabs value={pageIndex} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Overall" {...a11yProps(0)} />
                        <Tab label="Call Center" {...a11yProps(1)} />
                        <Tab label="Mobile App" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={pageIndex} index={0}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container justifyContent="center" spacing={2} style={{ justifyContent: 'space-between' }} >
                                {cardData.map((data, i) => (
                                    <Grid key={i} item xs={6} md={2} sm={2}>
                                        <SquareCard data={data} type={'calls'} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1, marginTop: '50px' }} >
                            <Card>
                                <CardHeader title={
                                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography align='center' style={{ alignSelf: 'center' }}>Advanced Statistics</Typography>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select start date"
                                                value={overallSelectedDateStart}
                                                onChange={(date) => handleOverallDateChange(date, 'start')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select end date"
                                                value={overallSelectedDateEnd}
                                                onChange={(date) => handleOverallDateChange(date, 'end')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                        </Grid>
                                    </Grid>
                                } />
                                <Divider />
                                <CardContent>
                                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Grid item xs={12} md={5} sm={5} style={{ display: 'flex', }}>
                                            <div style={{ display: 'flex', width: '100%' }}>
                                                <Bar
                                                    data={data}
                                                    width={200}
                                                    height={300}
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={7} sm={7}>
                                            <GridListInfo moreInfoData={moreInfoData} />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                            </Card>
                            <Card style={{ marginTop: 20 }}>
                                <CardHeader title={<Grid container>
                                    <Grid xs={12} sm={2} md={2} item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Typography >Grouped Calls</Typography>
                                    </Grid>
                                    <Grid xs={12} sm={2} md={2} item>
                                        <CSVLink onClick={() => groupedCalls.length === 0 ? false : true} filename={`TappyGuide_location_group.csv`} data={groupedCalls} headers={csvHeaderGroupedCalls} separator={","} style={{ alignSelf: 'center' }}>
                                            <Button
                                                disabled={groupedCalls.length === 0}
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                startIcon={<DescriptionIcon />}
                                            >
                                                Download
                                            </Button>
                                        </CSVLink>
                                    </Grid>
                                </Grid>} />
                                <CardContent>
                                    {groupedCalls.map((call) => (
                                        <Typography>{call._id} - {call.count}</Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={pageIndex} index={1}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container justifyContent="center" spacing={2} style={{ justifyContent: 'space-between' }} >
                                {cardData.map((data, i) => (
                                    <Grid key={i} item xs={6} md={2} sm={2}>
                                        <SquareCard data={data} type={'calls'} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1, marginTop: '50px' }} >
                            <Card>
                                <CardHeader title={
                                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography align='center' style={{ alignSelf: 'center' }}>Call Center Agents</Typography>
                                        <CSVLink filename={`TappyGuide(${moment(userSelectedDateStart).format('MM/DD/YYYY')})Agents.csv`} data={users} headers={csvHeadersUser} separator={","} style={{ alignSelf: 'center' }}>
                                            <IconButton >
                                                <DescriptionIcon style={{ alignSelf: 'center' }} />
                                            </IconButton>
                                        </CSVLink>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select start date"
                                                value={userSelectedDateStart}
                                                onChange={(date) => handleUserDateChange(date, 'start')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select end date"
                                                value={userSelectedDateEnd}
                                                onChange={(date) => handleUserDateChange(date, 'end')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                            <SearchField
                                                value={searchUser}
                                                searchPlaceholder='Search users'
                                                onChange={(e) => setSearchUser(e.target.value)}
                                                onClick={(e) => onSearchUsers(e)}
                                                onKeyPress={(e) => onSearchUsersKeyboard(e)}
                                                width={250}
                                            />
                                        </Grid>
                                    </Grid>
                                } />
                                <Divider />
                                <CardContent>
                                    <Grid >
                                        <Paper elevation={0}>
                                            {<TableContainer >
                                                <Table>
                                                    <TableHead className={classes.tableHeader}>
                                                        <TableRow>
                                                            {columnsUsers.map(column => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    style={{ minWidth: column.minWidth, paddingRight: '55px', color: 'white' }}
                                                                    align={column.align}
                                                                >
                                                                    <div>
                                                                        {column.label}
                                                                    </div>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    {<TableBody>
                                                        {users.map(row => {
                                                            return (
                                                                <TableRow hover tabIndex={-1} key={row._id}>
                                                                    {columnsUsers.map(column => {
                                                                        const value = row[column.id];
                                                                        return (
                                                                            <TableCell align={column.align} key={column.id} >
                                                                                <div>
                                                                                    {column.format ? column.format(row) : value}
                                                                                </div>
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>}
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[25, 50, 100]}
                                                                count={count}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page - 1}
                                                                onPageChange={handleChangePageUsers}
                                                                onRowsPerPageChange={handleChangeRowsPerPageUsers}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>}
                                        </Paper>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={pageIndex} index={2}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} >
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container justifyContent="center" spacing={2} style={{ justifyContent: 'space-between' }} >
                                {cardData.map((data, i) => (
                                    <Grid key={i} item xs={6} md={2} sm={2}>
                                        <SquareCard data={data} type={'calls'} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Grid container justifyContent="center" spacing={2} style={{ justifyContent: 'space-between' }} >
                                {cardDataClients.map((data, i) => (
                                    <Grid key={i} item xs={6} md={2} sm={2}>
                                        <SquareCard data={data} type={'users'} />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item style={{ flexGrow: 1, marginTop: '50px' }} >
                            <Card>
                                <CardHeader title={
                                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography align='center' style={{ alignSelf: 'center' }}>Clients</Typography>
                                        <CSVLink filename={`TappyGuide(${moment(clientSelectedDateStart).format('MM/DD/YYYY')})Clients.csv`} data={clients} headers={csvHeadersClient} separator={","} style={{ alignSelf: 'center' }}>
                                            <IconButton >
                                                <DescriptionIcon style={{ alignSelf: 'center' }} />
                                            </IconButton>
                                        </CSVLink>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select start date"
                                                value={clientSelectedDateStart}
                                                onChange={(date) => handleClientDateChange(date, 'start')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                            <KeyboardDatePicker
                                                PopoverProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                label="Select end date"
                                                value={clientSelectedDateEnd}
                                                onChange={(date) => handleClientDateChange(date, 'end')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                style={{ marginRight: '20px' }}
                                                autoOk={true}
                                            />
                                            <SearchField
                                                value={searchClient}
                                                searchPlaceholder='Search clients'
                                                onChange={(e) => setSearchClient(e.target.value)}
                                                onClick={(e) => onSearchClients(e)}
                                                onKeyPress={(e) => onSearchClientsKeyboard(e)}
                                                width={250}
                                            />
                                        </Grid>
                                    </Grid>
                                } />
                                <Divider />
                                <CardContent>
                                    <Grid >
                                        <Paper elevation={0}>
                                            {<TableContainer >
                                                <Table>
                                                    <TableHead className={classes.tableHeader}>
                                                        <TableRow>
                                                            {columnsClients.map(column => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    style={{ minWidth: column.minWidth, paddingRight: '55px', color: 'white' }}
                                                                    align={column.align}
                                                                >
                                                                    <div>
                                                                        {column.label}
                                                                    </div>
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    {<TableBody>
                                                        {clients.map(row => {
                                                            return (
                                                                <TableRow hover tabIndex={-1} key={row._id}>
                                                                    {columnsClients.map(column => {
                                                                        const value = row[column.id];
                                                                        return (
                                                                            <TableCell align={column.align} key={column.id} >
                                                                                <div>
                                                                                    {column.format ? column.format(row) : value}
                                                                                </div>
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>}
                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                rowsPerPageOptions={[5, 10, 25]}
                                                                count={count}
                                                                rowsPerPage={rowsPerPage}
                                                                page={page - 1}
                                                                onPageChange={handleChangePageClients}
                                                                onRowsPerPageChange={handleChangeRowsPerPageClients}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                                                </Table>
                                            </TableContainer>}
                                        </Paper>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default Statistics