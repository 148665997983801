import { io } from "socket.io-client";
import { url } from '../components/utils/url'

const socket = io(
    url,
    {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        autoConnect: false
    }
);

export default socket;