import React, { useState, useContext, useEffect } from "react";

//material
import { Grid, TextField, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//context
import { Context as AppContext } from "../../context/AppContext";
import { Context as AuthContext } from "../../context/AuthContext";

//style
const useStyles = makeStyles(theme => ({
  button: {
    margin: "10px"
  },
  gridContainer: {
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column"
  },
  input: {
    marginTop: "20px"
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  }
}));

const ChangeNotificationHour = () => {
  const classes = useStyles();
  const [hour, setHour] = useState(0);
  const { getNotificationHour, changeNotificationHour } = useContext(AppContext);
  const { toggleSnackBar } = useContext(AuthContext);

  const onChangeHour = async () => {
    const newHour = await changeNotificationHour(hour);
    if (newHour) {
      toggleSnackBar(true, "Time updated successfully", "success");
      setHour(newHour);
      return true;
    } else {
      toggleSnackBar(true, "Failed! Select something different than 0", "error");
    }
  };

  const onGetHour = async () => {
    const newHour = await getNotificationHour();
    if (newHour) {
      setHour(newHour);
    }
  };

  useEffect(() => {
    onGetHour();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Typography>Change minutes for notifications</Typography>
      <Grid item xs={12} sm={6} md={6} className={classes.gridContainer}>
        <TextField
          size='small'
          required
          className={classes.input}
          variant='outlined'
          color='primary'
          type='number'
          label='Minutes'
          value={hour}
          onChange={e => setHour(e.target.value)}
        />
        <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Typography
            style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
            align='center'
          >{`Users will be notified with ${hour} minutes before an appointment.`}</Typography>
          <Button
            onClick={() => onChangeHour()}
            disabled={!hour}
            className={classes.button}
            variant='contained'
            color='primary'
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeNotificationHour;
