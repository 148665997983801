import React, { useContext, useEffect, useState } from "react";
//material
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CircularProgress,
  makeStyles,
  Toolbar,
  Grid,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TableContainer,
  TablePagination,
  Button,
  Divider
} from "@material-ui/core";
import moment from "moment";
//context
import { Context as AppContext } from "../../context/AppContext";
import NotificationsIcon from "@material-ui/icons/Notifications";

//style
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tableBar: {
    backgroundColor: theme.palette.primary.main,
    height: "135px"
  }
}));

const PushNotificationsHistory = () => {
  const {
    getSentNotifications,
    state: { notifications }
  } = useContext(AppContext);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    getNotificationsInit(1, limit);
  }, []);

  const getNotifications = async (page, limit) => {
    const notif = await getSentNotifications(page, limit);
    setPaginationData(notif);
  };

  const getNotificationsInit = async (page, limit) => {
    setNotificationsLoading(true);
    const notif = await getSentNotifications(page, limit);
    setPaginationData(notif);
    setNotificationsLoading(false);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setLimit(data.limit);
    setHasMore(data.hasNextPage);
  };

  const onLoadMore = async () => {
    if (hasMore) {
      await getNotifications(page + 1, limit);
    } else {
      return;
    }
  };

  const renderItem = item => {
    return (
      <Card elevation={0} style={{ borderRadius: 10 }}>
        <CardHeader
          title={
            <Grid>
              <Typography>Title: {item.title}</Typography>
              <Typography>Sent by: {item.sentBy?.fullName}</Typography>
            </Grid>
          }
          subheader={moment(item.createdAt).format("MMMM Do YYYY, h:mm A")}
        />
        <CardContent>
          <Typography variant='body2' color='textSecondary' component='p'>
            Message:
          </Typography>
          <Typography variant='body2' color='textPrimary' component='p'>
            {item.body}
          </Typography>
          <Divider />
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <Grid>
        <Toolbar className={classes.tableBar} elevation={3}>
          <Grid item sm={4} xs={12} md={4} style={{ display: "flex", flexDirection: "row" }}>
            <NotificationsIcon style={{ color: "white", width: 32, height: 32 }} />
            <Typography style={{ color: "white", fontSize: "20px", marginLeft: "20px" }}>
              Notification history
            </Typography>
          </Grid>
        </Toolbar>
      </Grid>
      <Container>
        <Grid item style={{ marginTop: "50px" }}>
          {!notificationsLoading ? (
            <Grid>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <div key={notification._id}>{renderItem(notification)}</div>
                ))
              ) : (
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Typography>No history</Typography>
                </Grid>
              )}
              {hasMore ? (
                <Card
                  key={0}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    borderRadius: 0
                  }}
                >
                  <Button onClick={() => onLoadMore()}>Load more</Button>
                </Card>
              ) : null}
            </Grid>
          ) : (
            <Card
              key={0}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                borderRadius: 0
              }}
            >
              <CircularProgress style={{ alignSelf: "center" }} size={20} />
            </Card>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PushNotificationsHistory;
