import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

//context
import { Context as AuthContext } from "../context/AuthContext";

//material
import { makeStyles } from "@material-ui/core";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  }
}));
const PrivateRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const { state } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        state.isAuthenticated ? (
          <div className={classes.appBar}>
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
