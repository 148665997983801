import React, { useContext, useState, useRef } from "react";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroller";

//material
import {
  Button,
  DialogActions,
  TextField,
  Grid,
  Typography,
  makeStyles,
  CircularProgress,
  Dialog,
  IconButton
} from "@material-ui/core";

//context
import { Context as CallContext } from "../../context/CallContext";

//icons
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: "white",
    borderRadius: 20,
    display: "flex"
  },
  userChatBubble: {
    padding: 10,
    marginLeft: 15,
    justifyContent: "space-between",
    display: "flex"
  },
  scrollBar: {
    borderTop: "1px solid rgba(187,187,187,0.5)",
    overFlowY: "hidden",
    height: "100%"
  }
}));

const CallComment = ({ room }) => {
  const classes = useStyles();
  const {
    state: { comments },
    addCallComment,
    getCallComments
  } = useContext(CallContext);
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollbars = useRef(null);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState(1);

  const handleClick = event => {
    setOpen(true);
    if (room) {
      onFetchCommentsInit(room, 1, limit);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    } else {
      setOpen(false);
    }
  };

  const onFetchComments = async (userId, page, limit) => {
    const comm = await getCallComments(userId, page, limit);
    setPaginationData(comm);
  };

  const onFetchCommentsInit = async (userId, page, limit) => {
    setIsLoading(true);
    const comm = await getCallComments(userId, page, limit);
    setPaginationData(comm);
    setIsLoading(false);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setLimit(data.limit);
    setCount(data.totalDocs);
    setHasMore(data.hasNextPage);
  };

  const onAddCallComment = async () => {
    setComment("");
    addCallComment(room, comment);
  };

  const onLoadMore = async () => {
    if (hasMore) {
      await onFetchComments(room, page + 1, limit);
    } else {
      return;
    }
  };

  const renderItem = (item, index) => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          margin: 15,
          justifyContent: "space-between"
        }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography style={{ fontSize: 10 }}>
            {item.userId ? item.userId.fullName : "Removed user"}
          </Typography>
          <Typography style={{ color: "grey", fontSize: 10 }}>
            {moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </Typography>
        </Grid>
        <Grid>
          <Grid className={classes.userChatBubble}>
            <Grid>
              <Typography style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
                {item.comment}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  if (!room) {
    return null;
  }

  return (
    <div style={{ alignSelf: "center" }}>
      <IconButton title='Comments' onClick={handleClick}>
        <QuestionAnswerIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"}>
        <Grid style={{ display: "flex", flexDirection: "column", width: 700 }}>
          <Grid container style={{ height: "30vh" }}>
            {!isLoading ? (
              comments.length !== 0 ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Scrollbars className={classes.scrollBar} ref={scrollbars}>
                    <InfiniteScroll
                      loadMore={() => onLoadMore()}
                      useWindow={false}
                      hasMore={hasMore}
                      initialLoad={false}
                      style={{ display: "flex", flexDirection: "column" }}
                      threshold={10}
                    >
                      {comments.map((comment, index) => (
                        <div key={comment._id}>{renderItem(comment, index)}</div>
                      ))}
                      {hasMore ? (
                        <Grid
                          key={0}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            borderRadius: 0,
                            height: 25
                          }}
                        >
                          <CircularProgress style={{ alignSelf: "center" }} size={20} />
                        </Grid>
                      ) : null}
                    </InfiniteScroll>
                  </Scrollbars>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
                >
                  <Typography style={{ alignSelf: "center" }}>No comments added yet!</Typography>
                </Grid>
              )
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
              >
                <CircularProgress style={{ alignSelf: "center" }} />
              </Grid>
            )}
          </Grid>
          <TextField
            label={"Add your comment"}
            variant='outlined'
            value={comment}
            multiline
            rows={4}
            onChange={e => setComment(e.target.value)}
            style={{ margin: "10px", backgroundColor: "white" }}
          />
          <DialogActions>
            <Button variant='contained' onClick={() => handleClose()}>
              Close
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={!comment}
              onClick={() => onAddCallComment()}
            >
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </div>
  );
};

export default CallComment;
