import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

//material
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TableFooter,
  Switch,
  Typography,
  makeStyles,
  Button
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";

//context
import { Context as UserContext } from "../../context/UserContext";

//icons
import EditIcon from "@material-ui/icons/Edit";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import PersonIcon from "@material-ui/icons/Person";

//components
import PageLoading from "../../components/PageLoading";
import UpdateUserFormModal from "../../components/admin/UpdateUserFormModal";
import AddUserForm from "../../components/admin/AddUserForm";
import DeleteAlertButton from "../../components/admin/DeleteAlertButton";
import SearchHeader from "../../components/common/SearchHeader";

//style
const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "20px"
  },
  paperContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  }
}));

const CallCenterUsers = ({ history }) => {
  const {
    state,
    fetchCallCenterUsers,
    updateCallCenterUser,
    addCallCenterUser,
    clearErrors,
    deleteCallCenterUser,
    changeUserAvailability,
    disableUser
  } = useContext(UserContext);

  const classes = useStyles();

  const [openUserFormDialog, setOpenUserFormDialog] = useState(false);
  const [openAddUserFormDialog, setOpenAddUserFormDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    _id: "",
    email: ""
  });
  const [availableUsers, setAvailableUser] = useState(0);

  const onChangeUserAvailability = async id => {
    const isAvailable = await changeUserAvailability(id);
    if (isAvailable) {
      setAvailableUser(v => v + 1);
    } else {
      setAvailableUser(v => v - 1);
    }
  };

  const columns = [
    {
      id: "fullName",
      label: "Full Name",
      align: "left",
      format: value => (
        <div>
          <Typography>
            {value.role === "Admin" ? `${value.fullName}(Admin)` : `${value.fullName}`}
          </Typography>
        </div>
      )
    },
    {
      id: "updatedAt",
      label: "Active",
      align: "left",
      format: value => (
        <div>
          <Typography>{moment(value.updatedAt).fromNow()}</Typography>
        </div>
      )
    },
    { id: "email", label: "Email", align: "left" },
    {
      id: "available",
      label: `Available(${availableUsers})`,
      align: "center",
      format: value => (
        <div
          style={{
            paddingRight: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Switch
            checked={value.available}
            onChange={() => onChangeUserAvailability(value._id)}
            color='secondary'
          />
        </div>
      )
    },
    {
      id: "edit",
      label: "Edit",
      align: "right",
      format: value => (
        <div
          style={{
            paddingRight: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          <IconButton
            size='small'
            onClick={() => {
              openUserDialog(value);
            }}
          >
            <EditIcon />
          </IconButton>
          <DeleteAlertButton
            title={"Delete agent"}
            onDelete={_id => onDeleteUser(_id)}
            _id={value._id}
            fullName={value.fullName}
          />
          <IconButton
            size='small'
            onClick={() => history.push("/admin/admin-agent", { userId: value._id })}
          >
            <PersonIcon />
          </IconButton>
        </div>
      )
    },
    {
      id: "disabled",
      label: "Disabled",
      align: "center",
      format: value => (
        <div
          style={{
            paddingRight: "40px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Switch
            checked={value.disabled}
            onChange={() => disableUser(value._id)}
            color='secondary'
          />
        </div>
      )
    }
  ];

  const onDeleteUser = _id => {
    deleteCallCenterUser(_id);
  };

  const openUserDialog = value => {
    setEditUser({
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      _id: value._id
    });
    setOpenUserFormDialog(true);
  };
  const closeUserDialog = () => {
    setEditUser({
      firstName: "",
      lastName: "",
      _id: "",
      email: ""
    });
    clearErrors();
    setOpenUserFormDialog(false);
  };

  const closeAddUserDialog = () => {
    setOpenAddUserFormDialog(false);
  };

  const registerUser = async ({ email, firstName, lastName, password, verifyPassword }) => {
    const isOk = await addCallCenterUser(email, firstName, lastName, password, verifyPassword);
    if (isOk) {
      fetchAllUsers();
      return true;
    } else {
      return false;
    }
  };

  const updateUser = async (email, firstName, lastName, _id) => {
    const isOk = await updateCallCenterUser(email, firstName, lastName, _id);

    if (isOk) {
      setOpenUserFormDialog(false);
    }
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    const data = await fetchCallCenterUsers(newPage + 1, rowsPerPage, search);
    setPaginationData(data);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    const data = await fetchCallCenterUsers(1, +event.target.value, search);
    setPaginationData(data);
  };

  //used to search on hitting enter
  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchAllUsers();
    }
  };

  const fetchAllUsers = async () => {
    const data = await fetchCallCenterUsers(1, rowsPerPage, search);
    setPaginationData(data);
  };

  const setPaginationData = async data => {
    let availableUsers = 0;
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
    data.docs.map(doc => {
      if (doc.available) {
        availableUsers++;
      }
    });
    setAvailableUser(availableUsers);
  };

  useEffect(() => {
    fetchAllUsers();
    return () => {
      clearErrors();
    };
  }, []);

  return (
    <>
      <SearchHeader
        onClick={() => fetchAllUsers()}
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        onKeyPress={e => handleKeyDown(e)}
        title={"Agents"}
        icon={<GroupOutlinedIcon style={{ color: "white", width: 32, height: 32 }} />}
        searchPlaceholder={"Search agents"}
        count={count}
      >
        <Button variant='outlined' color='secondary' onClick={() => setOpenAddUserFormDialog(true)}>
          Add user
        </Button>
      </SearchHeader>
      <Grid style={{ margin: "30px" }}>
        <Grid className={classes.gridContainer}>
          <Paper className={classes.paperContainer} elevation={0}>
            {state.isLoading ? (
              <PageLoading page={false} />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                          align={column.align}
                        >
                          <div>{column.label}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {state.users.map(row => {
                        return (
                          <TableRow hover tabIndex={-1} key={row._id}>
                            {columns.map(column => {
                              const value = row[column.id];
                              return (
                                <TableCell align={column.align} key={column.id}>
                                  <div>{column.format ? column.format(row) : value}</div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  }
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
        {openUserFormDialog ? (
          <UpdateUserFormModal
            error={state.updateUserError}
            user={editUser}
            openUserFormDialog={openUserFormDialog}
            closeUserDialog={closeUserDialog}
            updateUser={updateUser}
          />
        ) : null}
        <Grid className={classes.gridContainer}>
          <Grid container spacing={2} style={{ display: "flex", flexDirection: "row" }}>
            <Grid item xs={12} sm={4} md={4}>
              {openAddUserFormDialog ? (
                <AddUserForm
                  error={state.addUserError}
                  registerUser={registerUser}
                  closeAddUserDialog={closeAddUserDialog}
                  openAddUserFormDialog={openAddUserFormDialog}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6} md={6}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(CallCenterUsers);
