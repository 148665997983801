import React, { useEffect, useRef } from "react";

//icons
import CallIcon from "@material-ui/icons/Call";
import CloseIcon from "@material-ui/icons/Close";
//material
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  Grid,
  makeStyles
} from "@material-ui/core";

//sounds
import ringing from "../../sounds/ringing.mp3";
import ringback from "../../sounds/ringback.mp3";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  dialogContainer: {
    borderRadius: "15px"
  }
}));

const CallModal = ({
  open,
  handleRefuse,
  handleAnswer,
  callerName,
  caller,
  clientName,
  isMic,
  cancelCooldown,
  isChat
}) => {
  const classes = useStyles();
  let timeOut = useRef();

  useEffect(() => {
    const audio = caller ? new Audio(ringback) : new Audio(ringing);
    audio.loop = true;

    const promise = audio.play();

    if (promise !== undefined) {
      promise.then(() => {}).catch(error => console.log(error));
    }

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [caller]);

  const onHandleRefuse = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    } else {
      handleRefuse();
    }
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={onHandleRefuse}
        classes={{
          paper: classes.dialogContainer
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {!caller
            ? isChat
              ? `${callerName} wants to chat...`
              : `${callerName} is calling...`
            : `Calling ${clientName}`}
        </DialogTitle>
        <DialogActions>
          <IconButton
            onClick={() => (caller ? handleRefuse("") : handleRefuse(""))}
            disabled={cancelCooldown}
          >
            <Grid
              style={cancelCooldown ? { backgroundColor: "grey" } : { backgroundColor: "red" }}
              className={classes.buttonContainer}
            >
              <CloseIcon style={{ color: "white" }} />
            </Grid>
          </IconButton>

          {!caller ? (
            <IconButton onClick={handleAnswer} disabled={!isMic}>
              <Grid
                style={isMic ? { backgroundColor: "green" } : { backgroundColor: "grey" }}
                className={classes.buttonContainer}
              >
                <CallIcon style={{ color: "white" }} />
              </Grid>
            </IconButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CallModal;
