import React from "react";
import moment from "moment";

//material
import { ListItemText, IconButton, Grid, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//icons
import CallIcon from "@material-ui/icons/Call";

//components
import CallComment from "./CallComment";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
}));

const CallView = ({ call, onClick, isMic, available, roomId }) => {
  const classes = useStyles();

  const listItem = description => {
    switch (description) {
      case "Calling":
        return (
          <>
            <ListItemText primary={description} style={{ color: "orange", alignSelf: "center" }} />
            <CallComment room={roomId} />
          </>
        );
      case "In call":
        return (
          <>
            <ListItemText primary={description} style={{ color: "green", alignSelf: "center" }} />
            <CallComment room={roomId} />
          </>
        );
      case "Missed":
        return (
          <>
            <ListItemText primary={description} style={{ color: "red", alignSelf: "center" }} />
            <CallComment room={roomId} />
            <Grid className={classes.iconContainer}>
              <IconButton onClick={onClick} disabled={!isMic || !available}>
                <Grid
                  style={{ backgroundColor: isMic && available ? "green" : "grey" }}
                  className={classes.buttonContainer}
                >
                  <CallIcon style={{ color: "white" }} />
                </Grid>
              </IconButton>
            </Grid>
          </>
        );
      case "Not Answered":
        return (
          <>
            <ListItemText primary={description} style={{ color: "red", alignSelf: "center" }} />
            <CallComment room={roomId} />
            <Grid className={classes.iconContainer}>
              <IconButton onClick={onClick} disabled={!isMic || !available}>
                <Grid
                  style={{ backgroundColor: isMic && available ? "green" : "grey" }}
                  className={classes.buttonContainer}
                >
                  <CallIcon style={{ color: "white" }} />
                </Grid>
              </IconButton>
            </Grid>
          </>
        );
      case "Ended":
        return (
          <>
            <ListItemText primary={description} style={{ alignSelf: "center" }} />
            <CallComment room={roomId} />
            <Grid className={classes.iconContainer}>
              <IconButton onClick={onClick} disabled={!isMic || !available}>
                <Grid
                  style={{ backgroundColor: isMic && available ? "green" : "grey" }}
                  className={classes.buttonContainer}
                >
                  <CallIcon style={{ color: "white" }} />
                </Grid>
              </IconButton>
            </Grid>
          </>
        );
      default:
        return (
          <>
            <ListItemText
              primary={description ? description : "Ended"}
              style={{ alignSelf: "center" }}
            />
            <CallComment room={roomId} />
            <Grid className={classes.iconContainer}>
              <IconButton onClick={onClick} disabled={!isMic || !available}>
                <Grid
                  style={{ backgroundColor: isMic && available ? "green" : "grey" }}
                  className={classes.buttonContainer}
                >
                  <CallIcon style={{ color: "white" }} />
                </Grid>
              </IconButton>
            </Grid>
          </>
        );
    }
  };

  return (
    <Grid
      container
      style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sm={6}
        style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
      >
        <Typography style={{ overflow: "hidden", height: "24px" }}>{call.name}</Typography>
        <Typography style={{ fontSize: "12px", color: "grey" }}>
          {moment(call.updatedAt).fromNow()}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sm={6}
        style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}
      >
        {listItem(call.description)}
      </Grid>
      <Divider />
    </Grid>
  );
};

export default CallView;
