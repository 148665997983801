import createDataContext from './createDataContext'
import axios from 'axios';

const clientReducer = (state, action) => {
    switch (action.type) {
        case 'fetch_clients':
            return { ...state, clients: action.payload.docs }
        case 'loading':
            return { ...state, isLoading: action.payload }
        case 'button_loading':
            return { ...state, buttonLoading: action.payload }
        case 'update_client':
            const updatedIndex = state.clients.findIndex((e) => e._id === action.payload._id)
            state.clients[updatedIndex] = action.payload
            return { ...state }
        case 'delete_client':
            const deletedIndex = state.clients.findIndex((e) => e._id === action.payload)
            state.clients.splice(deletedIndex, 1);
            return { ...state }
        case 'update-client-error':
            return { ...state, updateClientError: action.payload }
        case 'add-client-error':
            return { ...state, addClientError: action.payload }
        default:
            return state
    }
}

//ADMIN
const fetchClients = (dispatch) => {
    return async (page, limit, search) => {
        dispatch({ type: 'loading', payload: true })
        try {
            const response = await axios.get(`/api/get-clients-mb?page=${page}&limit=${limit}&search=${search}`);
            console.log(response)
            dispatch({ type: 'fetch_clients', payload: response.data })

            dispatch({ type: 'loading', payload: false })

            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const updateClient = (dispatch) => {
    return async ({ address, birthDate, firstName, lastName, phone, _id, email }) => {
        try {
            const response = await axios.put(`/api/update-client-mb`, { address, birthDate, firstName, lastName, phone, _id, email });
            console.log(response)
            dispatch({ type: 'update_client', payload: response.data })
            return true
        } catch (e) {
            if (e.response.data.error.code === 11000) {
                dispatch({ type: 'update-client-error', payload: 'Email already exists' })
                return false
            }
        }
    }
}

const deleteClient = (dispatch) => {
    return async (_id) => {
        try {
            const response = await axios.delete(`/api/delete-client-mb?userId=${_id}`);
            console.log(response)
            dispatch({ type: 'delete_client', payload: _id })
            return true
        } catch (e) {
            console.log(e.response)
            dispatch({ type: 'add-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const clearClientErrors = (dispatch) => {
    return async () => {
        dispatch({ type: 'add-client-error', payload: '' })
        dispatch({ type: 'update-client-error', payload: '' })
    }
}

export const { Provider, Context } = createDataContext(clientReducer, { fetchClients, updateClient, clearClientErrors, deleteClient }, 
    { clients: [], addClientError: '', updateClientError: '', isLoading: false })