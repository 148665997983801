import React, { useState } from "react";

//material
import { Paper, Grid, TextField, Container, Typography, Button, Dialog } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//style
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    margin: "10px"
  },
  paperContainer: {
    borderRadius: "15px"
  },
  input: {
    marginTop: "20px"
  },
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  dialogContainer: {
    borderRadius: "15px",
    width: "40%"
  }
}));

const AddUserForm = ({ registerUser, error, openAddUserFormDialog, closeAddUserDialog }) => {
  const classes = useStyles();
  const [addUser, setAddUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    verifyPassword: ""
  });

  const onReset = () => {
    setAddUser({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      verifyPassword: ""
    });
  };

  const onAddUser = async () => {
    const isOk = await registerUser(addUser);
    if (isOk) {
      onReset();
      closeAddUserDialog();
    }
  };

  return (
    <div>
      <Dialog
        open={openAddUserFormDialog}
        maxWidth={"xs"}
        onClose={closeAddUserDialog}
        aria-labelledby='form-dialog-title'
        classes={{
          paper: classes.dialogContainer
        }}
      >
        <Paper elevation={1} className={classes.paperContainer}>
          <Grid className={classes.headerStyle}>
            <Typography style={{ padding: "20px", color: "white" }}>Add agent</Typography>
          </Grid>
          <Container className={classes.container}>
            <TextField
              className={classes.input}
              variant='outlined'
              color='primary'
              label='Email Address'
              type='email'
              fullWidth
              required
              value={addUser.email}
              onChange={e => setAddUser({ ...addUser, email: e.target.value })}
            />
            <TextField
              className={classes.input}
              variant='outlined'
              required
              color='primary'
              label='First Name'
              type='text'
              fullWidth
              value={addUser.firstName}
              onChange={e => setAddUser({ ...addUser, firstName: e.target.value })}
            />
            <TextField
              className={classes.input}
              variant='outlined'
              required
              color='primary'
              label='Last Name'
              type='text'
              fullWidth
              value={addUser.lastName}
              onChange={e => setAddUser({ ...addUser, lastName: e.target.value })}
            />
            <TextField
              className={classes.input}
              variant='outlined'
              required
              color='primary'
              label='Password'
              type='password'
              fullWidth
              value={addUser.password}
              onChange={e => setAddUser({ ...addUser, password: e.target.value })}
            />
            <TextField
              className={classes.input}
              variant='outlined'
              required
              color='primary'
              label='Verify Password'
              type='password'
              fullWidth
              value={addUser.verifyPassword}
              onChange={e => setAddUser({ ...addUser, verifyPassword: e.target.value })}
            />
            {error ? (
              <Typography align='center' style={{ color: "red" }}>
                {error}
              </Typography>
            ) : null}
            <Grid style={{ justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
              <Button onClick={() => onReset()} className={classes.button} variant='contained'>
                Reset
              </Button>
              <Button
                color='primary'
                onClick={() => onAddUser()}
                disabled={
                  !addUser.email ||
                  !addUser.firstName ||
                  !addUser.lastName ||
                  !addUser.password ||
                  !addUser.verifyPassword
                }
                className={classes.button}
                variant='contained'
              >
                Add
              </Button>
            </Grid>
          </Container>
        </Paper>
      </Dialog>
    </div>
  );
};

export default AddUserForm;
