import createDataContext from './createDataContext'
import axios from 'axios';

const appReducer = (state, action) => {
    switch (action.type) {
        case 'get_tickets':
            return { ...state, tickets: action.payload }
        case 'get_tickets_admin':
            if (action.payload.page === 1) {
                return { ...state, tickets: action.payload.docs }
            } else {
                return { ...state, tickets: [...state.tickets, ...action.payload.docs] }
            }
        case 'get_ticket_comments':
            return { ...state, comments: action.payload }
        case 'replace_ticket':
            const ticketIndexToUpdate = state.tickets.findIndex(e => e._id === action.payload._id)
            if (ticketIndexToUpdate === -1) {
                return { ...state }
            }
            let ticketsUpdated = [...state.tickets]
            ticketsUpdated[ticketIndexToUpdate] = action.payload
            console.log(action.payload, 'update ticket')
            return { ...state, tickets: ticketsUpdated }
        case 'delete_one_ticket':
            const indexToDelete = state.tickets.findIndex(e => e._id === action.payload._id)
            if (indexToDelete === -1) {
                return { ...state }
            }

            let ticketsAfterDelete = [...state.tickets]
            ticketsAfterDelete.splice(indexToDelete, 1);
            console.log(action.payload, 'update ticket')
            return { ...state, tickets: ticketsAfterDelete }
        case 'get_idle_minutes':
            return { ...state, idleMinutes: action.payload }
        case 'get_notifications':
            if (action.payload.page === 1) {
                return { ...state, notifications: action.payload.docs }
            } else {
                return { ...state, notifications: [...state.notifications, ...action.payload.docs] }
            }
        default:
            return state
    }
}

const changeNotificationHour = (dispatch) => {
    return async (hour) => {
        try {
            const response = await axios.post(`/api/change-notification-hour`, { hour });
            console.log(response.data)
            return response.data.value

        } catch (e) {
            console.log(e.response)
        }
    }
}

//push notifications admin
const sendNotificationsToAllUsers = (dispatch) => {
    return async (title, message) => {
        try {
            const response = await axios.post(`/api/custom-notification`, { title, message });
            console.log(response.data)
            return response

        } catch (e) {
            return e.response
        }
    }
}

const getSentNotifications = (dispatch) => {
    return async (page, limit) => {
        try {
            const response = await axios.get(`/api/get-notifications?page=${page}&limit=${limit}`);
            console.log(response.data,'NOTIFICATIONS')
            dispatch({ type: 'get_notifications', payload: response.data })
            return response

        } catch (e) {
            return e.response
        }
    }
}

const changeIdleTimer = (dispatch) => {
    return async (minutes) => {
        try {
            const response = await axios.post(`/api/change-idle-timer`, { minutes });
            dispatch({ type: 'get_idle_minutes', payload: minutes })
            console.log(response.data)
            return response.data.value

        } catch (e) {
            console.log(e.response)
        }
    }
}

const getNotificationHour = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/get-notification-hour`);
            console.log(response, 'not hour')
            return response.data.value

        } catch (e) {
            console.log(e.response)
        }
    }
}

const getIdleTimer = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/get-idle-timer`);
            console.log(response, 'not minutes')
            dispatch({ type: 'get_idle_minutes', payload: response.data.value })
            return response.data.value

        } catch (e) {
            console.log(e.response)
        }
    }
}

//users
const addTicket = (dispatch) => {
    return async (title, description, type) => {
        try {
            const response = await axios.post(`/api/add-ticket`, { title, description, type });
            console.log(response, 'addTicket')
            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const userGetTickets = (dispatch) => {
    return async () => {
        const response = await axios.get(`/api/get-user-tickets`);
        dispatch({ type: 'get_tickets', payload: response.data })
        console.log(response, 'getTicket')
        return response.data
    }
}

const getOneTicket = (dispatch) => {
    return async (ticketId) => {
        const response = await axios.get(`/api/get-one-ticket?ticketId=${ticketId}`);
        console.log(response, 'get ticket')
        return response.data
    }
}

const addTicketComment = (dispatch) => {
    return async (ticketId, comment) => {
        const response = await axios.post(`/api/add-ticket-comment`, { comment, ticketId });
        console.log(response, 'get ticket')
        return response.data
    }
}
const getTicketComments = (dispatch) => {
    return async (ticketId) => {
        const response = await axios.get(`/api/get-ticket-comments?ticketId=${ticketId}`);
        console.log(response, 'get ticket')
        dispatch({ type: 'get_ticket_comments', payload: response.data })
        return response.data
    }
}

const deleteTicket = (dispatch) => {
    return async (ticketId) => {
        const response = await axios.delete(`/api/delete-one-ticket?ticketId=${ticketId}`);
        console.log(response, 'get ticket')
        dispatch({ type: 'delete_one_ticket', payload: response.data })
        return response.data
    }
}

//admin

const adminGetTickets = (dispatch) => {
    return async (page, limit, type) => {
        const response = await axios.get(`/api/get-admin-tickets?page=${page}&limit=${limit}&type=${type}`);
        dispatch({ type: 'get_tickets_admin', payload: response.data })
        console.log(response, 'getTicket')
        return response.data
    }
}

const changeTicketStatus = (dispatch) => {
    return async (ticketId, status) => {
        const response = await axios.post(`/api/change-ticket-status`, { ticketId, status });
        dispatch({ type: 'replace_ticket', payload: response.data })
        console.log(response, 'getTicket')
        return response.data
    }
}

export const { Provider, Context } = createDataContext(appReducer, { sendNotificationsToAllUsers, getSentNotifications, changeIdleTimer, getIdleTimer, deleteTicket, changeTicketStatus, adminGetTickets, getTicketComments, addTicketComment, getOneTicket, changeNotificationHour, userGetTickets, getNotificationHour, addTicket },
    { tickets: [], comments: [], idleMinutes: 30, notifications: [] })


