import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

//context
import { Context as AuthContext } from "../context/AuthContext";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        state.isAuthenticated && state.isAdmin ? <Component {...props} /> : <Redirect to={"/"} />
      }
    />
  );
};

export default AdminRoute;
