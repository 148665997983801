import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

//material
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableFooter,
  TableContainer,
  TablePagination,
  makeStyles
} from "@material-ui/core";

//context
import { Context as EventContext } from "../../context/EventContext";

//components
import PageLoading from "../../components/PageLoading";
import TableRowCollapseEvent from "../../components/common/TableRowCollapseEvent";
import SquareCard from "../../components/common/squareCard";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "20px"
  },
  paperContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)",
    marginTop: "2%"
  }
}));

const EventsAdmin = () => {
  const classes = useStyles();

  const {
    state: { eventsAdmin },
    getEventsAdmin
  } = useContext(EventContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [totalDocs, setTotalDocs] = useState(0);
  const [honored, setHonored] = useState(0);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    const data = await getEventsAdmin(1, rowsPerPage);
    setTotalDocs(data.totalDocs);
    let counter = 0;
    data.docs.map(doc => {
      if (doc.done) {
        counter++;
      }
    });
    setHonored(counter);
    setPaginationData(data);
    setLoaded(true);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    const data = await getEventsAdmin(newPage + 1, rowsPerPage);
    setPaginationData(data);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    const data = await getEventsAdmin(1, +event.target.value);
    setPaginationData(data);
  };

  const columns = [
    { id: "title", label: "Title", align: "left" },
    { id: "destination", label: "Destination", align: "left" },
    {
      id: "Time",
      label: "Time",
      align: "left",
      format: value => (
        <div>
          <Typography>{`${moment(value.start).format("MM-DD-YYYY : hh:mm A")} - ${moment(
            value.end
          ).format("hh:mm A")}`}</Typography>
        </div>
      )
    },
    {
      id: "accepted",
      label: "Accepted",
      align: "left",
      format: value => (
        <div>
          <Typography> {value.accepted.length}</Typography>
        </div>
      )
    },
    {
      id: "seen",
      label: "Seen",
      align: "left",
      format: value => (
        <div>
          <Typography>{value.seen.length} </Typography>
        </div>
      )
    },
    {
      id: "done",
      label: "Honored",
      align: "left",
      format: value => (
        <div>
          <Typography style={{ color: value.done ? "green" : "red" }}>
            {value.done ? "Yes" : "No"}{" "}
          </Typography>
        </div>
      )
    }
  ];

  return (
    <>
      <Grid style={{ margin: "30px" }}>
        <Grid className={classes.gridContainer}>
          <Grid
            container
            justifyContent='center'
            spacing={2}
            style={{ justifyContent: "space-between" }}
          >
            <Grid item xs={6} md={6} sm={6}>
              <SquareCard data={{ value: totalDocs, title: "Total appointments:" }} />
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <SquareCard data={{ value: honored, title: "Total honored:" }} />
            </Grid>
          </Grid>
          <Paper className={classes.paperContainer} elevation={0}>
            {loaded ? (
              eventsAdmin.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                            align={column.align}
                          >
                            <div>{column.label}</div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {
                      <TableBody>
                        {eventsAdmin.map(row => (
                          <TableRowCollapseEvent row={row} columns={columns} key={row._id} />
                        ))}
                      </TableBody>
                    }
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[25, 50, 100]}
                          count={count}
                          rowsPerPage={rowsPerPage}
                          page={page - 1}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              ) : (
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignSelf: "center"
                  }}
                >
                  <Typography style={{ margin: "50px" }}>No records found</Typography>
                </Grid>
              )
            ) : (
              <PageLoading page={false} />
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default EventsAdmin;
