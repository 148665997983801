import React, { useState } from "react";
import "../../components/VideoPlayer.css";

//material
import {
  TableCell,
  TableRow,
  IconButton,
  Grid,
  Collapse,
  Box,
  Typography
} from "@material-ui/core";

//icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const TableRowCollapseHistory = ({ row, columns }) => {
  const [open, setOpen] = useState(false);

  const eventInfo = () => {
    const acceptedResult = row.acceptedHistory.reduce(
      (json, val) => ({ ...json, [val.email]: (json[val.email] | 0) + 1 }),
      {}
    );
    const declinedResult = row.declinedHistory.reduce(
      (json, val) => ({ ...json, [val.email]: (json[val.email] | 0) + 1 }),
      {}
    );

    var acceptedArr = Object.keys(acceptedResult).map(function (key) {
      return [key, acceptedResult[key]];
    });
    var declinedArr = Object.keys(declinedResult).map(function (key) {
      return [key, declinedResult[key]];
    });

    return (
      <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginRight: "5px" }}>Accepted:</Typography>
          {acceptedArr.length > 0 ? (
            <div>
              {acceptedArr.map((acc, index) => (
                <Typography key={index}>{`${acc[0]} : ${acc[1]}`}</Typography>
              ))}
            </div>
          ) : (
            <div>
              <Typography> 0</Typography>
            </div>
          )}
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginRight: "5px" }}>Declined:</Typography>
          {declinedArr.length > 0 ? (
            <div>
              {declinedArr.map((acc, index) => (
                <Typography key={index}>{` ${acc[0]} : ${acc[1]}`}</Typography>
              ))}
            </div>
          ) : (
            <div>
              <Typography> 0</Typography>
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell align={"left"} key='compress'>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(column => {
          const value = row[column.id];
          return (
            <TableCell align={column.align} key={column.id}>
              <div>{column.format ? column.format(row) : value}</div>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={4}>
              <Grid style={{ width: "100%" }}>{eventInfo()}</Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowCollapseHistory;
