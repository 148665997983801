import React, { useEffect, useMemo, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { Grid, Button } from "@material-ui/core";
import { PinDrop, MyLocation } from "@material-ui/icons";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import RouteParameters from "@arcgis/core/rest/support/RouteParameters";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import * as route from "@arcgis/core/rest/route.js";

import SearchBoxGoogle from "../components/common/SearchBoxGoogle";

import Widgets from "../components/common/Widgets";
import Marker from "../components/common/Marker";
import { PopUp } from "../components/common/PopUp";

import { useArcgisMap } from "../components/utils/hooks/useArcgisMap";
import { useQuoteCtx } from "../components/utils/hooks/useQuoteCtx";

const GoogleMapCallerPin = () => {
  const K_WIDTH = 40;
  const K_HEIGHT = 40;

  const greatPlaceStyle = {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2
  };
  return (
    <div style={greatPlaceStyle}>
      <PersonPinCircleIcon style={{ color: "red", width: "30px", height: "30px" }} />
    </div>
  );
};

const ROUTE_URL =
  "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World";
const LINE_STYLE = {
  type: "simple-line",
  color: [5, 150, 255],
  width: 3
};

const compareCurrentDate = date => {
  if (!date) return false;

  const dateObj = new Date(date);
  const currentDate = new Date();

  return currentDate > dateObj;
};

const Map = ({ callerLat, callerLng, currentMap, followCaller, timesOfFormats }) => {
  const {
    state: { quotes, hasShownQuotesOnMap, bookedQuote },
    resetQuotes,
    resetBookedQuote,
    getQuotes
  } = useQuoteCtx();

  const [fullLocationData, setFullLocationData] = useState();
  const [anchorX, setAnchorX] = useState(null);
  const [anchorY, setAnchorY] = useState(null);

  const [innerCurrentMap, setInnerCurrentMap] = useState(currentMap || "google");

  const mapRef = useRef();
  const gmapsRef = useRef();

  const defaultCenter = useRef({
    lat: callerLat || 39.113014,
    lng: callerLng || -105.358887
  });

  const [mapsapi, setMapsapi] = useState({
    apiReady: false,
    map: null,
    maps: null
  });

  const {
    arcgisMap: { view, apiReady }
  } = useArcgisMap(mapRef, {
    callerLat,
    lat: defaultCenter.current.lat,
    lng: defaultCenter.current.lng
  });

  const routeLayer = useMemo(() => {
    const layer = new GraphicsLayer();
    view && view.map.add(layer);

    return layer;
  }, [view]);

  const directionsRenderer = useMemo(() => {
    if (mapsapi.maps && mapsapi.map)
      return new mapsapi.maps.DirectionsRenderer({
        preserveViewport: true,
        suppressMarkers: true
      });
  }, [mapsapi.maps, mapsapi.map]);

  const GoogleMapsMarker = ({ quote, hasFreePrice }) => (
    <PinDrop
      onClick={e => {
        setAnchorX(e.clientX);
        setAnchorY(e.clientY);
        setFullLocationData(quote);
      }}
      style={{ zIndex: 3234, color: hasFreePrice ? "green" : "blue", fontSize: 22 }}
    />
  );

  const createMapOptions = maps => {
    return {
      styles: [{ stylers: [{ visibility: "on" }] }],
      fullscreenControl: false,
      zoomControlOptions: {
        position: maps.ControlPosition.TOP_LEFT
      },
      draggable: !fullLocationData,
      scrollwheel: !fullLocationData
    };
  };

  const handleGoogleDirectionsRender = reserved => {
    if (!callerLat || !callerLng) return;
    if (!reserved) return;
    if (!directionsRenderer) return;
    if (!mapsapi.map || !mapsapi.maps) return;

    const [parkingLat, parkingLng] = reserved?._embedded["pw:location"].entrances[0].coordinates;

    if (!directionsRenderer.getMap()) directionsRenderer.setMap(mapsapi.map);

    const directionsService = new mapsapi.maps.DirectionsService();

    const origin = { lat: callerLat, lng: callerLng };
    const destination = { lat: parkingLat, lng: parkingLng };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING"
      },
      (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          console.error(`Directions request failed due to ${status}`);
        }
      }
    );
  };

  function centerArcgisMap(latitude, longitude) {
    view.goTo({
      center: [longitude, latitude],
      zoom: 17
    });
  }

  function centerGoogleMapToLocation(latitude, longitude) {
    if (!mapsapi.map) return;
    mapsapi.map.panTo({ lat: latitude, lng: longitude });
    mapsapi.map.setZoom(17);
  }

  useEffect(() => {
    if (compareCurrentDate(bookedQuote?.end_time)) resetBookedQuote();

    if (!callerLat || !callerLng) return;
    if (followCaller) {
      currentMap === "google"
        ? centerGoogleMapToLocation(callerLat, callerLng)
        : centerArcgisMap(callerLat, callerLng);
    }
    if (bookedQuote) {
      handleGoogleDirectionsRender(bookedQuote);
      (async () => handleArcgisDirectionsRender(bookedQuote))();
    } else {
      directionsRenderer && directionsRenderer.setMap(null);
      routeLayer.removeAll();
    }
  }, [callerLat, callerLng, followCaller, bookedQuote, currentMap]);

  const createRouteParameters = (callerLat, callerLng, reserved) => {
    return new RouteParameters({
      stops: new FeatureSet({
        features: [
          new Graphic({
            geometry: {
              type: "point",
              latitude: callerLat,
              longitude: callerLng
            }
          }),
          new Graphic({
            geometry: {
              type: "point",
              latitude: reserved._embedded["pw:location"].entrances[0].coordinates[0],
              longitude: reserved._embedded["pw:location"].entrances[0].coordinates[1]
            }
          })
        ]
      }),
      returnDirections: true
    });
  };

  const handleArcgisDirectionsRender = async reserved => {
    try {
      if (!reserved || !view) return;

      const routeParams = createRouteParameters(callerLat, callerLng, reserved);
      const data = await route.solve(ROUTE_URL, routeParams);

      if (data.routeResults && data.routeResults.length > 0) {
        const currentRouteGraphic = data.routeResults[0].route;
        currentRouteGraphic.symbol = LINE_STYLE;
        currentRouteGraphic.attributes = { type: "route" };

        routeLayer.removeAll();
        routeLayer.add(currentRouteGraphic);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => setFullLocationData(null), [currentMap]);

  useEffect(() => {
    if (timesOfFormats.startTime && timesOfFormats.endTime) {
      getQuotes(timesOfFormats.startTime, timesOfFormats.endTime);
    } else {
      resetQuotes();
    }
  }, [timesOfFormats]);

  const mapToShow = currentMap === undefined ? innerCurrentMap : currentMap;

  return (
    <div style={{ height: "100%" }}>
      <Grid style={{ height: "100%", width: "100%", position: "relative", gap: 20 }}>
        <div
          className='webmap'
          ref={mapRef}
          style={{
            height: "100%",
            width: "100%",
            display: mapToShow === "arcgis" ? "flex" : "none"
          }}
        />
        {mapToShow === "arcgis" && (
          <div className='my-location-arcgis' onClick={() => centerArcgisMap(callerLat, callerLng)}>
            <MyLocation />
          </div>
        )}
        {apiReady ? (
          <>
            <Widgets view={view} setAnchorX={setAnchorX} />
            {callerLat && callerLng && (
              <Marker view={view} remove={false} lat={callerLat} lng={callerLng} type='caller' />
            )}
            {quotes?.map(item => {
              const lat = item._embedded["pw:location"].entrances[0].coordinates[0];
              const lng = item._embedded["pw:location"].entrances[0].coordinates[1];

              return (
                <Marker
                  view={view}
                  item={item}
                  key={item.location_id}
                  remove={!hasShownQuotesOnMap}
                  {...{ lat, lng, item, setAnchorX, setFullLocationData, setAnchorY }}
                />
              );
            })}
          </>
        ) : null}
        {mapToShow === "arcgis" && (
          <PopUp
            {...{
              anchorX,
              anchorY,
              handleDirectionsRender: handleArcgisDirectionsRender,
              fullLocationData,
              setFullLocationData,
              map: "arcgis"
            }}
          />
        )}

        <div
          style={{
            display: mapToShow === "google" ? "flex" : "none",
            height: "100%",
            width: "100%"
          }}
        >
          {mapToShow === "google" && (
            <div
              className='my-location-google'
              onClick={() => centerGoogleMapToLocation(callerLat, callerLng)}
            >
              <MyLocation />
            </div>
          )}
          <GoogleMapReact
            onZoomAnimationStart={e => {
              if (fullLocationData) {
                e.preventDefault();
              }
            }}
            ref={gmapsRef}
            id='gMap'
            defaultCenter={defaultCenter.current}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={callerLat ? 17 : 5}
            options={createMapOptions}
            zoomControl
            onWheel={e => e.stopPropagation()}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              libraries: ["places", "geometry"]
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              setTimeout(() => {
                setMapsapi({ map, maps, apiReady: true });
              }, 800);
            }}
          >
            {mapsapi.apiReady && <SearchBoxGoogle mapsapi={mapsapi} />}
            {mapToShow === "google" && (
              <PopUp
                mapRef={gmapsRef}
                map={"google"}
                {...{
                  anchorX,
                  anchorY,
                  fullLocationData,
                  setFullLocationData,
                  handleDirectionsRender: handleGoogleDirectionsRender
                }}
                lat={fullLocationData?._embedded?.["pw:location"]?.entrances[0].coordinates[0] || 0}
                lng={fullLocationData?._embedded?.["pw:location"]?.entrances[0].coordinates[1] || 0}
              />
            )}

            {quotes.length > 0 &&
              quotes.map(quote => {
                const lat = quote._embedded["pw:location"].entrances[0].coordinates[0];
                const lng = quote._embedded["pw:location"].entrances[0].coordinates[1];

                if (!hasShownQuotesOnMap) return null;

                const hasFreePrice = quote.purchase_options.filter(
                  item => item.base_price.USD === "0.00"
                );

                return (
                  <GoogleMapsMarker
                    hasFreePrice={!!hasFreePrice.length}
                    key={quote.location_id}
                    quote={quote}
                    {...{ lat, lng }}
                  />
                );
              })}
            {callerLat && callerLng && <GoogleMapCallerPin lat={callerLat} lng={callerLng} />}
          </GoogleMapReact>
        </div>
      </Grid>
      {!currentMap && (
        <Button
          fullWidth
          onClick={() => {
            setInnerCurrentMap(prev => (prev === "google" ? "arcgis" : "google"));
            setMapsapi({ apiReady: false, map: null, googlemaps: null });
          }}
        >
          {innerCurrentMap === "google" ? "Google" : "Arcgis"}
        </Button>
      )}
    </div>
  );
};

export default Map;
