import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//material
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TableFooter,
  TableContainer,
  TablePagination,
  makeStyles,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";

//context
import { Context as UserContext } from "../../context/UserContext";
import { Context as StatisticsContext } from "../../context/StatisticsContext";

//components
import PageLoading from "../../components/PageLoading";
import SearchHeader from "../../components/common/SearchHeader";

//icons
import HistoryIcon from "@material-ui/icons/History";
import GroupIcon from "@material-ui/icons/Group";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: "20px"
  },
  paperContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "15px",
    border: "1px solid rgb(0,0,0,0.12)"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  },
  formControl: {
    minWidth: 160,
    marginTop: "15px",
    marginRight: "15px"
  }
}));

//types
//1. Canceled
//2. Declined
//3. Missed
//4. No Guides
//5. Answered

const HistoryNoAnswer = () => {
  const {
    state: { histories, historyLoading },
    fetchHistory
  } = useContext(UserContext);
  const {
    state: { noGuidesHistory },
    getHistoryWithNoGuides
  } = useContext(StatisticsContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [openAvailableUsersDialog, setOpenAvailableUsersDialog] = useState(false);
  const [userData, setUserData] = useState([]);

  const historyType = [
    { value: "All", label: "All" },
    { value: "Canceled", label: "Canceled" },
    { value: "Declined", label: "Declined" },
    { value: "Missed", label: "Missed" },
    { value: "No Guides", label: "No Guides" }
  ];

  const [historyTypeSelected, setHistoryTypeSelected] = useState("All");

  const handlePopoverOpen = (event, data) => {
    setOpenAvailableUsersDialog(true);
    setUserData(data.availableUsers);
  };

  const handlePopoverClose = () => {
    setOpenAvailableUsersDialog(false);
  };

  const classes = useStyles();

  useEffect(() => {
    getHistoryWithNoGuides(startDate, endDate);
    fetchAllHistory(page, rowsPerPage, search, startDate, endDate, historyTypeSelected);
  }, []);

  const fetchAllHistory = async (
    page,
    rowsPerPage,
    search,
    startDate,
    endDate,
    historyTypeSelected
  ) => {
    const data = await fetchHistory(
      page,
      rowsPerPage,
      search,
      false,
      startDate,
      endDate,
      historyTypeSelected
    );
    setPaginationData(data);
  };

  const setPaginationData = async data => {
    if (!data) return;
    setPage(data.page);
    setRowsPerPage(data.limit);
    setCount(data.totalDocs);
  };

  const handleDateChange = async (date, type) => {
    if (type === "start") {
      setStartDate(date);
      fetchAllHistory(1, rowsPerPage, search, date, endDate, historyTypeSelected);
      getHistoryWithNoGuides(date, endDate);
    } else {
      setEndDate(date);
      fetchAllHistory(1, rowsPerPage, search, startDate, date, historyTypeSelected);
      getHistoryWithNoGuides(startDate, date);
    }
  };

  const onChangeType = e => {
    setHistoryTypeSelected(e.target.value);
    fetchAllHistory(1, rowsPerPage, search, startDate, endDate, e.target.value);
  };

  //used to search on hitting enter
  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      fetchAllHistory(page, rowsPerPage, search, startDate, endDate);
    }
  };

  //table change page fetches the users
  const handleChangePage = async (event, newPage) => {
    fetchAllHistory(newPage + 1, rowsPerPage, search, startDate, endDate);
  };
  //table row change fetches the users as the parameters go
  const handleChangeRowsPerPage = async event => {
    fetchAllHistory(1, +event.target.value, search, startDate, endDate);
  };

  const columns = [
    { id: "userName", label: "Agent", align: "left" },
    { id: "clientName", label: "User", align: "left" },
    {
      id: "createdAt",
      label: "Time",
      align: "left",
      format: value => (
        <div>
          <Typography>
            {" "}
            {moment(value.createdAt).format("MM/DD/YYYY")}
            {`(${moment(value.createdAt).tz("America/Detroit").format("h:mm A")})`}
          </Typography>
        </div>
      )
    },
    {
      id: "caller",
      label: "Caller",
      align: "left",
      format: value => (
        <div>
          <Typography> {value.caller === "User" ? value.userName : value.clientName}</Typography>
        </div>
      )
    },
    {
      id: "type",
      label: "Type",
      align: "left",
      format: value => (
        <div>
          <Typography>{value.type} </Typography>
        </div>
      )
    },
    {
      id: "actions",
      label: "Actions",
      align: "left",
      format: value => (
        <div>
          {value.type === "No Guides" ? (
            <IconButton onClick={e => handlePopoverOpen(e, value)}>
              <GroupIcon />
            </IconButton>
          ) : null}
        </div>
      )
    }
  ];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SearchHeader
        onClick={() => fetchAllHistory()}
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        onKeyPress={e => handleKeyDown(e)}
        title={"History"}
        icon={<HistoryIcon style={{ color: "white", width: 32, height: 32 }} />}
        searchPlaceholder={"Search in history"}
      />
      <Grid style={{ margin: "30px" }}>
        <Grid className={classes.gridContainer}>
          <Paper className={classes.paperContainer} elevation={0}>
            <Grid
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
            >
              <Grid
                item
                xs={4}
                sm={4}
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  marginBottom: "20px",
                  marginTop: "15px"
                }}
              >
                <Card>
                  <CardContent>
                    <Typography style={{ color: "grey" }}>
                      Missed calls with no guides available(UTC-4):
                    </Typography>
                    <Grid>
                      {noGuidesHistory.map((data, i) => (
                        <Typography key={i}>
                          {data.title}- {data.value}
                        </Typography>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
              >
                <KeyboardDatePicker
                  PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='normal'
                  label='Select start date'
                  value={startDate}
                  onChange={date => handleDateChange(date, "start")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginRight: "20px" }}
                  autoOk={true}
                />
                <KeyboardDatePicker
                  PopoverProps={{ anchorOrigin: { vertical: "bottom", horizontal: "right" } }}
                  variant='inline'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  margin='normal'
                  label='Select end date'
                  value={endDate}
                  onChange={date => handleDateChange(date, "end")}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginRight: "20px" }}
                  autoOk={true}
                />
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='demo-simple-select-outlined-label'>Type</InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={historyTypeSelected}
                    onChange={e => onChangeType(e)}
                    label='Type'
                  >
                    {historyType.map(type => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {historyLoading ? (
              <PageLoading page={false} />
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map(column => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth, paddingRight: "55px" }}
                          align={column.align}
                        >
                          <div>{column.label}</div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {
                    <TableBody>
                      {histories.length !== 0 ? (
                        histories.map(row => {
                          return (
                            <TableRow hover tabIndex={-1} key={row._id}>
                              {columns.map(column => {
                                const value = row[column.id];
                                return (
                                  <TableCell align={column.align} key={column.id}>
                                    <div>{column.format ? column.format(row) : value}</div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell>
                            <Typography>No data for the selected dates</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  }
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        open={openAvailableUsersDialog}
        onClose={handlePopoverClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{"Available users:"}</DialogTitle>
        <DialogContent>
          {userData.map(user => (
            <DialogContentText key={user._id}>{user.email}</DialogContentText>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePopoverClose} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default HistoryNoAnswer;
