import React from "react";

//material
import { Grid, Typography } from "@material-ui/core";

const Construction = () => {
  return (
    <div>
      <Grid
        style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100vh" }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Typography variant='h1'>Coming soon!</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Construction;
