import React, { useEffect, useState, useContext, useRef } from "react";
import useTimer from "../components/utils/hooks/useTimer";

import moment from "moment";
import ysFixWebmDuration from "fix-webm-duration";

//material
import {
  makeStyles,
  Grid,
  IconButton,
  Container,
  Typography,
  Paper,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@material-ui/core";

//components
import VideoPlayer from "../components/VideoPlayer";
import CallComment from "../components/call/CallComment";

//context
import { Context as CallContext } from "../context/CallContext";
import { Context as AuthContext } from "../context/AuthContext";
import socket from "../context/socket";

//icons
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import CallEndIcon from "@material-ui/icons/CallEnd";
import Map from "../components/Map";
import { useQuoteCtx } from "../components/utils/hooks/useQuoteCtx";
import { useMapCtx } from "../components/utils/hooks/useMapCtx";
import { MyModal } from "../components/common/MyModal";
import { ReservedParkingModal } from "../components/common/ReservedParkingModal";
import { LoadingModal } from "../components/common/LoadingModal";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: "90vh",
    marginTop: "10px",
    flexDirection: "column"
  },
  buttonContainer: {
    width: 40,
    height: 40,
    borderRadius: 50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 120
  }
}));

let configuration = {
  iceServers: [
    {
      urls: "turn:tappydev.com:5349",
      credential: "t@ppyDev01!",
      username: "tappyDev"
    }
  ]
};

const ChatPage = ({ history }) => {
  const {
    state: { room, caller, clientId },
    setOpenCallModal,
    endCall,
    addHistory,
    getClientData,
    setRecordFile
  } = useContext(CallContext);
  const {
    state: { user }
  } = useContext(AuthContext);
  const classes = useStyles();
  const { timer, handleStart, isActive, getTimer } = useTimer(0);

  const {
    state: { quotes, hasShownQuotesOnMap, bookedQuote, isLoading },
    resetContext,
    setHasShownQuotesOnMap,
    checkForBookedQuote
  } = useQuoteCtx();

  const [modalOpen, setModalOpen] = useState(false);
  const [reservedParkingModalOpen, setReservedParkingModalOpen] = useState(false);

  const { map, setMap, followCaller, setFollowCaller, setTimesOfFormats, timesOfFormats } =
    useMapCtx();

  const [remoteStream, setRemoteStream] = useState(null);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [startRecording, setStartRecording] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [disableMuteButton, setDisableMuteButton] = useState(true);

  const [selectedInputDevice, setSelectedInputDevice] = useState("");
  const storedOffer = useRef(false);
  const localStream = useRef(null);

  // const remoteStream = useCallbackRef(new MediaStream(), () => forceUpdate(true));

  const peerConnection = useRef();
  const reconnect = useRef();
  const audioRef = useRef();
  const selectedOutputDevice = useRef("default");
  let coordArray = useRef([]);
  const numOfStreams = useRef(0);
  const recorder = useRef();
  const startTime = useRef();

  useEffect(() => {
    if (!room) {
      history.push("/calls");
    }
  }, [room]);

  useEffect(() => checkForBookedQuote(), []);

  useEffect(() => {
    getClientData(clientId);

    socket.on("callAccepted", async () => {
      setOpenCallModal(false);
    });

    socket.on("joinRoomInCall", async () => {
      socket.emit("joinRoom", { roomId: room, userId: clientId });
    });

    socket.on("exchange-client", async message => {
      if (message.offer) {
        console.log(message.offer, "OFFER RECEIVED");
        // socket.emit('joinRoom', { roomId: room })
        peerConnection.current.setRemoteDescription(new RTCSessionDescription(message.offer));
        const answer = await peerConnection.current.createAnswer();
        peerConnection.current.setLocalDescription(answer);
        socket.emit("exchange-server-answer", { roomId: room, answer: answer });
      } else if (message.answer) {
        console.log(message.answer, "ANSWER RECEIVED");
        peerConnection.current.setRemoteDescription(new RTCSessionDescription(message.answer));
      } else if (message.iceCandidate) {
        console.log(message.iceCandidate, "CANDIDATE RECEIVED");
        try {
          await peerConnection.current.addIceCandidate(message.iceCandidate);
        } catch (e) {
          console.error("Error adding received ice candidate", e);
        }
      }
    });

    navigator.mediaDevices.ondevicechange = async () => {
      await getConnectedDevices(true);
    };

    return () => {
      closeSockets();
      stopStream();
    };
  }, []);

  const recordVideo = async () => {
    const mediaParts = [];

    const ac = new AudioContext();
    const localSource = ac.createMediaStreamSource(
      new MediaStream([localStream.current.getAudioTracks()[0]])
    );
    const remoteSource = ac.createMediaStreamSource(
      new MediaStream([remoteStream.getAudioTracks()[0]])
    );
    const dest = ac.createMediaStreamDestination();
    localSource.connect(dest);
    remoteSource.connect(dest);
    //delete this if only audio needed

    if (remoteStream.getVideoTracks()[0]) {
      dest.stream.addTrack(remoteStream.getVideoTracks()[0]);
    }

    recorder.current = new MediaRecorder(dest.stream);
    recorder.current.onstop = e => {
      var duration = Date.now() - startTime.current;
      var buggyBlob = new Blob(mediaParts, { type: "video/x-msvideo" });
      ysFixWebmDuration(buggyBlob, duration, function (fixedBlob) {
        console.log(fixedBlob);
        setRecordFile(fixedBlob);
      });
    };

    recorder.current.ondataavailable = event => {
      var data = event.data;
      if (data && data.size > 0) {
        mediaParts.push(data);
      }
    };

    recorder.current.start();
    startTime.current = Date.now();
  };

  useEffect(() => {
    if (startRecording) {
      recordVideo();
    }
  }, [startRecording]);

  //test the audio change input

  const getConnectedDevices = async fromListener => {
    const inputDevices = [];
    const outputDevices = [];
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log(devices, "DEVICES");

    devices.forEach(deviceInfo => {
      if (deviceInfo.kind === "audioinput") {
        inputDevices.push(deviceInfo);
        setAudioInputDevices(inputDevices);
      } else if (deviceInfo.kind === "audiooutput") {
        outputDevices.push(deviceInfo);
        setAudioOutputDevices(outputDevices);
      }
    });

    const foundInput = devices.find(el => el.deviceId === localStorage.getItem("audioInput"));
    const foundOutput = devices.find(el => el.deviceId === localStorage.getItem("audioOutput"));
    if (foundOutput) {
      selectedOutputDevice.current = foundOutput.deviceId;
      if (storedOffer.current && audioRef.current) {
        if (typeof audioRef.current.player?.player?.player.sinkId !== "undefined") {
          await audioRef.current.player?.player?.player.setSinkId(foundOutput.deviceId);
        }
      }
    } else {
      selectedOutputDevice.current = "default";
      if (storedOffer.current && audioRef.current) {
        if (typeof audioRef.current.player?.player?.player.sinkId !== "undefined") {
          await audioRef.current.player?.player?.player.setSinkId("default");
        }
      }
    }
    if (fromListener) return;
    if (foundInput) {
      console.log(foundInput, "found inpuit");
      setSelectedInputDevice(foundInput.deviceId);
      const stream = await getStream(foundInput.deviceId);
      return stream;
    } else {
      console.log(foundInput, "not found inpuit");

      setSelectedInputDevice("default");
      const stream = await getStream("default");
      return stream;
    }
  };

  const onEndCall = async () => {
    endCall(history, room);
  };

  const joinCall = async () => {
    if (caller) return;

    console.log("JOINING CALL");

    try {
      await createPeerConnection();
    } catch (e) {
      console.log(e);
    }
  };

  const makeCall = async () => {
    if (!caller) return;

    console.log("MAKINGCALL");
    try {
      await createPeerConnection();
      await createOffer();
    } catch (e) {
      console.log(e);
    }
  };

  const createOffer = async () => {
    const offer = await peerConnection.current.createOffer({
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1
    });
    await peerConnection.current.setLocalDescription(offer);
    socket.emit("store-offer", { roomId: room, offer: offer, userId: user._id });
  };

  const restartIceConnection = async () => {
    if (!peerConnection.current) return;
    // socket.emit('joinRoom', { roomId: room })
    const offer = await peerConnection.current.createOffer({
      offerToReceiveAudio: 1,
      offerToReceiveVideo: 1,
      iceRestart: true
    });
    await peerConnection.current.setLocalDescription(offer);
    socket.emit("exchange-server-offer", {
      roomId: room,
      offer: peerConnection.current.localDescription
    });
  };

  const createPeerConnection = async () => {
    peerConnection.current = new RTCPeerConnection(configuration);
    const stream = await getConnectedDevices();
    // stream.getAudioTracks()[0].enabled = false
    for (const track of stream.getTracks()) {
      peerConnection.current.addTrack(track, stream);
    }

    peerConnection.current.ontrack = async e => {
      setRemoteStream(null);
      let theStream = new MediaStream();
      console.log("remotePC tracking with ", e);
      if (e.streams[0] && remoteStream !== e.streams[0]) {
        numOfStreams.current++;
        console.log("RemotePC received the stream", e.streams[0]);
        e.streams[0].getTracks().forEach(track => {
          theStream.addTrack(track);
        });
        setRemoteStream(theStream);
        setDisableMuteButton(false);
        if (numOfStreams.current === 1) {
          setIsAudio(true);
          stream.getAudioTracks()[0].enabled = true;
          setStartRecording(true);
        }
        if (numOfStreams.current > 0) {
          if (typeof audioRef.current.player?.player?.player.sinkId !== "undefined") {
            await audioRef.current.player?.player.player.setSinkId(selectedOutputDevice.current);
          }
        }
      }
    };

    peerConnection.current.onicecandidate = event => {
      try {
        if (event && event.candidate) {
          if (caller) {
            if (storedOffer.current) {
              console.log("sending ice cand", storedOffer.current);
              socket.emit("onicecandidate", { roomId: room, iceCandidate: event.candidate });
            } else {
              console.log("storing icecandiddate");
              socket.emit("store-onicecandidate", {
                roomId: room,
                iceCandidate: event.candidate,
                userId: user._id
              });
            }
          } else {
            console.log("sending ice cand");
            socket.emit("onicecandidate", { roomId: room, iceCandidate: event.candidate });
          }
        }
      } catch (e) {
        console.error(`Error adding remotePC iceCandidate: ${e}`);
      }
    };

    peerConnection.current.oniceconnectionstatechange = function (event) {
      console.log(event, "iceconnectionstatechange");
    };

    peerConnection.current.onnegotiationneeded = async () => {
      try {
        if (storedOffer.current) {
          console.log(peerConnection.current.connectionState, "ION NEGGOTIONATI NENED");
          const offer = await peerConnection.current.createOffer({
            offerToReceiveAudio: 1,
            offerToReceiveVideo: 1
          });
          await peerConnection.current.setLocalDescription(offer);
          socket.emit("exchange-server-offer", {
            roomId: room,
            offer: peerConnection.current.localDescription
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    let count = 0;
    let reconnectingTime = 0;

    peerConnection.current.addEventListener("connectionstatechange", async event => {
      console.log(event.currentTarget.connectionState, "STATE");
      if (event.currentTarget.connectionState === "connected" && count === 0) {
        resetContext();
        setTimesOfFormats({
          startTime: undefined,
          endTime: undefined
        });
        setFollowCaller(false);

        const callerData = localStorage.getItem("callerData");

        const caller = JSON.parse(callerData)?.[clientId];

        if (!caller) {
          console.log("NO CALLER");

          localStorage.setItem("callerData", JSON.stringify({ [clientId]: {} }));
        }

        storedOffer.current = true;
        handleStart();
        count++;
        socket.on("receive-location", data => {
          console.log(data, "LOCATION");
          if (!data) return;
          setLat(data.position?.coords.latitude);
          setLng(data.position?.coords.longitude);
          coordArray.current.push(data.position);
        });
      } else if (event.currentTarget.connectionState === "connected") {
        clearInterval(reconnect.current);
        reconnectingTime = 0;
      } else if (event.currentTarget.connectionState === "disconnected") {
        if (caller && storedOffer.current) {
          reconnect.current = setInterval(() => {
            reconnectingTime++;
            if (reconnectingTime === 5) {
              onEndCall();
            } else {
              if (peerConnection.current.restartIce) {
                peerConnection.current.restartIce();
              } else {
                restartIceConnection();
              }
            }
          }, 3000);
        }
      } else if (peerConnection.current.connectionState === "failed") {
        clearInterval(reconnect.current);
        onEndCall();
        reconnectingTime = 0;
      }
    });
  };

  const getStream = async deviceId => {
    let audioState = false;
    if (localStream.current) {
      audioState = await localStream.current.getAudioTracks()[0].enabled;
      await localStream.current.getTracks().forEach(track => track.stop());
      localStream.current = null;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: deviceId ? { deviceId } : { deviceId: "default" }
      });
      stream.getAudioTracks()[0].enabled = audioState;

      if (deviceId) {
        let audioTrack = stream.getAudioTracks()[0];
        var sender = await peerConnection.current.getSenders().find(function (s) {
          console.log(s, "sender");
          console.log(audioTrack, "track audio");

          return s.track.kind === audioTrack.kind;
        });
        if (sender) {
          console.log("replacing track", audioTrack);
          sender.replaceTrack(audioTrack);
        }
      }
      localStream.current = stream;

      return stream;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (caller) {
      makeCall();
    } else {
      joinCall();
    }
  }, [caller]);

  useEffect(() => {
    return () => {
      if (isActive) addHistory(room, getTimer.current, caller, coordArray.current);
    };
  }, [isActive, caller]);

  const muteCall = () => {
    setIsAudio(prevState => {
      localStream.current.getAudioTracks()[0].enabled = !prevState;
      return !prevState;
    });
  };

  const stopStream = async () => {
    if (localStream.current) {
      console.log("stopping stream");
      localStream.current.getTracks().forEach(track => track.stop());
      if (recorder.current) {
        recorder.current.stop();
      }
    }
    localStream.current = null;
    clearInterval(reconnect.current);
    setRemoteStream(null);
    // remoteStream.current = null
    peerConnection.current.close();
    peerConnection.current = null;
  };

  const closeSockets = () => {
    socket.off("exchange-client");
    socket.off("receive-location");
    socket.off("callAccepted");
    socket.off("joinRoomInCall");
  };

  const handleChangeAudioInput = async e => {
    setSelectedInputDevice(e.target.value);
    localStorage.setItem("audioInput", e.target.value);
    await getStream(e.target.value);
    localStream.current.getAudioTracks()[0].enabled = isAudio;
  };

  const handleChangeAudioOutput = async e => {
    try {
      console.log(audioRef);
      selectedOutputDevice.current = e.target.value;
      localStorage.setItem("audioOutput", e.target.value);
      if (typeof audioRef.current.player?.player?.player.sinkId !== "undefined") {
        await audioRef.current.player.player.player.setSinkId(e.target.value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid
      style={{
        flexDirection: "row",
        display: "flex",
        backgroundColor: "white",
        zIndex: 2,
        overflow: "hidden"
      }}
    >
      <Container maxWidth='xl' className={classes.container}>
        {remoteStream && (
          <VideoPlayer
            myRef={audioRef}
            isVideo={remoteStream && remoteStream.getVideoTracks().length > 0}
            url={remoteStream}
            playing={true}
          />
        )}
        <Grid style={{ height: "100%", width: "100%", zIndex: 4, backgroundColor: "white" }}>
          {lat !== 0 && lng !== 0 && (
            <Map
              currentMap={map}
              timesOfFormats={timesOfFormats}
              followCaller={followCaller}
              timesOfFo
              callerLat={lat}
              callerLng={lng}
            />
          )}
        </Grid>
        <Grid
          component={Paper}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            zIndex: 100,
            backgroundColor: "white",
            width: "inherit",
            borderTop: "1px solid grey"
          }}
        >
          <IconButton onClick={muteCall} disabled={disableMuteButton}>
            {isAudio ? <MicIcon /> : <MicOffIcon />}
          </IconButton>
          <IconButton onClick={onEndCall} variant='contained'>
            <Grid style={{ backgroundColor: "red" }} className={classes.buttonContainer}>
              <CallEndIcon style={{ color: "white" }} />
            </Grid>
          </IconButton>
          <Typography style={{ alignSelf: "center" }}>
            {moment.duration(timer, "seconds").format("h:mm:ss", { trim: false })}
          </Typography>
          <Button onClick={() => setMap(a => (a === "google" ? "arcgis" : "google"))}>
            {map === "google" ? "Arcgis" : "Google"}
          </Button>

          <CallComment room={room} />
          {numOfStreams.current > 0 && (
            <FormControl className={classes.formControl}>
              <InputLabel id='demo-simple-select-label1'>Audio input</InputLabel>
              <Select
                labelId='demo-simple-select-label1'
                id='demo-simple-select1'
                value={selectedInputDevice}
                onChange={handleChangeAudioInput}
              >
                {audioInputDevices.map(({ deviceId, label }) => (
                  <MenuItem key={deviceId} value={deviceId}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {numOfStreams.current > 0 && (
            <FormControl className={classes.formControl}>
              <InputLabel id='demo-simple-select-label2'>Audio output</InputLabel>
              <Select
                labelId='demo-simple-select-label2'
                id='demo-simple-select2'
                value={selectedOutputDevice.current}
                onChange={handleChangeAudioOutput}
              >
                {audioOutputDevices.map(({ deviceId, label }) => (
                  <MenuItem key={deviceId} value={deviceId}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button style={{ marginLeft: 15 }} onClick={() => setFollowCaller(!followCaller)}>
            {followCaller ? "Stop following caller" : "Follow caller"}
          </Button>
          <Button onClick={() => setModalOpen(true)}>Reserve Parking</Button>
          <Button
            onClick={() => setHasShownQuotesOnMap(!hasShownQuotesOnMap)}
            disabled={quotes?.length === 0}
          >
            {hasShownQuotesOnMap ? "Hide parking spaces" : `Show parking spaces`}
          </Button>
          <Button onClick={() => setReservedParkingModalOpen(true)} disabled={!bookedQuote}>
            Check reserved parking
          </Button>
        </Grid>
      </Container>
      <MyModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onClose={() => setModalOpen(false)}
        setTimesOfFormats={setTimesOfFormats}
      />
      <ReservedParkingModal
        modalOpen={reservedParkingModalOpen}
        setModalOpen={setReservedParkingModalOpen}
        onClose={() => setReservedParkingModalOpen(false)}
      />
      <LoadingModal visible={isLoading} />
    </Grid>
  );
};

export default ChatPage;
