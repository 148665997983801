import React, { useState } from "react";
import { Link } from "react-router-dom";

//material
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

//icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(theme => ({
  container: {
    padding: "60px",
    alignSelf: "center"
  },
  sectionTitle: {
    fontSize: "20px"
  },
  input: {
    marginTop: "20px"
  }
}));

const Login = ({ errorMessage, signin, history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberPass, setRememberPass] = useState(localStorage.getItem("email") ? true : false);
  const classes = useStyles();
  const [email, setEmail] = useState(
    localStorage.getItem("email") ? localStorage.getItem("email") : ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem("password") ? localStorage.getItem("password") : ""
  );

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onSignin = async () => {
    const role = await signin(email, password, rememberPass);
    if (role === "Admin") {
      history.push("/admin/call-center-users");
    } else {
      history.push("/calls");
    }
  };

  const handleKeyDown = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSignin();
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} className={classes.container}>
      <Typography variant='h6' marked='center' color='primary' className={classes.sectionTitle}>
        LOGIN TO YOUR ACCOUNT
      </Typography>
      <Grid container style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
        <FormControl className={classes.input}>
          <TextField
            variant='outlined'
            color='primary'
            required
            label='Email'
            name='email'
            autoComplete='email'
            type='text'
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl variant='outlined' className={classes.input}>
          <InputLabel color='primary' htmlFor='standard-adornment-password'>
            Password *
          </InputLabel>
          <OutlinedInput
            variant='outlined'
            id='standard-adornment-password'
            type={showPassword ? "text" : "password"}
            name='password'
            required
            color='primary'
            value={password}
            onChange={e => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={80}
            onKeyPress={e => handleKeyDown(e)}
          />
        </FormControl>
        <Grid style={{ marginTop: "30px", justifyContent: "space-between", display: "flex" }}>
          <FormControlLabel
            control={<Checkbox name='checkedC' />}
            label='Remember Me'
            checked={rememberPass}
            onChange={e => setRememberPass(e.target.checked)}
          />
          <Typography
            component={Link}
            to='/forgot-password'
            color='secondary'
            style={{ alignSelf: "center" }}
          >
            Forgot Password?
          </Typography>
        </Grid>
      </Grid>
      {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
      <Button
        size='large'
        fullWidth
        style={{ marginTop: "30px" }}
        variant='contained'
        color='primary'
        onClick={() => onSignin()}
      >
        Login
      </Button>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "20px"
        }}
      >
        <Typography style={{ fontSize: 10, color: "grey", alignSelf: "center" }}>
          For support contact us at{" "}
          <a href={"mailto:" + "support@tappyguide.com"}>support@tappyguide.com</a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
