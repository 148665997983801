import React, { useContext, useState } from "react";
import { Switch, Route, useRouteMatch, Link, withRouter, Redirect } from "react-router-dom";
import clsx from "clsx";
//material
import {
  makeStyles,
  Drawer,
  AppBar,
  Card,
  CardMedia,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  Hidden,
  MenuItem,
  Grid
} from "@material-ui/core";

//pages
import CallCenterUsers from "./Admin/CallCenterUsers";
import MobileUsers from "./Admin/MobileUsers";
import AdminInfo from "./Admin/AdminInfo";
import History from "./Admin/History";
import HistoryNoAnswer from "./Admin/HistoryNoAnswer";
import Statistics from "./Admin/Statistics";
import Logs from "./Admin/Logs";
import Events from "./Admin/Events";
import AgentInfo from "./Admin/AgentInfo";
import Tickets from "./Admin/Tickets";
import Map from "./Admin/Map";
import PushNotifications from "./Admin/PushNotifications";
import PushNotificationsHistory from "./Admin/PushNotificationsHistory";

//context
import { Context as AuthContext } from "../context/AuthContext";

//icons
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import LockIcon from "@material-ui/icons/Lock";
import logo from "../images/appstore.png";
import HomeIcon from "@material-ui/icons/Home";
import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CodeIcon from "@material-ui/icons/Code";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import HistoryIcon from "@material-ui/icons/History";
import WhatshotIcon from "@material-ui/icons/Whatshot";
import NotificationsIcon from "@material-ui/icons/Notifications";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    backgroundColor: theme.palette.background.paper
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  leftTolbar: {
    backgroundColor: theme.palette.primary.main,
    height: "200px"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default
  },
  content: {
    flexGrow: 1,
    minHeight: "80vh"
  },
  grow: {
    flexGrow: 1
  },
  listItemHover: {
    width: 260,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  listItemTitle: {
    fontSize: "12px",
    color: "#0000008A"
  },
  listItemText: {
    fontSize: "14px"
  },
  media: {
    height: "35px",
    width: "35px"
  },
  iconStyle: {
    width: "16px",
    height: "16px"
  },
  dividerStyle: {
    marginTop: "4%"
  }
}));

const AdminPage = props => {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const { container } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    state: { user },
    logout
  } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <Grid>
      <Grid
        style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
        className={clsx(classes.leftTolbar, classes.toolbar)}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "25px"
          }}
        >
          <Card className={classes.media}>
            <CardMedia className={classes.media} image={logo} />
          </Card>
          <Typography
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: "14px",
              marginLeft: "20px"
            }}
          >
            Tappy Guide
          </Typography>
        </Grid>
        <Grid style={{ marginTop: "60px" }}>
          <Typography
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              fontSize: "16px"
            }}
          >{`Welcome ${user.firstName}`}</Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <ListItemText disableTypography className={classes.listItemTitle} primary='CALL CENTER' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/call-center-users`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/call-center-users`}
        >
          <ListItemIcon>
            <GroupOutlinedIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Agents' />
        </ListItem>
        <Divider className={classes.dividerStyle} />
        <ListItem>
          <ListItemText disableTypography className={classes.listItemTitle} primary='MOBILE' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/mobile-users`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/mobile-users`}
        >
          <ListItemIcon>
            <PhoneIphoneIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Users' />
        </ListItem>
        <Divider className={classes.dividerStyle} />
        <ListItem>
          <ListItemText disableTypography className={classes.listItemTitle} primary='CALLS' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/call-history`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/call-history`}
        >
          <ListItemIcon>
            <HistoryIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='History' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/call-history-no-answer`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/call-history-no-answer`}
        >
          <ListItemIcon>
            <HistoryIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.listItemText}
            primary='History more data'
          />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/admin-statistics`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/admin-statistics`}
        >
          <ListItemIcon>
            <BarChartIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Statistics' />
        </ListItem>
        {/* <ListItem onClick={() => {
                    handleDrawerClose()
                }}
                    selected={props.location.pathname === `${path}/admin-event-logs`}
                    className={classes.listItemHover}
                    button
                    component={Link}
                    to={`${url}/admin-event-logs`}>
                    <ListItemIcon>
                        <CodeIcon className={classes.iconStyle} />
                    </ListItemIcon>
                    <ListItemText disableTypography className={classes.listItemText} primary="Schedule logs" />
                </ListItem> */}
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/admin-events`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/admin-events`}
        >
          <ListItemIcon>
            <CalendarTodayIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Appointments' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/admin-map`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/admin-map`}
        >
          <ListItemIcon>
            <WhatshotIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Hotspot' />
        </ListItem>
        <Divider className={classes.dividerStyle} />
        <ListItem>
          <ListItemText disableTypography className={classes.listItemTitle} primary='SUPPORT' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/admin-tickets`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/admin-tickets`}
        >
          <ListItemIcon>
            <HelpOutlineOutlinedIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Tickets' />
        </ListItem>
        <Divider className={classes.dividerStyle} />
        <ListItem>
          <ListItemText disableTypography className={classes.listItemTitle} primary='SETTINGS' />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/admin-info`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/admin-info`}
        >
          <ListItemIcon>
            <LockIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.listItemText}
            primary='Administrator'
          />
        </ListItem>
        <ListItem
          onClick={() => {
            handleDrawerClose();
          }}
          selected={props.location.pathname === `${path}/push-notifications`}
          className={classes.listItemHover}
          button
          component={Link}
          to={`${url}/push-notifications`}
        >
          <ListItemIcon>
            <NotificationsIcon className={classes.iconStyle} />
          </ListItemIcon>
          <ListItemText disableTypography className={classes.listItemText} primary='Push' />
        </ListItem>
      </List>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} elevation={1} color='inherit'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            component={Link}
            to='/home'
          >
            <HomeIcon />
          </IconButton>

          <div className={classes.grow} />
          <MenuItem onClick={() => logout(props.history)}>Logout</MenuItem>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          <Drawer
            container={container}
            variant='temporary'
            anchor={"left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.offset} />
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* ADMIN ROUTES */}
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/call-center-users`} />
          </Route>
          <Route exact path={`${path}/call-center-users`}>
            <CallCenterUsers />
          </Route>
          <Route exact path={`${path}/mobile-users`}>
            <MobileUsers />
          </Route>
          <Route exact path={`${path}/call-history`}>
            <History />
          </Route>
          <Route exact path={`${path}/call-history-no-answer`}>
            <HistoryNoAnswer />
          </Route>
          <Route exact path={`${path}/admin-info`}>
            <AdminInfo />
          </Route>
          <Route exact path={`${path}/admin-statistics`}>
            <Statistics />
          </Route>
          <Route exact path={`${path}/admin-event-logs`}>
            <Logs />
          </Route>
          <Route exact path={`${path}/admin-events`}>
            <Events />
          </Route>
          <Route exact path={`${path}/admin-agent`}>
            <AgentInfo />
          </Route>
          <Route exact path={`${path}/admin-tickets`}>
            <Tickets />
          </Route>
          <Route exact path={`${path}/admin-map`}>
            <Map />
          </Route>
          <Route exact path={`${path}/push-notifications`}>
            <PushNotifications />
          </Route>
          <Route exact path={`${path}/push-notifications/history`}>
            <PushNotificationsHistory />
          </Route>
        </Switch>
      </main>
    </div>
  );
};

export default withRouter(AdminPage);
