import createDataContext from './createDataContext'
import axios from 'axios';
import socket from '../context/socket'

const callReducer = (state, action) => {
    switch (action.type) {
        case 'fetch_calls':
            return { ...state, calls: action.payload.docs }
        case 'add_room':
            return { ...state, room: action.payload.roomId, callerName: action.payload.fullName, clientName: action.payload.clientName, caller: action.payload.caller, clientId: action.payload.clientId }
        case 'remove_room':
            return { ...state, room: null, callerName: null, client: null }
        case 'fetch_client':
            return { ...state, client: action.payload }
        case 'call_modal':
            return { ...state, openCallModal: action.payload }
        case 'cancel_cooldown':
            return { ...state, cancelCooldown: action.payload }
        case 'set_record_file':
            return { ...state, recordFile: action.payload }
        case 'set_history_id':
            return { ...state, historyId: action.payload }
        case 'add_call_comments':
            if (action.payload.page === 1) {
                return { ...state, comments: action.payload.docs }
            } else {
                return { ...state, comments: [...state.comments, ...action.payload.docs] }
            }
        case 'update_call':
            const indexToUpdate = state.calls.findIndex((v) => v._id === action.payload._id)
            state.calls[indexToUpdate] = action.payload
            return { ...state }
        case 'add_call_comment':
            state.comments.unshift(action.payload)
            return { ...state }
        case 'file_is_uploading':
            return { ...state, fileIsUploading: action.payload.uploading, error: action.payload.error }
        default:
            return state
    }
}

const fetchCalls = (dispatch) => {
    return async (page, limit, search) => {
        try {
            const response = await axios.get(`/api/get-calls?page=${page}&limit=${limit}&search=${search}`);
            console.log(response)
            dispatch({ type: 'fetch_calls', payload: response.data })

            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const fetchOneCall = (dispatch) => {
    return async (roomId) => {
        try {
            const response = await axios.get(`/api/get-one-call?roomId=${roomId}`);
            dispatch({ type: 'update_call', payload: response.data })
            console.log(response.data)
            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const createCall = (dispatch) => {
    return async (clientId, roomId, clientName) => {
        dispatch({ type: 'cancel_cooldown', payload: true })

        dispatch({ type: 'add_room', payload: { roomId, clientName, caller: true, clientId } })
    }
}

const cancelTheCooldown = (dispatch) => {
    return async () => {
        dispatch({ type: 'cancel_cooldown', payload: false })
    }
}

const addCalling = (dispatch) => {
    return async ({ roomId, fullName, clientId }) => {
        dispatch({ type: 'add_room', payload: { roomId, caller: false, fullName, clientId } })
    }
}

const getClientData = (dispatch) => {
    return async (clientId) => {
        try {
            const response = await axios.get(`/api/get_client_info?clientId=${clientId}`);
            console.log(response, "CLIENT DATA")
            dispatch({ type: 'fetch_client', payload: response.data })
        } catch (e) {
            console.log(e)
        }

    }
}

const refuseCall = (dispatch) => {
    return async (history, roomId, userId, customMessage = '', caller) => {
        socket.emit('userRefuseCall', ({ roomId, userId, customMessage, caller }))
        console.log(socket, 'REFUSECALL')
        dispatch({ type: 'remove_room' })
        history.push('/calls')
    }
}

const endCall = (dispatch) => {
    return async (history, roomId) => {
        socket.emit('endCall', ({ roomId }))
        dispatch({ type: 'remove_room' })
        history.push('/calls')
    }
}

const clientEndCall = (dispatch) => {
    return async (history) => {
        dispatch({ type: 'remove_room' })
        history.push('/calls')
    }
}

const addHistory = (dispatch) => {
    return async (roomId, duration, caller, coords) => {
        try {
            const response = await axios.post(`/api/add-history`, { roomId, duration, caller, coords });
            console.log(response)
            dispatch({ type: 'set_history_id', payload: response.data._id })
        }catch(e) {
            console.log(e)
            dispatch({ type: 'set_history_id', payload: null })
        }
       
    }
}

const closeUploadDialog = (dispatch) => {
    return async () => {
        dispatch({ type: 'file_is_uploading', payload: { uploading:false, error: null } } )
    }
}

const addHistoryRecord = (dispatch) => {
    return async (recordFile) => {
        const formData = new FormData()
        formData.append('myFile', recordFile);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        try {
            dispatch({ type: 'file_is_uploading', payload: { uploading:true, error: null } })
            const response = await axios.post(`/api/add-history-record`, formData, config);
            if (response) {
                dispatch({ type: 'file_is_uploading', payload: { uploading:false, error: null } })
            }
            console.log(response)
            dispatch({ type: 'set_record_file', payload: null })
            dispatch({ type: 'set_history_id', payload: null })
        } catch (e) {
            console.log(e)
            dispatch({ type: 'file_is_uploading', payload: { uploading:true, error: e } })
        }

    }
}

const setRecordFile = (dispatch) => {
    return async (recordFile) => {
        dispatch({ type: 'set_record_file', payload: recordFile })
    }
}

const setOpenCallModal = (dispatch) => {
    return async (modal) => {
        dispatch({ type: 'call_modal', payload: modal })
    }
}


//comments
const addCallComment = (dispatch) => {
    return async (callId, comment) => {
        const response = await axios.post(`/api/add-call-comment`, { callId, comment });
        console.log(response, 'call comment')
        dispatch({ type: 'add_call_comment', payload: response.data })
    }
}

const getCallComments = (dispatch) => {
    return async (callId, page, limit) => {
        const response = await axios.get(`/api/get-call-comments?callId=${callId}&page=${page}&limit=${limit}`);
        console.log(response, 'call comments')
        dispatch({ type: 'add_call_comments', payload: response.data })
        return response.data
    }
}

export const { Provider, Context } = createDataContext(callReducer,
    { setOpenCallModal, fetchCalls, getCallComments, closeUploadDialog, addCallComment, addHistoryRecord, setRecordFile, addCalling, refuseCall, endCall, clientEndCall, addHistory, createCall, fetchOneCall, cancelTheCooldown, getClientData },
    { fileIsUploading: false,error: null, openCallModal: false, calls: [], room: null, callerName: null, clientName: null, caller: false, clientId: null, cancelCooldown: false, client: null, recordFile: null, historyId: null, comments: [] })