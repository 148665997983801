import { useState, useEffect, useRef } from "react";

import Graphic from "@arcgis/core/Graphic.js";

import "selectize";
import "./Selectize.css";

const Widgets = props => {
  const [graphic, setGraphic] = useState(null);

  let lng = useRef(props.lat);
  let lat = useRef(props.lng);
  let clickHandlers = [];

  useEffect(() => {
    lat.current = props.lat;
    lng.current = props.lng;
  }, [props.lat, props.lng]);

  useEffect(() => {
    // Create a polygon geometry
    const point = {
      type: "point", // autocasts as new Polygon()
      latitude: lat.current,
      longitude: lng.current
    };

    // Create a symbol for rendering the graphic

    const hasFreePrice =
      props?.item?.purchase_options?.filter(item => item.base_price.USD === "0.00") || [];

    const color =
      props?.type === "caller" ? [226, 119, 40] : !hasFreePrice.length ? [0, 0, 0] : [0, 255, 0];

    const simpleMarkerSymbol = {
      type: "simple-marker",
      color,
      outline: {
        color: [255, 255, 255], // white
        width: 1
      }
    };

    // Add the geometry and symbol to a new graphic
    const graphic = new Graphic({
      geometry: point,
      symbol: simpleMarkerSymbol
    });
    setGraphic(graphic);

    const handlePointClick = event => {
      if (event.button === 1) return;
      if (!graphic) return;
      props.view.hitTest(event.screenPoint).then(function (response) {
        const clickedGraphics = response.results;

        if (clickedGraphics.length <= 0) return;

        const clickedGraphic = clickedGraphics[0].graphic;

        if (clickedGraphic !== graphic) return;

        props.setFullLocationData(props.item);
        props.setAnchorX(event.x);
        props.setAnchorY(event.y);
      });
    };

    const newClickHandler = props.view.on("click", handlePointClick);
    clickHandlers.push(newClickHandler);

    props.view.graphics.add(graphic);

    if (props.remove) {
      console.log("remove");
      props.view.graphics.remove(graphic);
      for (const event of clickHandlers) {
        event.remove();
      }
      clickHandlers = [];
    } else {
      console.log("no remove");
    }

    return () => {
      if (clickHandlers) {
        for (const event of clickHandlers) {
          event.remove();
        }
        clickHandlers = [];
      }
      props.view.graphics.remove(graphic);
    };
  }, [lat.current, props.test, props.remove]);

  return null;
};

export default Widgets;
