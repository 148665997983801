import React, { useContext } from "react";
import moment from "moment";

//material
import { Container, Grid, Typography, IconButton } from "@material-ui/core";

//icons
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

//context
import { Context as EventContext } from "../../context/EventContext";
import { Context as AuthContext } from "../../context/AuthContext";

const EventView = ({ event }) => {
  const { acceptEvent, declineEvent } = useContext(EventContext);
  const {
    state: { user }
  } = useContext(AuthContext);
  const onAcceptEvent = async () => {
    console.log(event, "EVE");
    acceptEvent(event._id);
  };

  const onDeclineEvent = async () => {
    console.log(event, "EVE");
    declineEvent(event._id);
  };

  const likedPost = () => {
    if (event && event.accepted.find(e => e === user._id)) {
      console.log("true");
      return true;
    } else return false;
  };

  return (
    <Container style={{ display: "flex", flexDirection: "row" }}>
      <Grid item xs={12} md={6} sm={6}>
        <Grid>
          <Typography style={{ fontWeight: "bold" }}>{event.title}</Typography>
        </Grid>
        <Grid>Destination: {event.destination}</Grid>
        <Grid>Reason: {event.reason}</Grid>
        <Grid>
          <Typography>{`${moment(event.start).format("MM-DD-YYYY : hh:mm A")} - ${moment(
            event.end
          ).format("hh:mm A")}`}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sm={6}
        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
      >
        <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <IconButton
            color={likedPost() ? "secondary" : "default"}
            onClick={() => (likedPost() ? onDeclineEvent() : onAcceptEvent())}
          >
            <ThumbUpIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventView;
