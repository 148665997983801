import createDataContext from './createDataContext'
import axios from 'axios';
import moment from 'moment'

const userReducer = (state, action) => {
    switch (action.type) {
        case 'fetch_users':
            return { ...state, users: action.payload.docs }
        case 'fetch_history':
            return { ...state, histories: action.payload.docs }
        case 'loading':
            return { ...state, isLoading: action.payload }
        case 'history-loading':
            return { ...state, historyLoading: action.payload }
        case 'button_loading':
            return { ...state, buttonLoading: action.payload }
        case 'update_user':
            const updatedIndex = state.users.findIndex((e) => e._id === action.payload._id)
            state.users[updatedIndex] = action.payload
            return { ...state, updateUserError: '' }
        case 'delete_user':
            const deletedIndex = state.users.findIndex((e) => e._id === action.payload)
            state.users.splice(deletedIndex, 1);
            return { ...state }
        case 'update-user-error':
            return { ...state, updateUserError: action.payload }
        case 'add-user-error':
            return { ...state, addUserError: action.payload }
        case 'fetch_user':
            return { ...state, user: { ...action.payload.user, data: action.payload.history } }
        case 'fetch_user_comments':
            if (action.payload.page === 1) {
                return { ...state, comments: action.payload.docs }
            } else {
                return { ...state, comments: [...state.comments, ...action.payload.docs] }
            }
        case 'user-logs':
            return { ...state, userLogs: action.payload.docs }
        default:
            return state
    }
}

//ADMIN
const fetchCallCenterUsers = (dispatch) => {
    return async (page, limit, search) => {
        dispatch({ type: 'loading', payload: true })
        try {
            const response = await axios.get(`/api/get-users-cc?page=${page}&limit=${limit}&search=${search}`);
            console.log(response)
            dispatch({ type: 'fetch_users', payload: response.data })

            dispatch({ type: 'loading', payload: false })

            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const fetchCallCenterUser = (dispatch) => {
    return async (userId) => {
        dispatch({ type: 'loading', payload: true })
        try {
            const response = await axios.get(`/api/get-user-cc?userId=${userId}`);
            console.log(response)
            dispatch({ type: 'fetch_user', payload: response.data })

            dispatch({ type: 'loading', payload: false })

            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const fetchCallCenterCommentsUser = (dispatch) => {
    return async (userId, page, limit) => {
        try {

            const response = await axios.get(`/api/get-user-cc-comments?userId=${userId}&page=${page}&limit=${limit}`);
            console.log(response)
            dispatch({ type: 'fetch_user_comments', payload: response.data })
            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const fetchAllTimeHistory = (dispatch) => {
    return async () => {
        try {
            const response = await axios.get(`/api/get-history?page=${1}&limit=${100000}&search=${''}&answer=${true}&startDate=${false}&endDate=${false}&type=${'All'}`);
            console.log(response, 'RESPONSE ALLTIME')
            //restruct for csv
            const newArr = await response.data.docs.map((history) => {
                const startLat = history.location[0]?.coords.latitude
                const startLong = history.location[0]?.coords.longitude
                const endLat = history.location[history.location.length - 1]?.coords.latitude
                const endLong = history.location[history.location.length - 1]?.coords.longitude
                return ({
                    ...history,
                    timeCSV: `${moment(history.createdAt).format('MM/DD/YYYY')}(${moment(history.createdAt).format('h:mm A')})`,
                    callerCSV: history.caller === 'User' ? history.userName : history.clientName,
                    durationCSV: moment.duration(history.duration, "seconds").format("h:mm:ss", { trim: false }),
                    startLat: `"${startLat}"`,
                    startLong: `"${startLong}"`,
                    endLat: `"${endLat}"`,
                    endLong: `"${endLong}"`,
                })
            })

            return newArr

        } catch (e) {
            console.log(e.response)
        }
    }
}

const fetchHistory = (dispatch) => {
    return async (page, limit, search, answer = true, startDate = false, endDate = false, type = 'All') => {
        dispatch({ type: 'history-loading', payload: true })
        try {
            const response = await axios.get(`/api/get-history?page=${page}&limit=${limit}&search=${search}&answer=${answer}&startDate=${startDate}&endDate=${endDate}&type=${type}`);
            console.log(response, 'original response')

            //restruct for csv
            const newArr = await response.data.docs.map((history) => {
                const startLat = history.location[0]?.coords.latitude
                const startLong = history.location[0]?.coords.longitude
                const endLat = history.location[history.location.length - 1]?.coords.latitude
                const endLong = history.location[history.location.length - 1]?.coords.longitude
                return ({
                    ...history,
                    timeCSV: `${moment(history.createdAt).format('MM/DD/YYYY')}(${moment(history.createdAt).format('h:mm A')})`,
                    callerCSV: history.caller === 'User' ? history.userName : history.clientName,
                    durationCSV: moment.duration(history.duration, "seconds").format("h:mm:ss", { trim: false }),
                    startLat: `"${startLat}"`,
                    startLong: `"${startLong}"`,
                    endLat: `"${endLat}"`,
                    endLong: `"${endLong}"`,
                })
            })
            console.log(newArr, 'RESPONSE HISTORY')
            response.data.docs = newArr

            dispatch({ type: 'fetch_history', payload: response.data })

            dispatch({ type: 'history-loading', payload: false })

            return response.data

        } catch (e) {
            console.log(e.response)
        }
    }
}

const updateCallCenterUser = (dispatch) => {
    return async (email, firstName, lastName, _id) => {
        try {
            const response = await axios.put(`/api/update-user-cc`, { email, firstName, lastName, _id });
            console.log(response)
            dispatch({ type: 'update_user', payload: response.data })
            return true
        } catch (e) {
            if (e.response.data.error.code === 11000) {
                dispatch({ type: 'update-user-error', payload: 'Email already exists' })
                return false
            } else if (e.response.status === 404) {
                console.log(e.response)

                dispatch({ type: 'update-user-error', payload: e.response.data.error })
            }
        }
    }
}

const addCallCenterUser = (dispatch) => {
    return async (email, firstName, lastName, password, verifyPassword) => {
        try {
            const response = await axios.post(`/api/add-user-cc`, { email, firstName, lastName, password, verifyPassword });
            console.log(response)
            dispatch({ type: 'add-user-error', payload: '' })
            return true
        } catch (e) {
            console.log(e.response)
            dispatch({ type: 'add-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const deleteCallCenterUser = (dispatch) => {
    return async (_id) => {
        try {
            const response = await axios.delete(`/api/delete-user-cc?userId=${_id}`);
            console.log(response)
            dispatch({ type: 'delete_user', payload: _id })
            return true
        } catch (e) {
            console.log(e.response)
            dispatch({ type: 'add-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const updateAdminInfo = (dispatch) => {
    return async (email, firstName, lastName, _id) => {
        try {
            const response = await axios.put('/api/change-admin-info', { email, firstName, lastName, _id })
            console.log(response)
            dispatch({ type: 'update-user-error', payload: '' })

            return true
        } catch (e) {
            dispatch({ type: 'update-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const changeAdminPassword = (dispatch) => {
    return async (oldPassword, newPassword, verifyPassword, email, _id) => {
        try {
            const response = await axios.put('/api/change-admin-password', { oldPassword, newPassword, verifyPassword, email, _id })
            console.log(response)
            dispatch({ type: 'add-user-error', payload: '' })

            return true
        } catch (e) {
            dispatch({ type: 'add-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const changeUserPassword = (dispatch) => {
    return async (oldPassword, newPassword, verifyPassword, email, _id) => {
        try {
            const response = await axios.put('/api/change-user-password', { oldPassword, newPassword, verifyPassword, email, _id })
            console.log(response)
            dispatch({ type: 'add-user-error', payload: '' })

            return true
        } catch (e) {
            dispatch({ type: 'add-user-error', payload: e.response.data.error })
            return false
        }
    }
}

const changeUserAvailability = (dispatch) => {
    return async (userId) => {
        const response = await axios.post('/api/admin-user-availability', { userId })
        console.log(response)
        dispatch({ type: 'update_user', payload: response.data })
        return response.data.available
    }
}

const disableUser = (dispatch) => {
    return async (userId) => {
        const response = await axios.post('/api/admin-user-disable', { userId })
        console.log(response)
        dispatch({ type: 'update_user', payload: response.data })
        console.log(userId)
    }
}

const clearErrors = (dispatch) => {
    return async () => {
        dispatch({ type: 'add-user-error', payload: '' })
        dispatch({ type: 'update-user-error', payload: '' })
    }
}

const getUserLogs = (dispatch) => {
    return async (userId, page, limit, type, startDate, endDate) => {
        try {
            const response = await axios.get(`/api/admin-get-user-logs?userId=${userId}&page=${page}&limit=${limit}&type=${type}&startDate=${startDate}&endDate=${endDate}`)
            console.log(response, "USERLOGS")
            dispatch({ type: 'user-logs', payload: response.data.logs })
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
}

export const { Provider, Context } = createDataContext(userReducer,
    { fetchHistory, fetchCallCenterUsers, disableUser, fetchCallCenterUser, fetchAllTimeHistory, deleteCallCenterUser, updateCallCenterUser, getUserLogs, fetchCallCenterCommentsUser, changeUserPassword, changeUserAvailability, addCallCenterUser, updateAdminInfo, changeAdminPassword, clearErrors },
    { users: [], user: {}, comments: [], addUserError: '', updateUserError: '', isLoading: false, histories: [], historyLoading: false, userLogs: [] })