import React, { useState, useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import pkg from "../../package.json";

//material
import {
  makeStyles,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  Card,
  CardMedia,
  Grid,
  Typography,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer
} from "@material-ui/core";
import clsx from "clsx";

//context
import { Context as AuthContext } from "../context/AuthContext";
import { Context as CallContext } from "../context/CallContext";
import { Context as EventContext } from "../context/EventContext";

//icons
import MoreIcon from "@material-ui/icons/MoreVert";
import logo from "../images/appstore.png";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EventIcon from "@material-ui/icons/Event";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";

//components
import Chatbar from "../components/Chatbar";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    logo: {
      maxWidth: 160
    }
  },
  offset: theme.mixins.toolbar,
  media: {
    height: "35px",
    width: "35px"
  },
  adminButton: {
    color: theme.palette.secondary.main
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    backgroundColor: theme.palette.background.paper
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  leftTolbar: {
    backgroundColor: theme.palette.primary.main,
    height: "200px"
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.default
  },
  grow: {
    flexGrow: 1
  },
  listItemHover: {
    width: drawerWidth - 20,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      width: drawerWidth - 20
    }
  },
  listItemTitle: {
    fontSize: "12px",
    color: "#0000008A"
  },
  listItemText: {
    fontSize: "14px"
  },
  iconStyle: {
    width: "16px",
    height: "16px"
  },
  nameGrid: {
    backgroundColor: theme.palette.primary.main,
    marginLeft: -20,
    marginRight: -20,
    textAlign: "center"
  }
}));

const Navbar = props => {
  const classes = useStyles();
  const {
    state: { user, isAdmin },
    logout
  } = useContext(AuthContext);
  const {
    state: { room, client, clientId }
  } = useContext(CallContext);
  const {
    state: { notification },
    changeNotificationStatus
  } = useContext(EventContext);

  const [mobileMoreAnchorEl2, setMobileMoreAnchorEl2] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl2);
  const { container } = props;

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl2(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    changeNotificationStatus(false);
    setMobileMoreAnchorEl2(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <>
      <Grid
        style={{
          flexDirection: "column",
          display: "flex",
          justifyContent: room && client ? "space-between" : "flex-start",
          height: "100%"
        }}
      >
        <Grid
          style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
          className={clsx(classes.leftTolbar, classes.toolbar)}
        >
          <Typography
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              fontSize: "10px"
            }}
          >
            {pkg.version}({pkg.build})
          </Typography>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "25px"
            }}
          >
            <Card className={classes.media}>
              <CardMedia className={classes.media} image={logo} />
            </Card>
            <Typography
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: "14px",
                marginLeft: "20px"
              }}
            >
              Tappy Guide
            </Typography>
          </Grid>
          <Grid style={{ marginTop: "60px", margin: "20px" }}>
            <Typography
              style={{
                color: "white",
                display: "flex",
                maxHeight: "50px",
                justifyContent: "center",
                flexDirection: "row",
                overflow: "hidden",
                fontSize: "16px",
                whiteSpace: "pre-line",
                wordWrap: "break-word"
              }}
            >{`Welcome ${user.firstName}`}</Typography>
          </Grid>
        </Grid>
        {room && client ? (
          <Grid
            style={{
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%"
            }}
          >
            <Typography>City: {client.otherInfo.address.city}</Typography>
            <Typography>State: {client.otherInfo.address.state}</Typography>
            <Typography>Street: {client.otherInfo.address.street}</Typography>
            <Typography>Zip Code: {client.otherInfo.address.zipCode}</Typography>
            <Typography>
              Birth Date: {moment(client.otherInfo.birthDate).format("MM/DD/YYYY")}
            </Typography>
            <Typography>Phone: {client.otherInfo.phone}</Typography>
            <Grid className={classes.nameGrid}>
              <Typography
                style={{
                  whiteSpace: "pre-line",
                  color: "white",
                  fontSize: 20,
                  alignSelf: "center"
                }}
              >
                {client.firstName} {client.lastName}
              </Typography>
            </Grid>
            <Chatbar roomId={room} clientId={clientId} />
          </Grid>
        ) : (
          <List>
            <ListItem>
              <ListItemText disableTypography className={classes.listItemTitle} primary='Map' />
            </ListItem>
            <ListItem
              onClick={() => {
                handleDrawerClose();
              }}
              selected={props.location.pathname === `/map`}
              className={classes.listItemHover}
              button
              component={Link}
              to={`/map`}
            >
              <ListItemIcon>
                <ExploreOutlinedIcon className={classes.iconStyle} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.listItemText} primary='Map' />
            </ListItem>
            <ListItem
              onClick={() => {
                handleDrawerClose();
              }}
              selected={props.location.pathname === `/profile`}
              className={classes.listItemHover}
              button
              component={Link}
              to={`/profile`}
            >
              <ListItemIcon>
                <GroupOutlinedIcon className={classes.iconStyle} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.listItemText} primary='Profile' />
            </ListItem>
            <ListItem
              onClick={() => {
                handleDrawerClose();
                handleMobileMenuClose();
              }}
              selected={props.location.pathname === `/event`}
              className={classes.listItemHover}
              button
              component={Link}
              to={`/event`}
            >
              <ListItemIcon>
                <EventIcon className={classes.iconStyle} />
              </ListItemIcon>
              <Badge color='secondary' variant='dot' invisible={!notification}>
                <ListItemText
                  disableTypography
                  className={classes.listItemText}
                  primary='Appointments'
                />
              </Badge>
            </ListItem>
            <ListItem
              onClick={() => {
                handleDrawerClose();
                handleMobileMenuClose();
              }}
              selected={props.location.pathname === `/home`}
              className={classes.listItemHover}
              button
              component={Link}
              to={`/home`}
            >
              <ListItemIcon>
                <CalendarTodayIcon className={classes.iconStyle} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.listItemText} primary='Calendar' />
            </ListItem>
            <ListItem
              onClick={() => {
                handleDrawerClose();
                handleMobileMenuClose();
              }}
              selected={props.location.pathname === `/tickets`}
              className={classes.listItemHover}
              button
              component={Link}
              to={`/tickets`}
            >
              <ListItemIcon>
                <HelpOutlineOutlinedIcon className={classes.iconStyle} />
              </ListItemIcon>
              <ListItemText disableTypography className={classes.listItemText} primary='Support' />
            </ListItem>
          </List>
        )}
      </Grid>
    </>
  );

  const onLogout = async () => {
    logout(props.history);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl2}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {isAdmin ? (
        <MenuItem
          onClick={handleMobileMenuClose}
          className={classes.adminButton}
          component={Link}
          to='/admin'
          disabled={!!room}
        >
          Admin
        </MenuItem>
      ) : null}
      <MenuItem component={Link} onClick={handleMobileMenuClose} to='/calls' disabled={!!room}>
        Calls
      </MenuItem>
      <MenuItem onClick={() => onLogout()} disabled={!!room}>
        Logout
      </MenuItem>
    </Menu>
  );
  return (
    <div className={classes.grow}>
      <AppBar position='fixed' color='inherit' className={classes.appBar}>
        <Toolbar>
          <IconButton disabled={!!room} onClick={() => props.history.goBack()}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
          {!room ? (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {isAdmin ? (
              <MenuItem
                component={Link}
                className={classes.adminButton}
                to='/admin'
                disabled={!!room}
              >
                Admin
              </MenuItem>
            ) : null}
            <MenuItem component={Link} to='/calls' disabled={!!room}>
              Calls
            </MenuItem>
            <MenuItem onClick={() => onLogout()} disabled={!!room}>
              Logout
            </MenuItem>
            <MenuItem onClick={() => toggleFullScreen()}>
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </MenuItem>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation='css'>
          {!room ? (
            <Drawer
              container={container}
              variant='temporary'
              anchor={"left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          ) : null}
        </Hidden>
        <Hidden xsDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant='permanent'
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
        <div className={classes.offset} />
      </nav>
      {renderMobileMenu}
    </div>
  );
};
export default withRouter(Navbar);
