import React, { useState } from "react";

//material
import {
  Slide,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Button as MButton,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//icons
import DeleteIcon from "@material-ui/icons/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

//style
const useStyles = makeStyles(theme => ({
  headerStyle: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px"
  },
  dialogContainer: {
    borderRadius: "15px"
  }
}));

const DeleteAlertButton = ({ onDelete, _id, fullName, title }) => {
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const classes = useStyles();

  const handleClickOpen = e => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmationDialog(true);
  };
  const handleClose = e => {
    e.stopPropagation();
    e.preventDefault();
    setConfirmationDialog(false);
  };

  const onDeleteUser = async e => {
    e.stopPropagation();
    e.preventDefault();
    onDelete(_id);
    setConfirmationDialog(false);
  };

  return (
    <>
      <div>
        <IconButton
          size='small'
          onClick={e => handleClickOpen(e)}
          onMouseDown={e => e.stopPropagation()}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div>
        <Dialog
          open={confirmationDialog}
          TransitionComponent={Transition}
          keepMounted
          classes={{
            paper: classes.dialogContainer
          }}
          onClose={e => handleClose(e)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <Grid className={classes.headerStyle}>
            <Typography style={{ padding: "20px", color: "white" }}>{title}</Typography>
          </Grid>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Are you sure you want to delete
              <strong style={{ color: "red" }}> {fullName}</strong>
              {" ?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MButton onClick={e => handleClose(e)} variant='contained'>
              Cancel
            </MButton>
            <MButton
              onClick={e => onDeleteUser(e)}
              color='primary'
              variant='contained'
              onMouseDown={e => e.stopPropagation()}
            >
              Delete
            </MButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default DeleteAlertButton;
