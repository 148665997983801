import React, { useState } from "react";
import moment from "moment";

//material
import {
  Typography,
  TableCell,
  Divider,
  TableRow,
  IconButton,
  Grid,
  Collapse,
  Box,
  makeStyles
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles(theme => ({
  textStyle: {
    color: "grey"
  },
  columnStyle: {
    display: "flex",
    flexDirection: "column"
  }
}));

const TableRowCollapse = ({ row, columns }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell align={"left"} key='compress'>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map(column => {
          const value = row[column.id];
          return (
            <TableCell align={column.align} key={column.id}>
              <div>{column.format ? column.format(row) : value}</div>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={4} style={{ display: "flex", flexDirection: "row" }}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid className={classes.columnStyle}>
                  <Typography className={classes.textStyle}>
                    <strong>Registration date:</strong> {moment(row.createdAt).format("MM/DD/YYYY")}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>City:</strong> {row.otherInfo.address.city}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>State:</strong> {row.otherInfo.address.state}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>Street:</strong> {row.otherInfo.address.street}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>Zip:</strong> {row.otherInfo.address.zipCode}
                  </Typography>
                </Grid>
              </Grid>
              <Divider orientation={"vertical"} />
              <Grid item xs={12} sm={6} md={6}>
                <Grid className={classes.columnStyle}>
                  <Typography className={classes.textStyle}>
                    <strong>Date of birth:</strong>{" "}
                    {moment(row.otherInfo.birthDate).format("MM/DD/YYYY")}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>Gender:</strong> {row.otherInfo.gender}
                  </Typography>
                  <Typography className={classes.textStyle}>
                    <strong>Phone:</strong> {row.otherInfo.phone}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableRowCollapse;
